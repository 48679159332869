import { createSlice } from "@reduxjs/toolkit";
import initialState from "./initialState";
import {
  getHomeCarouselList,
  getMovieFeatured,
  getMovieList,
  getSeriesList
} from "../../../actions/services";

const persistSlice = createSlice({
  name: "persist",
  initialState,
  reducers: {
    setFeaturedMovies(state, { payload }) {
      state.featuredMovies = payload;
    },
    setHomeCarousel(state, { payload }) {
      state.homeCarousel = payload;
    },
    setMovieList(state, { payload }) {
      state.movieList = payload;
    },
    setSeriesList(state, { payload }) {
      state.seriesList = payload;
    }
  }
});

export const {
  setFeaturedMovies,
  setHomeCarousel,
  setMovieList,
  setSeriesList
} = persistSlice.actions;

export default persistSlice.reducer;

const isDataExpired = savedAt =>
  new Date() - new Date(savedAt) > 10 * 60 * 1000;

export const getHomeCarousel = () => (dispatch, getState) => {
  try {
    const { persist } = getState();
    const homeCarousel = persist?.homeCarousel;
    if (
      !homeCarousel ||
      (homeCarousel?.savedAt && isDataExpired(homeCarousel?.savedAt))
    ) {
      getHomeCarouselList().then(res => {
        if (res?.data?.success && res?.data?.data) {
          dispatch(
            setHomeCarousel({ data: res?.data?.data, savedAt: new Date() })
          );
        }
      });
    } else {
      dispatch(setHomeCarousel(homeCarousel));
    }
  } catch (err) {}
};

export const getFeaturedMovie = () => (dispatch, getState) => {
  try {
    const { persist } = getState();
    const featuredMovies = persist?.featuredMovies;
    if (
      !featuredMovies ||
      (featuredMovies?.savedAt && isDataExpired(featuredMovies?.savedAt))
    ) {
      getMovieFeatured().then(res => {
        if (res?.data?.success && res?.data?.data) {
          dispatch(
            setFeaturedMovies({ data: res?.data?.data, savedAt: new Date() })
          );
        }
      });
    } else {
      dispatch(setFeaturedMovies(featuredMovies));
    }
  } catch (err) {}
};

export const getMovieListData = subType => (dispatch, getState) => {
  try {
    const { persist } = getState();
    const movieList = persist?.movieList;
    if (
      !movieList ||
      (movieList?.savedAt && isDataExpired(movieList?.savedAt)) ||
      subType !== movieList?.subType
    ) {
      getMovieList(subType).then(res => {
        if (res?.data?.success && res?.data?.data) {
          dispatch(
            setMovieList({
              data: res?.data?.data,
              savedAt: new Date(),
              subType
            })
          );
        }
      });
    } else {
      dispatch(setMovieList(movieList));
    }
  } catch (err) {}
};
export const getSeriesListData = () => (dispatch, getState) => {
  try {
    const { persist } = getState();
    const seriesList = persist?.seriesList;
    if (
      !seriesList ||
      (seriesList?.savedAt && isDataExpired(seriesList?.savedAt))
    ) {
      getSeriesList().then(res => {
        if (res?.data?.success && res?.data?.data) {
          dispatch(
            setSeriesList({
              data: res?.data?.data,
              savedAt: new Date(),
            })
          );
        }
      });
    } else {
      dispatch(setSeriesList(seriesList));
    }
  } catch (err) {}
};
