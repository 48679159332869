import { BUSINESS_CONFIG } from "../clientConfig"

export const PageTitleCd = {
    HOME: '',
    MOVIE_LIST: '',
    MOVIE_INFO: '',
    SERIES_LIST: '',
    SERIES_INFO: '',
    SETTING: ' | Settings',
    USER_PROFILE: ' | User Account',
    WATCH_HISTORY: ' | Watch History',
}

export const setPageTitle = (pageCd, movieName) => {
    let title = `${BUSINESS_CONFIG.Title}${PageTitleCd?.[pageCd] || PageTitleCd.HOME}`;
    title = movieName ? title + ' | ' + movieName : title;
    document.title = title;
}