import { CONTENT_RATING } from "constants/constants";
import { ROUTES } from "helpers/routeHelper";

export const APP_HEADER_TITLE = "sprout blue demo"; // to check where is this used?
export const BUSINESS_NAME = "sprout blue demo"; // to check where is this used?
export const APP_FOOTER = "sprout blue demo"; // to check where is this used?
export const BUSINESS_ID = 2; // to check where is this used?
export const APP_THEME = "demotheme"; 

export const ENABLE_ADS_BLOCKER = false; // to check where is this used?

// export const LOGO = 'https://nine-rasa-all.ams3.cdn.digitaloceanspaces.com/All-Assests-Ninerasa/website-logo-transprent-landscape/Nine-Rasa-Golden-Logo-landscape-Version-1.png';

// export const SMALL_LOGO = 'https://nine-rasa-all.ams3.cdn.digitaloceanspaces.com/All-Assests-Ninerasa/website-logo-transprent-portrait/Nine-Rasa-Golden-Logo-Porait-Version-1.png'; // to check where is this used? (check also index .html)

// export const LOGO = 'https://flowgiga.ams3.cdn.digitaloceanspaces.com/0000-ladoo-play-assests/logos/Ladoo-Play.png';


export const LOGO = 'https://flowgiga.ams3.cdn.digitaloceanspaces.com/0000-demo-assets/logo/logo-white.png';
export const SMALL_LOGO = 'https://jugnu-play-all.sgp1.digitaloceanspaces.com/Jugnu-assets/website-logo-transparent-portarit/1.png';
export const RAZORPAY_THEME = '#EFCF02'; // to check where is this used?
export const BASE_URL = "https://ninerasa.flowgiga.com"; // not used any where

// APP BAckend API
// export const APP_BACKEND_API = "https://dev.flowgiga.com/ninerasa";
export const APP_BACKEND_API = "https://staging.flowgiga.com/jugnu";
// APP Analytics API
export const ANALYTICS_BACKEND_API =
  "https://demo-backend.flowgiga.com/dynamo/analytics";
// export const BUSINESS_CONFIG = {
//   BusinessName: "jugnu-staging",
//   BusinessId: 4,
//   GoogleAnalytics_TrackingId: "UA-244951482-2",
//   PrivacyPolicy: `${APP_BACKEND_API}/privacy_policy`,
//   RazorPayKey: "rzp_live_t7NvjrNqiqlN4D",
//   Title: "Jugnu Play|| OTT+",
// };
export const BUSINESS_CONFIG = {
  BusinessName: "Sprout-blue-demo",
  BusinessId: 2,
  GoogleAnalytics_TrackingId : 'UA-244951482-1',
  PrivacyPolicy: `${APP_BACKEND_API}/privacy_policy`,
  // RazorPayKey: 'rzp_test_lis6gnd6XonNX3',
  RazorPayKey: "rzp_live_t7NvjrNqiqlN4D",
  // Title: 'Jugnu Play|| OTT+'
   Title: 'Sprout Blue Demo' // to check where is this used? (check also index .html)
  
}
// used in app header download button
export const APP_LINK = {
  ANDROID:"https://play.google.com/store/apps/details?id=io.ninerasa.flowgiga.app",
  IOS: "https://apps.apple.com/in/app/ninerasa/id1569663582",
};
// to hide the social sign in/up buttons
export const SHOW_SOCIAL_AUTH = false;
export const SHOP_URL = "https://games.jugnuplay.com";
export const GAME_URL = "https://games.jugnuplay.com";
export const QUIZ_URL = "https://quiz.jugnuplay.com";
// initilze the payment types
export const PAYMENT = {
  IS_SUBSCRIPTION: true,
  IS_PAY_PER_VIEW: false,
}; 

// to show the sub types filter in mobile
export const FILTER_TYPE = 'subtype';
// export const FILTER_TYPE = "multipart";
// to show the sub types filter in mobile
export const SHOW_HOME_FILTER = true;
// List of filters/Sub types in mobile home and Desktop header

export const MOVIE_SUB_TYPES = [
  // { label: "Play", value: "Play" },
  { label: "Documentary", value: "Documentary" },
  // { label: "Standup", value: "Standup" },
  { label: "Shortseries", value: "Shortseries" },
  { label: "Limitedseries", value: "Limitedseries" },
  // { label: "Normal", value: "Normal" },
  { label: "Shortfilm", value: "Shortfilm" }
];


// To show/hide the footer tabs in mobile
export const SHOW_TABS = {
  HOME: true,
  MOVIES_LIST: FILTER_TYPE != "subtype",
  SERIES_LIST: FILTER_TYPE != "subtype",
  COINS: false,
  USER_PROFILE: false,
  EXPLORE: true,
  SHOP: false,
  GAME: false,
  QUIZ: false,
  WATCH_HISTORY: true,
  ACCOUNT: false,
};

export const META_KEYWORDS =
  "original theatre online, scary stories to tell in the dark, vinay pathak, live theatre shows near me, national theatre live plays, live stage shows, ram prasad ki tehrvi watch ott, death of a salesman, death of a salesman 1985, tughlaq durbar, shreyas talpade, pashmina, shreyas talpade movie list, shreyas talpade taj mahal movie, shreyas talpade movie, aa namo bahu nade chhe, ajab karamat, chel chabilo gujarati, duniya ne undha chasma, dikri vahal no dariyo, best gujarati natak comedy, marathi theatre, gujjubhai ni golmaal, gupchup gupchup, gujjubhai e gaam gajavyu, new ott, best ott 2021, new on ott, new ott 2021, latest on ott, upcoming ott, new on ott this week, to mi navech, kusum manohar lele, sakharam binder, savita damodar paranjpe, indian classical drama, indian folk theatre, mohit takalkar, theatre artists in india, how to learn acting, how to learn acting at home, how to learn acting at home free, indian theatre actors, how to learn acting online, indian theatre directors, differences between theatre and drama, live theatre performances near me, marathi natak list, marathi natak comedy list, marathi natak list free download, marathi drama list, marathi plays list, marathi natak list 2019, marathi sangeet natak list, marathi vinodi natak list, famous indian dramatists, live stage shows";

// add the object for id, which you want to show warnong on watch click
export const CONTENT_WATCH_WARNING = [
  {
    //id : CONTENT_RATING.PG_13, header: 'Pg-13 content', message: 'This is PG-13 marked content, want to continue?'
    id: CONTENT_RATING.A,
    header: "Adult Content",
    message:
      "This content may contain coarse language, nudity, sexual content, violence and mature themes, that are not suitable for viewers under the age of 18. Do you wish to continue?",
  },
];

// shows movie/series titles in the carousels 
export const SHOW_CARD_TITLE = false;
export const SHOW_CARD_TITLE_DESKTOP = false;

// shows or hides the featured banner in the filter pages 
export const SHOW_FEATURED_BANNER_MOVIE_LIST = true;

// ------------------------- Layout Controls Start -------------------------------
// force all carousals to be thumbnails.
export const ALL_THUMBNAILS = false;

// choose carousel indices to make them thumbanails (super seeded by ALL_THUMBNAILS)
export const CAROUSAL_THUMBNAILS_INDICES = [0,2];

// ------------------------- Layout Controls ends -------------------------------


// ------------------------- Welcome Pop-up Controls start -------------------------------
export const SHOW_SIGNUP_WELCOME_MESSAGE = true;
export const SHOW_SIGNUP_PAYMENT_PLANS = false;
// ------------------------- Welcome Pop-up Controls end -------------------------------


// ------------------------- Jugnu Speific Usecase to be removed stat-------------------
export const PROFILE_SHOW_COINS_AND_ABOUTUS = false;


//extra tabs not to be used built for a spifice usecase (jugnu)
export const SHOW_EXTRA_TABS= false;

export const EXTRA_HOME_TABS = [
  { label: "Home", link: ROUTES.HOME },
  { label: "Movies", link: ROUTES.MOVIES },
  { label: "Webseries", link: ROUTES.SERIES_LIST },
  { label: "Games", link: GAME_URL, isExternal: true },
  { label: "KKM", link: QUIZ_URL, isExternal: true },
];

// ------------------------- Jugnu Speific Usecase to be removed end-------------------


// ------------------------- Static pages content to moved to json files -----------------

export const DISCLAIMER = '"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. '
export const PRIVACY_POLICY = '"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. '
export const ABOUT_US = `"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.`
export const TERMS_OF_SERVICE = '"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. '