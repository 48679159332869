import React from "react";
import { Route , Redirect} from "react-router-dom";
import { connect } from 'react-redux';
import { setLastRoute, setCurrentRoute } from './../../store/slices/appSlice';
import { ROUTES } from "helpers/routeHelper";

const ComponentWrapper = ({
  component: Component, 
  lastRoute,
  isAuthUser,
  isMobile,
  setLastRouteAction,
  setCurrentRouteAction,
  ...rest
}) => {
  const {
    skipWrapper, 
    authRoute,
    computedMatch 
  } = {
    ...rest,
  };

  const propsToPassed = {
    isMobile
  };

  if(authRoute && isAuthUser === null) {
    return <></>
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        false ?
        // authRoute && isAuthUser === false ?
        <Redirect to={ROUTES.AUTH} /> :

        // skipWrapper ? (
        //   <>
        //   </>
        <Component {...props} {...propsToPassed} {...rest} />
        // ) : (
        //   <>
        //     <main role="main">
        //       <Component {...props} {...propsToPassed} {...rest} />
        //     </main>
        //   </>
        // )
      }
    />
  );
};


const mapStateToProps = ({user,app}) => ({
  email: user?.email,
  userId: user?.userId,
  fullName: user?.fullName,
  isAuthUser: user?.isAuthUser,
  isMobile: app?.isMobile,
  showSideMenu: app?.showSideMenu,
  showTabs: app?.showTabs,
  showHeader: app?.showHeader,
})

const mapDispatchToProps = {
  setLastRouteAction: setLastRoute,
  setCurrentRouteAction: setCurrentRoute

}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ComponentWrapper);
