import { apiV1 } from './config';
import axiosRetry from "axios-retry";


const getProducts = () => {
    const response = apiV1().get(`/shop`, {
      'axios-retry': {
        retries: 0
      }
    });
    console.log(response);
    return response;
}

export { getProducts };
