import React, { useEffect, useState, useMemo } from "react";
import PropTypes from "prop-types";
import Slider from "react-slick";
import { isPlatform, IonSkeletonText, IonThumbnail, IonIcon } from "@ionic/react";
import MovieCardNormal from "../MovieCard/Normal/MovieCardNormal";
import MovieCardShort from "../MovieCard/Short/MovieCardShort";
import MovieCardLongMobile from "../MovieCard/Long/MovieCardLongMobile/MovieCardLongMobile";
import MovieCardLongWeb from "../MovieCard/Long/MovieCardLongWeb/MovieCardLongWeb";
import "./MovieCarousel.scss";
import PlayButton from "../Play/PlayButton";
import { chevronForward } from "ionicons/icons";
import { arrowForward} from "ionicons/icons";
// import MovieCardOne from "../MovieCard/MovieCardOne/MovieCardOne";
import MovieCardOne from "../MovieCard/MovieCardOne/MovieCardOne";
import { useHistory } from "react-router-dom";
import { ROUTES } from "helpers/routeHelper";
 
 
const propTypes = {
  isNotBannerView: PropTypes.bool,
};

const WrapWatch = ({ wrapPlay, children, ...props }) => {
  return wrapPlay ? (
    <PlayButton
      {...props}
    >
      {children}
    </PlayButton>
  ) : (
    <>{children}</>
  );
};

const MovieCarousel = ({
  moviesList,
  name,
  isBanner,
  screen,
  showSkeleton,
  showVideo,
  isNotBannerView,
  continueWatch,
}) => {
  const [movies, setMovies] = useState(null);
  const [skeleton, setSkeleton] = useState(null);
  const [count, setCount] = useState(0);
 
  useEffect(() => {
    setSkeleton(showSkeleton);
  }, [showSkeleton]);
 
  useEffect(() => {
    if (moviesList?.length) {
      setMovies(moviesList);
      setCount(moviesList?.length || 0);
    }
  }, [skeleton, moviesList, setMovies]);
 
  const isMobile = isPlatform("mobile");
  const history = useHistory()
  const normal = useMemo(() => {
    return isMobile
      ? movies?.map((movie, i) =>
          skeleton?.short ? (
            <IonThumbnail
              key={`movie-short-skeleton-${i}`}
              className="skeleton-card-short"
            >
              <IonSkeletonText animated />
            </IonThumbnail>
          ) : (
            <WrapWatch wrapPlay={continueWatch} movieId={movie?.state?.item?.id}
            collectionId={movie?.id}
            collectionItemCombo={{
              collection: { ...movie, state: null },
              item: movie?.state?.item,
            }}
            playerState={movie?.state}>
            <MovieCardShort
              key={`movie-carousel-movie-short-${i}${movie?.id}`}
              movie={movie}
              screen={screen}
              disableLink={continueWatch}
            />
            </WrapWatch>
          )
 
      )
      : movies?.map((movie, i) =>
        skeleton?.normal ? (
          <IonThumbnail className="skeleton-card-normal" key={i}>
            <IonSkeletonText animated />
          </IonThumbnail>
        ) : (
          <WrapWatch wrapPlay={continueWatch} movieId={movie?.state?.item?.id}
            collectionId={movie?.id}
            collectionItemCombo={{
              collection: { ...movie, state: null },
              item: movie?.state?.item,
            }}
            playerState={movie?.state}>
            <MovieCardNormal
              key={`movie-carousel-movie-short-${i}${movie?.id}`}
              movie={movie}
              screen={screen}
              disableLink={continueWatch}
            />
            </WrapWatch>
          // <MovieCardOne key={`movie-carousel-movie-${i}${movie?.id}`} movie={movie} screen={screen} />
        )
      );
  }, [movies, skeleton, name]);
 
  const normalPortrait = useMemo(() => {
    return isMobile
      ? movies?.map((movie, i) =>
          skeleton?.short ? (
            <IonThumbnail
              key={`movie-short-skeleton-${i}`}
              className="skeleton-card-short"
            >
              <IonSkeletonText animated />
            </IonThumbnail>
          ) : (
            <WrapWatch wrapPlay={continueWatch} movieId={movie?.state?.item?.id}
            collectionId={movie?.id}
            collectionItemCombo={{
              collection: { ...movie, state: null },
              item: movie?.state?.item,
            }}
            playerState={movie?.state}>
            <MovieCardShort
              key={`movie-carousel-movie-short-${i}${movie?.id}`}
              movie={movie}
              screen={screen}
              banners={true}
              disableLink={continueWatch}
            />
            </WrapWatch>
          ) 
        )
      : movies?.map((movie, i) =>
          skeleton?.normal ? (
            <IonThumbnail className="skeleton-card-normal" key={i}>
              <IonSkeletonText animated />
            </IonThumbnail>
          ) : (
            <WrapWatch wrapPlay={continueWatch} movieId={movie?.state?.item?.id}
            collectionId={movie?.id}
            collectionItemCombo={{
              collection: { ...movie, state: null },
              item: movie?.state?.item,
            }}
            playerState={movie?.state}>
            <MovieCardNormal
              key={`movie-carousel-movie-short-${i}${movie?.id}`}
              movie={movie}
              screen={screen}
              disableLink={continueWatch}
            />
            </WrapWatch>
            // <MovieCardOne key={`movie-carousel-movie-${i}${movie?.id}`} movie={movie} screen={screen} />
          )
        );
  }, [movies, skeleton, name]);
 
  const long = useMemo(() => {
    return isMobile
      ? movies?.map((movie, i) =>
        skeleton?.long ? (
          <IonThumbnail
              key={`mobile-carousel-skeleton-${i}`}
              className="skeleton-card-long-mobile"
            >
            <IonSkeletonText animated />
          </IonThumbnail>
        ) : (
          <WrapWatch wrapPlay={continueWatch} movieId={movie?.state?.item?.id}
            collectionId={movie?.id}
            collectionItemCombo={{
              collection: { ...movie, state: null },
              item: movie?.state?.item,
            }}
            playerState={movie?.state}>
            <MovieCardLongMobile
              key={`mobile-carousel-movie-${i}`}
              collection={movie}
              screen={screen}
              showVideo={showVideo}
              disableLink={continueWatch}
             />
            </WrapWatch>
        )
      )
      : movies?.map((movie, i) =>
        skeleton?.long ? (
          <IonThumbnail
              key={`web-carousel-skeleton-${i}`}
              className="skeleton-card-long"
            >
            <IonSkeletonText animated />
          </IonThumbnail>
        ) : (
          <WrapWatch wrapPlay={continueWatch} movieId={movie?.state?.item?.id}
            collectionId={movie?.id}
            collectionItemCombo={{
              collection: { ...movie, state: null },
              item: movie?.state?.item,
            }}
            playerState={movie?.state}>
            <MovieCardLongWeb
              key={`web-carousel-movie-${i}`}
              movie={movie}
              screen={screen}
              showVideo={showVideo}
              disableLink={continueWatch}
             />
            </WrapWatch>
        )
      );
  }, [movies, skeleton]);
 
  const settingsLong = {
    centerMode: true,
    dots: false,
    infinite: true,
    lazyLoad: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplaySpeed: 3500,
    draggable: true,
    autoplay: true,
    swipe: true,
    swipeToSlide: true,
    centerPadding: "370px",
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          centerMode: true,
          centerPadding: "150px",
        },
      },
      {
        breakpoint: 770,
        settings: {
          centerMode: false,
          dots: true,
          centerPadding: "100px",
        },
      },
    ],
  };
  const BannerCarousel = () => <Slider {...settingsLong}>{long}</Slider>;
 
  const settings = useMemo(() => {
    return {
      lazyLoad: true,
      dots: false,
      infinite: count > 5,
      speed: 500,
      draggable: true,
      swipe: true,
      swipeToSlide: true,
      slidesToShow: 5,
      slidesToScroll: 2,
      focusOnSelect: true,
      responsive: [
        {
          breakpoint: 1350,
          settings: {
            slidesToShow: 5,
            infinite: count > 5,
            slidesToScroll: 2,
            dots: false,
            nextArrow: <SampleNextArrow />,
            prevArrow: <SamplePrevArrow />,
          },
        },
        {
          breakpoint: 1280,
          settings: {
            slidesToShow: 4,
            infinite: count > 4,
            slidesToScroll: 1,
            dots: false,
            nextArrow: <SampleNextArrow />,
            prevArrow: <SamplePrevArrow />,
          },
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 4,
            infinite: count > 4,
            slidesToScroll: 1,
            dots: false,
          },
        },
        {
          breakpoint: 900,
          settings: {
            slidesToShow: 3.2,
            infinite: count > 3,
            slidesToScroll: 1,
            dots: false,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2.55,
            infinite: false,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1.55,
            infinite: false,
            slidesToScroll: 1,
          },
        },
      ],
    };
  }, [count]);
 
  const CardCarousel = () => <Slider {...settings}>{normal}</Slider>;
 
  const settingsPortrait = useMemo(() => {
    return {
      lazyLoad: true,
      dots: false,
      infinite: count > 5,
      speed: 500,
      draggable: true,
      swipe: true,
      swipeToSlide: true,
      slidesToShow: 5,
      slidesToScroll: 2,
      focusOnSelect: true,
      responsive: [
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2.8,
            infinite: false,
            slidesToScroll: 1,
          },
        },
      ],
    };
  }, [count]);
 
  const CardCarouselPortrait = () => (
    <Slider {...settingsPortrait}>{normalPortrait}</Slider>
  );
 
  return (
    <>
      <>
        {isBanner ? (
          <div className="mb-2">
            <div className="owl-long border-bottom-big">
              {skeleton?.long && <BannerCarousel />}
              {!skeleton?.long && <BannerCarousel />}
            </div>
          </div>
        ) : (
          <>
            <div className="container-fluid carousal-container">
              <span className="carousal-bar-name d-flex justify-content-between align-items-center">
                {showSkeleton?.normal || showSkeleton?.short ? (
                  <IonSkeletonText animated style={{ width: "40%" }} />
                ) : (
                      <>
                      <h2 className="carosuel-icon">{name}</h2>
                        <IonIcon icon={arrowForward} color="primary" onClick={() => {
                    history.push({pathname:`${ROUTES.GENRE_LIST.replace(
                    ":genre",
                    "genre"
                  )}?filter=${name}`,state:{movies:moviesList,name:name}}) }} />
                      </>
                )}
              </span>
              <div className={`owl-${isMobile ? "short" : "normal"}`}>
                {(skeleton?.short || skeleton?.long) &&
                  (isNotBannerView ? <CardCarouselPortrait /> : <CardCarousel />)}
                {!(skeleton?.short || skeleton?.long) &&
                  (isNotBannerView ? <CardCarouselPortrait /> : <CardCarousel />)}
              </div>
            </div>
          </>
        )}
      </>
    </>
  );
};
export default MovieCarousel;
 
function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    />
  );
}
 
function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    />
  );
}

