import {
  getPaymentOrder,
  verifyPaymentOrder,
} from "actions/services/user.service";
import {
  IDENTIFY_TRAITS,
  TRACK_EVENT,
} from "analytics/analyticsHelper";
import { BUSINESS_CONFIG, LOGO, RAZORPAY_THEME } from "./../../../clientConfig";

const processPaymentResponse = (
  response,
  paymentSuccess,
  paymentFailure,
  track,
  identify,
  userName,
  isSubscription
) => {
  track(
    TRACK_EVENT.PAYMENT_EVENT.NAME,
    TRACK_EVENT.PAYMENT_EVENT.getViewProps({
      STATUS: TRACK_EVENT.PAYMENT_EVENT.STATUS.PAYMENT_DONE,
      ...response,
    })
  );
  identify(userName, {
    [IDENTIFY_TRAITS.PAYMENT.NAME]: IDENTIFY_TRAITS.PAYMENT.VALUES.PAYMENT_DONE,
  });

  if (
    response?.razorpay_payment_id &&
    (response?.razorpay_order_id || response?.razorpay_subscription_id) &&
    response?.razorpay_signature
  ) {
    verifyPaymentOrder({
      razorpay_signature_id: response?.razorpay_signature,
      razorpay_subscription_id: response?.razorpay_subscription_id,
      razorpay_payment_id: response?.razorpay_payment_id,
      razorpay_order_id: response?.razorpay_order_id,
    }).then((res) => {
      if (res?.statusText === "OK" && res?.data?.success) {
        track(
          TRACK_EVENT.PAYMENT_EVENT.NAME,
          TRACK_EVENT.PAYMENT_EVENT.getViewProps({
            STATUS: TRACK_EVENT.PAYMENT_EVENT.STATUS.VERIFIED,
            ...res?.data?.data,
          })
        );
        identify(userName, {
          [IDENTIFY_TRAITS.PAYMENT.NAME]:
            IDENTIFY_TRAITS.PAYMENT.VALUES.VERIFIED,
        });
        paymentSuccess();
      } else {
        paymentFailure(null);
      }
    });
  }
  if (
    response?.razorpay_payment_id &&
    response?.razorpay_order_id &&
    response?.razorpay_signature &&
    !isSubscription
  ) {
    verifyPaymentOrder({
      razorpay_signature_id: response?.razorpay_signature,
      razorpay_payment_id: response?.razorpay_payment_id,
      razorpay_order_id: response?.razorpay_order_id,
    }).then((res) => {
      if (res?.statusText === "OK" && res?.data?.success) {
        track(
          TRACK_EVENT.PAYMENT_EVENT.NAME,
          TRACK_EVENT.PAYMENT_EVENT.getViewProps({
            STATUS: TRACK_EVENT.PAYMENT_EVENT.STATUS.VERIFIED,
            ...res?.data?.data,
          })
        );
        identify(userName, {
          [IDENTIFY_TRAITS.PAYMENT.NAME]:
            IDENTIFY_TRAITS.PAYMENT.VALUES.VERIFIED,
        });
        paymentSuccess();
      } else {
        paymentFailure(null);
      }
    });
  }
};

const cancelCallback = (res, paymentFailure, identify, userName) => {
  paymentFailure();
  identify(userName, {
    [IDENTIFY_TRAITS.PAYMENT.NAME]: IDENTIFY_TRAITS.PAYMENT.VALUES.CANCLED,
  });
};

export const payWithRazorNative = (
  movieId,
  userName,
  paymentSuccess,
  paymentFailure,
  isSubscription,
  tier,
  track,
  identify
) => {
  const prefill = {
    name: userName,
    // email: "gaurav.kumar@example.com",
    // contact: "9999999999",
  };
  track(
    TRACK_EVENT.APP_USAGE.NAME,
    TRACK_EVENT.APP_USAGE.getClickProps(
      TRACK_EVENT.APP_USAGE.CLICK_OPTIONS.PAYMENT_CLICK,
      TRACK_EVENT.APP_USAGE.SCREEN.PAYMENT,
      prefill
    )
  );

  var options = {
    key: BUSINESS_CONFIG.RazorPayKey,
    image: LOGO,
    notes: {
      address: "Razorpay Corporate Office",
    },
    theme: {
      color: RAZORPAY_THEME
    },
    modal: {
      ondismiss: () => paymentFailure(),
    },
  };
  track(
    TRACK_EVENT.PAYMENT_EVENT.NAME,
    TRACK_EVENT.PAYMENT_EVENT.getViewProps({
      STATUS: TRACK_EVENT.PAYMENT_EVENT.STATUS.INIT,
      ...prefill,
    })
  );
  identify(userName, {
    [IDENTIFY_TRAITS.PAYMENT.NAME]: IDENTIFY_TRAITS.PAYMENT.VALUES.INIT,
  });
  getPaymentOrder({
    id: movieId,
    isSubscription: isSubscription,
    tier: tier,
  })
    .then((res) => {
      track(
        TRACK_EVENT.PAYMENT_EVENT.NAME,
        TRACK_EVENT.PAYMENT_EVENT.getViewProps({
          STATUS: TRACK_EVENT.PAYMENT_EVENT.STATUS.IN_PROGRESS,
          ...res?.data?.data,
        })
      );
      identify(userName, {
        [IDENTIFY_TRAITS.PAYMENT.NAME]:
          IDENTIFY_TRAITS.PAYMENT.VALUES.IN_PROGRESS,
      });
      let data = res?.data?.data;
      if (
        res?.data?.success &&
        data?.pending &&
        data?.id &&
        (data?.amount || isSubscription) &&
        (data?.status === "created" || data?.existing || isSubscription)
      ) {
        if (isSubscription) {
          options["subscription_id"] = data?.id;
        } else {
          options["order_id"] = data?.id;
        }
        if (data?.amount_due) {
          options["amount"] = data?.amount_due;
        }
        if (data?.currency) {
          options["currency"] = data?.currency;
        }
        if (data?.notes) {
          options["notes"] = { id: data?.notes };
        }

        RazorpayCheckout.on("payment.success", (response) => // eslint-disable-line no-undef
          processPaymentResponse(
            response,
            paymentSuccess,
            paymentFailure,
            track,
            identify,
            userName,
            isSubscription
          )
        );
        RazorpayCheckout.on("payment.cancel", (res) => // eslint-disable-line no-undef
          cancelCallback(res, paymentFailure, identify, userName)
        );
        RazorpayCheckout.open(options); // eslint-disable-line no-undef
      } else if (!data?.pending) {
        paymentSuccess();
      } else {
        paymentFailure(null);
      }
    })
    .catch((error) => {
      const err = error;
      console.log(err);
      identify(userName, {
        [IDENTIFY_TRAITS.PAYMENT.NAME]: IDENTIFY_TRAITS.PAYMENT.VALUES.FAILURE,
      });
      paymentFailure(null);
    });
};
