import { InAppPurchase2 } from "@ionic-native/in-app-purchase-2";
import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
  name: "user",
  initialState: {
    userId: "",
    email: "",
    fullName: "",
    isAuthUser: null,
    plans: null,
    verified: null,
    phoneNumber: "",
  },
  reducers: {
    saveUserDetailsReducer: (state, action) => {
      state.userId = action.payload?.ref_id || "";
      state.email = action.payload?.email || "";
      state.fullName = action.payload?.full_name || "";
      state.phoneNumber = action.payload?.phone || "";
      state.verified = action.payload?.verified;
      if (action.payload?.ref_id && (action.payload?.email || action.payload?.phone)) {
        state.isAuthUser = true;
      } else {
        state.isAuthUser = false;
      }
    },
    saveUserPlansReducer: (state, action) => {
      state.plans = action?.payload;

      const plans = action?.payload?.map((p) => {
        return p?.gateways?.find((g) => g.name === "playstore_billing")
          ?.plan_id;
      });
      InAppPurchase2.verbosity = InAppPurchase2.DEBUG;
      // eslint-disable-next-line no-unused-expressions
      plans?.forEach((plan) => {
        if (!InAppPurchase2.get(plan)) {
          InAppPurchase2.register({
            type: InAppPurchase2.PAID_SUBSCRIPTION,
            id: plan,
          });
        }
      });
    },
  },
});

export const {
  saveUserDetailsReducer,
  saveUserPlansReducer,
} = userSlice.actions;

export const saveUserDetails = (data) => (dispatch) => {
  dispatch(saveUserDetailsReducer(data));
};

export const savePlanDetails = (data) => (dispatch) => {
  dispatch(saveUserPlansReducer(data));
};

export default userSlice.reducer;
