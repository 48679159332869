import React, { useEffect, useState, useRef } from "react";
import { Capacitor } from "@capacitor/core";
import "./VideoNative.scss";
import dashjs from "dashjs";
import { connect } from "react-redux";
import { ScreenOrientation } from "@ionic-native/screen-orientation";
import {
  useIonViewWillEnter,
  useIonViewWillLeave,
  getPlatforms,
} from "@ionic/react";
import ControlBar from "modules/common/ControlBar";
import "./icomoon.ttf";
import {
  Defination,
  Selecticon,
  Rewind10,
  Repeat,
  Fullscreen,
  Forward10,
} from "./Icons";
import { useAnalytics } from "use-analytics";
import {
  TRACK_EVENT,
} from "analytics/analyticsHelper";
import {
  setShowHeader,
  setShowSideMenu,
  setShowTabs,
  setDefault,
} from "store/slices/appSlice";
import Slider from "react-slick";
import localStore from "utils/localStore";
import { getBackendApiUrl } from "config/config";
import { CastCardMobileComponent } from "modules/common";
import { PageWrapperComponent } from "modules/common";
import { exit } from "ionicons/icons";

const VideoNative = ({
  url,
  movieInfo,
  setShowHeaderAction,
  setShowSideMenuAction,
  setShowTabsAction,
  setDefaultAction,
  isMobile,
  sessionId,
  continueWatchTime,
  playNextUrl,
}) => {
  const { track, page } = useAnalytics();
  const [isTimeout, setIstimeout] = useState(true);
  const [showLoader, setShowLoader] = useState(false);
  const [metaLoader, setMetaLoader] = useState(false);
  // Fullscreen and orientation for native is handled in ControlBar.js
  const [showRating, setShowRating] = useState(true);

  const playerRef = useRef(null);
  const playerDashRef = useRef(null);
  const trackData = useRef(null);

  const init = () => {
    trackData.current = {
      sessionId: sessionId,
      MovieId: movieInfo?.item?.id,
      SeasonId: movieInfo?.item?.season_id,
      CollectionId: movieInfo?.collection?.id,
      Title: movieInfo?.collection?.title,
      Type: movieInfo?.collection?.multipart ? 'Series' : 'Movie',
      Player: 'VideoNative.JS'
    };
  }
  const exit = () => {
    track(
      TRACK_EVENT.PLAYER_EVENT.NAME,
      TRACK_EVENT.PLAYER_EVENT.getProps(
        TRACK_EVENT.PLAYER_EVENT.ACTION.EXIT,
        { ...trackData.current }
      )
    );
    try {
      const currentTime = playerRef.current.currentTime;
      if(currentTime > 0 && movieInfo?.id) {
        updateMovieWatchTime(movieInfo?.id, currentTime)
      } 
    } catch(e){}
    }
  useEffect(() => {
    if (!Capacitor.isNativePlatform()) {
      init();
      return exit();
    }
  },[]);

  useEffect(() => {
    startVideo();
    setMetaLoader(true);
  }, [url]);

  const startVideo = () => {
    const videoElement = document.querySelector(".videoContainerNative video");
    const player = dashjs.MediaPlayer().create();
    const protData = {
      "org.w3.clearkey": {
        // "clearkeys": {
        // 	"oW5AK5BW43HzbTSKpiu3SQ": "hyN9IKGfWKdAwFaE5pm0qg"
        // },
        serverURL: `${getBackendApiUrl()}/license/`,
      },
    };
    player.setProtectionData(protData);

    var filterLicenseRequest = function (request) {
      /* Here you can modify/overwrite the licens request (url, headers, data...) */
      request.data = JSON.stringify(request.data);
      if (localStore.getToken()) {
        request.headers["Authorization"] = `JWT ${localStore.getToken()}`;
      }
      return Promise.resolve();
    };
    player.initialize(videoElement, url, false);
    player.registerLicenseRequestFilter(filterLicenseRequest);
    const controlbar = new ControlBar(player);
    controlbar.initialize();
    playerDashRef.current = player;
    //
    player.on("streamInitialized", (e) => {
      setMetaLoader(false);
      const p = player;

      const data = {
        url,
        event: "PlayerInitilized",
        currentTime: p?.time(),
        Platforms: getPlatforms().join(","),
        movie: movieInfo,
        sessionId: sessionId
      };

      setTimeout(() => {
        setShowRating(false);
      }, 3000);

      setTimeout(() => {
        if (continueWatchTime > 0) {
          player.seek(continueWatchTime);
          setShowLoader(false);
        }
      }, 500);

      track(
        TRACK_EVENT.PLAYER_EVENT.NAME,
        TRACK_EVENT.PLAYER_EVENT.getProps(
          TRACK_EVENT.PLAYER_EVENT.ACTION.INITILIZED,
          { ...data, ...trackData.current }
        )
      );
    });
    player.on("playbackSeeked", (e) => {
      const p = player;
      const data = {
        url,
        event: "VideoSeeked",
        currentTime: p?.time(),
        Platforms: getPlatforms().join(","),
        movie: movieInfo,
      };

      track(
        TRACK_EVENT.PLAYER_EVENT.NAME,
        TRACK_EVENT.PLAYER_EVENT.getProps(
          TRACK_EVENT.PLAYER_EVENT.ACTION.SEEK,
          { ...data, ...trackData.current }
        )
      );
    });
    player.on("playbackPaused", (e) => {
      const p = player;

      try {
        const currentTime = playerRef.current.currentTime;
        if(currentTime > 0 && movieInfo?.id) {
          updateMovieWatchTime(movieInfo?.id, currentTime)
        } 
      } catch(e){}

      if (!e?.ended) {
        const data = {
          url,
          event: "VideoPaused",
          currentTime: p?.time(),
          Platforms: getPlatforms().join(","),
          movie: movieInfo,
        };
        // track(
        //   TRACK_EVENT.PLAYER_EVENT.NAME,
        //   TRACK_EVENT.PLAYER_EVENT.getProps(
        //     TRACK_EVENT.PLAYER_EVENT.ACTION.PAUSED,
        //     data
        //   )
        // );
      }
    });
    player.on("playbackPlaying", (e) => {
      const p = player;

      if (e?.playingTime < 0) {
        const data = {
          url,
          event: "InitialPlayed",
          currentTime: p?.time(),
          duration: p.duration(),
        };
      } else {
        const data = {
          url,
          event: "InitialPlayed",
          currentTime: p?.time(),
          Platforms: getPlatforms().join(","),
          movie: movieInfo,
        };
        // track(
        //   TRACK_EVENT.PLAYER_EVENT.NAME,
        //   TRACK_EVENT.PLAYER_EVENT.getProps(
        //     TRACK_EVENT.PLAYER_EVENT.ACTION.PLAYED,
        //     data
        //   )
        // );
      }
    });
    player.on("qualityChangeRequested", (e) => {
      const p = player;
      if (e?.oldQuality > 0) {
        const data = {
          url,
          event: "QualityChanged",
          newQuality: e?.newQuality,
          oldQuality: e?.oldQuality,
          currentTime: p?.time(),
          videoQualityCount: p?.getBitrateInfoListFor("video")?.length,
          Platforms: getPlatforms().join(","),
          movie: movieInfo,
        };
        track(
          TRACK_EVENT.PLAYER_EVENT.NAME,
          TRACK_EVENT.PLAYER_EVENT.getProps(
            TRACK_EVENT.PLAYER_EVENT.ACTION.QUALITY_CHANGED,
            { ...data, ...trackData.current }
          )
        );
      }
    });
    player.on("error", (err) => {
      const p = player;
      track(
        TRACK_EVENT.PLAYER_EVENT.NAME,
        TRACK_EVENT.PLAYER_EVENT.getProps(
          TRACK_EVENT.PLAYER_EVENT.ACTION.ERROR,
          {
            error: JSON.stringify(err),
            Platforms: getPlatforms().join(","),
            movie: movieInfo,
          }
        )
      );
    });

    player.on("playbackWaiting", (err) => {
      setShowLoader(true);
    });

    player.on("playbackStarted", (err) => {
      setShowLoader(false);
    });
    player.on("playbackPlaying", (err) => {
      setShowLoader(false);
    });

    player.on("playbackEnded", () => {
      const p = player;
      const data = {
        url,
        event: "playbackEnded",
        currentTime: p?.time(),
        videoQualityCount: p?.getBitrateInfoListFor("video")?.length,
        Platforms: getPlatforms().join(","),
        movie: movieInfo,
      };
      track(
        TRACK_EVENT.PLAYER_EVENT.NAME,
        TRACK_EVENT.PLAYER_EVENT.getProps(
          TRACK_EVENT.PLAYER_EVENT.ACTION.ENDED,
          { ...data, ...trackData.current }
        )
      );
    });
  };

  const mouseMoveEvent = () => {
    try {
      if (isTimeout) {
        let timer;
        let fadeInBuffer = false;
        if (!fadeInBuffer && timer) {
          clearTimeout(timer);
          timer = 0;
          document.body.style.cursor = "";
        } else {
          document.body.style.cursor = "default";
          document.getElementById("customControl").style.opacity = 1;
          document.getElementById("videoController").style.opacity = 1;
          fadeInBuffer = false;
        }
        timer = setTimeout(() => {
          try {
            document.body.style.cursor = "none";
            document.getElementById("customControl").style.opacity = 0;
            document.getElementById("videoController").style.opacity = 0;
            fadeInBuffer = true;
            setIstimeout(true);
          } catch (e) { }
        }, 4000);
        setIstimeout(false);
      }
    } catch (e) { }
  };

  const onRewind = () => {
    track(
      TRACK_EVENT.PLAYER_EVENT.NAME,
      TRACK_EVENT.PLAYER_EVENT.getClickProps(
        TRACK_EVENT.PLAYER_EVENT.CLICK.SEEK_10_MINUS,
        { url, Platforms: getPlatforms().join(","), movie: movieInfo }
      )
    );
  };
  const onForward = () => {
    track(
      TRACK_EVENT.PLAYER_EVENT.NAME,
      TRACK_EVENT.PLAYER_EVENT.getClickProps(
        TRACK_EVENT.PLAYER_EVENT.CLICK.SEEK_10_PLUS,
        { url, Platforms: getPlatforms().join(","), movie: movieInfo }
      )
    );
  };
  const onNext = () => {
    track(
      TRACK_EVENT.PLAYER_EVENT.NAME,
      TRACK_EVENT.PLAYER_EVENT.getClickProps(
        TRACK_EVENT.PLAYER_EVENT.CLICK.NEXT,
        { url, Platforms: getPlatforms().join(","), movie: movieInfo }
      )
    );
  };
  const onPrevious = () => {
    track(
      TRACK_EVENT.PLAYER_EVENT.NAME,
      TRACK_EVENT.PLAYER_EVENT.getClickProps(
        TRACK_EVENT.PLAYER_EVENT.CLICK.PREVIOUS,
        { url, Platforms: getPlatforms().join(","), movie: movieInfo }
      )
    );
  };
  const onRepeat = () => {
    track(
      TRACK_EVENT.PLAYER_EVENT.NAME,
      TRACK_EVENT.PLAYER_EVENT.getClickProps(
        TRACK_EVENT.PLAYER_EVENT.CLICK.REPEAT,
        { url, Platforms: getPlatforms().join(","), movie: movieInfo }
      )
    );
  };
  const onFullScreen = () => {
    track(
      TRACK_EVENT.PLAYER_EVENT.NAME,
      TRACK_EVENT.PLAYER_EVENT.getClickProps(
        TRACK_EVENT.PLAYER_EVENT.CLICK.FULLSCREEN,
        { url, Platforms: getPlatforms().join(","), movie: movieInfo }
      )
    );
  };
  const onMute = () => {
    track(
      TRACK_EVENT.PLAYER_EVENT.NAME,
      TRACK_EVENT.PLAYER_EVENT.getClickProps(
        TRACK_EVENT.PLAYER_EVENT.CLICK.MUTE,
        { url, Platforms: getPlatforms().join(","), movie: movieInfo }
      )
    );
  };

  useIonViewWillEnter(() => {
    ScreenOrientation.lock(ScreenOrientation.ORIENTATIONS.LANDSCAPE);
    page(TRACK_EVENT.APP_USAGE.SCREEN.PLAYER_NATIVE);
    setShowHeaderAction(false);
    setShowSideMenuAction(false);
    setShowTabsAction(false);
    init();
  });

  useIonViewWillLeave(() => {
    setDefaultAction();
    exit();
    ScreenOrientation.lock(ScreenOrientation.ORIENTATIONS.PORTRAIT);
    try {
      playerDashRef.current.destroy();
    } catch (e) {
      if (playerRef) {
        try {
          playerRef.current.pause();
          playerRef.current.removeAttribute("src"); // empty source
          playerRef.current.load();

          const videoElement = document.querySelector(".videoContainerNative video");
          let player = dashjs.MediaPlayer().create();
          player.destroy();
          player.initialize(
            videoElement,
            '',
            false
          );
        } catch (e) { }
      }
    }
  });

  const settings = {
    dots: false,
    lazyLoad: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const playPause = () => {    
    const data = {
      url,
      currentTime: playerDashRef.current?.time(),
      videoQualityCount: playerDashRef.current?.getBitrateInfoListFor("video")?.length,
      Platforms: getPlatforms().join(",")
    };
    track(
      TRACK_EVENT.PLAYER_EVENT.NAME,
      TRACK_EVENT.PLAYER_EVENT.getProps(
        TRACK_EVENT.PLAYER_EVENT.ACTION.PLAYED_PAUSED,
        { ...data, ...trackData.current }
      )
    );
  }

  return (
    <>
      <PageWrapperComponent hideHeader hideFooter pageClass="video-native">
        <div
          className="dash-video-player-native"
          onMouseMove={mouseMoveEvent}
        >
          {/* <!-- HTML structure needed for the ControlBar --> */}

          <div className="videoContainerNative" id="videoContainerNative">
            <div className="nativeinnercontainer">
              {/* <IonLoading
                  isOpen={showLoader}
                  onDidDismiss={() => setShowLoader(false)}
                  message={"Loading..."}
                /> */}
              {(showLoader || metaLoader) && <div className="spinner"></div>}
              <div id="customControl" class={metaLoader ? 'hide' : ''}>
                {showRating && (
                  <div className="videoratingwrapnative">
                    <div className="rating main">
                      {movieInfo?.collection?.rating}
                    </div>
                    <div className="rating semi">
                      {movieInfo?.item?.rating_tags?.join(", ")}
                    </div>
                  </div>
                )}
                <div
                  id="bitrateListBtn"
                  className="control-icon-layout bitrateicon"
                  title="Bitrate List"
                >
                  <Defination />
                  <Selecticon />
                </div>
                <div
                  id="rewindBtn"
                  className="btn-rewind"
                  title="Rewind"
                  onClick={() => {
                    onRewind();
                  }}
                >
                  <span id="iconrewind" className="icon-rewind">
                    <Rewind10 />
                    <span className="icon-forward10"></span>
                  </span>
                </div>
                <div
                  id="playPauseBtn"
                  className="btn-play-pause"
                  title="Play/Pause"
                  onClick={() => playPause()}
                >
                  <span id="iconPlayPause" className="icon-play"></span>
                </div>
                <div
                  id="forwardBtn"
                  className="btn-forward"
                  title="Forward"
                  onClick={() => {
                    onForward();
                  }}
                >
                  <span id="iconforward" className="icon-forward">
                    <Forward10 />
                  </span>
                </div>
              </div>
              <video
                id="videoElement"
                className="videoElementNative"
                ref={playerRef}
                poster={movieInfo?.item?.thumbnails?.[0]}
              ></video>
              <div id="ad-container" className="videoElementNative"></div>
              <div
                id="videoController"
                className="video-controller unselectable"
              >
                <div className="seekgrid">
                  <div className="seekContainer">
                    <div id="seekbar" className="seekbar seekbar-complete">
                      <div
                        id="seekbar-buffer"
                        className="seekbar seekbar-buffer"
                      ></div>
                      <div
                        id="seekbar-play"
                        className="seekbar seekbar-play"
                      ></div>
                    </div>
                  </div>
                  <span id="videoTime" className="time-display">
                    00:00:00
                  </span>
                  <span id="videoDuration" className="duration-display">
                    00:00:00
                  </span>
                </div>
                <div className="next-prev-wrap">
                  {/* <div id="prev-btn" className="btn-prev control-icon-layout" title="Previous" onClick={() => { onPrevious() }}>
											<Prev />
										</div> */}
                  <div
                    id="repeat-btn"
                    className="btn-repeat control-icon-layout hide"
                    title="Repeat"
                    onClick={() => {
                      onRepeat();
                    }}
                  >
                    <Repeat />
                  </div>
                  {/* <div id="next-btn" className="btn-next control-icon-layout" title="Next" onClick={() => { onNext() }}>
											<Next />
										</div> */}
                </div>
                <div className="bottom-controls">
                  <div className="volume-wrap">
                    <div
                      id="muteBtn"
                      className="btn-mute control-icon-layout"
                      title="Mute"
                      onClick={() => {
                        onMute();
                      }}
                    >
                      <span id="iconMute" className="icon-mute-off"></span>
                    </div>
                    <input
                      type="range"
                      id="volumebar"
                      className={`volumebar ${isMobile ? "hide" : ""}`}
                      min="0"
                      max="1"
                      step=".01"
                    />
                  </div>
                  <div className="right-controls">
                    <div
                      id="trackSwitchBtn"
                      className="control-icon-layout hide"
                      title="A/V Tracks"
                    >
                      <span className="icon-tracks"></span>
                    </div>
                    <div
                      id="captionBtn"
                      className="btn-caption control-icon-layout"
                      title="Closed Caption"
                    >
                      <span className="icon-caption"></span>
                    </div>
                    <div
                      id="fullscreenBtn"
                      className="btn-fullscreen control-icon-layout"
                      title="Fullscreen"
                      onClick={() => {
                        onFullScreen();
                      }}
                    >
                      {/* <!-- <span className="icon-fullscreen-enter"></span> --> */}
                      <Fullscreen />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {movieInfo && isMobile && (
          <div>
            <div></div>
            <div className="movieInfoContainer">
              <div className="font-bold">
                <div className="titleBlock">
                  <div>
                    <h2 className="title">{movieInfo?.collection?.title}</h2>
                    <ul className="movie-details-wrap">
                      <li className="movie-details-list">
                        {movieInfo?.item?.duration}
                      </li>
                      <li className="movie-details-list">
                        {movieInfo?.collection?.rating}
                      </li>
                      <li className="movie-details-list">English</li>
                      <li className="movie-details-list">
                        {movieInfo?.collection?.genre?.[0]}
                      </li>
                      <li className="movie-details-list">
                        {movieInfo?.item?.rating_tags}
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="descriptionBlock">
                  <p>{movieInfo?.collection?.description}</p>
                  <div className="cast-card-wrap">
                    <h5 className="cast-title">Cast:</h5>
                    <div className="cast-card-slider-wrap">
                      <Slider {...settings}>
                        {movieInfo?.artists?.map((actor, key) => (
                          <CastCardMobileComponent key={key} actor={actor} />
                        ))}
                      </Slider>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </PageWrapperComponent>
    </>
  );
};

const mapStateToProps = ({ payment }) => ({
  url: payment?.playerUrl?.url,
  sessionId: payment?.playerUrl?.sessionId,
  movieInfo: payment?.movieInfo,
    continueWatchTime: payment?.continueWatchTime,
  playNextUrl: payment?.playNextUrl,
});

const mapDispatchToProps = {
  setShowHeaderAction: setShowHeader,
  setShowSideMenuAction: setShowSideMenu,
  setShowTabsAction: setShowTabs,
  setDefaultAction: setDefault,
};

export default connect(mapStateToProps, mapDispatchToProps)(VideoNative);
