import React, { useState, useEffect } from "react";
import PlayButton from "../Play/PlayButton";
import RippleEffect from "../Ripple/Ripple";
import "./EpisodeListMobile.scss";

const EpisodeListMobile = ({ collection, onWatchClick, selectedSeason }) => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    setCount(collection?.length || 0);
  }, [collection]);

  return (
    <div>
      <div className="series-mobile">
        {collection?.seasons[selectedSeason]?.items?.map((item, i) => (
          <RippleEffect key={`${selectedSeason}-${i}`}>
            <PlayButton movieId={item?.id} collectionId={item?.collection_id} collectionItemCombo={{
              collection: {...collection, seasons: null},
              item: item
            }} >
            <div className="episodeCard-mobile">
              <div className="episodeImg-mobile">
                <img src={item?.thumbnails?.[0]} alt="" />
                <div className="mask">
                  <img className="play-icon" src="https://jugnu.ams3.cdn.digitaloceanspaces.com/001staticimageassets/Icons/Play-Inside-Episode-Cards/2/play-Icon.svg" alt="" />
                </div>
              </div>
              <div className="episodeInfo-mobile">
                <h2 className="title-font">{item?.prefix || item?.title}</h2>
                <h2 className="description-font">{item?.description.slice(0, 90)}</h2>
              </div>
            </div>  
            </PlayButton>
          </RippleEffect>
        ))}
      </div>
    </div>
  );
};

export default EpisodeListMobile;