import React, { useEffect, useState } from "react";
import "./Coupon.scss";
import PropTypes from "prop-types";
import {  IonButton, useIonViewWillLeave } from "@ionic/react";
import { setPageTitle } from "../../../../helpers/seoHelper";
import { generateCoupon } from "actions/services/user.service";
import { PageWrapperComponent } from "modules/common";


const propTypes = {
  couponData: PropTypes.string,
};

const Coupon = ({couponData}) => {

  const [data, setData] = useState(null);
  const [error, setError] = useState('');
  const [generatedCode, setGeneratedCode] = useState(false);
  const [showMessage, setShowMessage] = useState("click to copy");

  useEffect(() => {
    setPageTitle('COUPON');
  }, []);

  const onGenerateSubscription = () => {
    generateCoupon().then((res) => {
      const data = res?.data?.data;
      if (res?.data?.success) {
        setData(data);
        setError('');
      } else {
        setData(null);
        setError(data?.message);
      }
    });
    setGeneratedCode(true);
  };

  const onCopyClick = () => {
    navigator.clipboard.writeText(data?.code);
    setShowMessage("code copied")
  }

  useIonViewWillLeave(() => {
    setData(null);
    setError('');
  });


  const renderCoupon = () => {
    return (<><div className="exclusive-code">
      Your Exclusive Gift Code:
      <span onClick={() => { onCopyClick() }}>{showMessage}</span>
      
    </div>
    <div className="coupon-code">
      {data?.code}
    </div></>)
  }

  const renderSoldOut = () => {
    return (<><div className="exclusive-code"><div className="coupon-code">
    We are all sold out!
  </div></div></>)
  }

  return (
    <>
      <PageWrapperComponent>
          <div className="coupon-container">
            <div className="coupon-container-header d-flex">
              <img src="https://nine-rasa-all.ams3.cdn.digitaloceanspaces.com/Nine%20Rasa%20Golden%20Logo-H%20%282%29%20%281%29.png" alt="logo" />
            </div>
            <div className="coupon-container-body">
              <div className="coupon-container-body-left">
                <div className="coupon-container-body-left-heading">
                  How to redeem gift codes?
                </div>
                <div className="steps-wrap">
                  <div className="step d-flex align-items-center">
                    <div className="step-text">
                      Step 1: Genrate your coupon here
                    </div>
                    <div className="step-img">
                      <img src="https://nine-rasa-all.ams3.cdn.digitaloceanspaces.com/0couponimages/coupon-step1.svg" alt="step1" />
                    </div>
                  </div>
                  <div className="step d-flex align-items-center flex-row-reverse">
                    <div className="step-text">
                      Step 2: Visit us at Ott.com or via our mobile apps
                    </div>
                    <div className="step-img">
                      <img src="https://nine-rasa-all.ams3.cdn.digitaloceanspaces.com/0couponimages/coupon-step2.svg" alt="step2" />
                    </div>
                  </div>
                  <div className="step d-flex align-items-center">
                    <div className="step-text">
                      Step 3: visit the account page and redeem your coupon.
                      Visiting us for the fist time? you can also redeem your coupon while signing-up.
                    </div>
                    <div className="step-img">
                      <img src="https://nine-rasa-all.ams3.cdn.digitaloceanspaces.com/0couponimages/coupon-step3.svg" alt="step3" />
                    </div>
                  </div>
                  <div className="step d-flex align-items-center flex-row-reverse">
                    <div className="step-text">
                      Step 4: Enjoy all our premium content!!
                    </div>
                    <div className="step-img">
                      <img src="https://nine-rasa-all.ams3.cdn.digitaloceanspaces.com/0couponimages/coupon-step4.svg" alt="step4" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="coupon-container-body-right">
                <div className="coupon-container-body-right-heading">
                  Welcome To Ott Gift Code Genrator.
                </div>
                <div className="coupon-generator-box d-flex justify-content-center align-items-center">
                  {!data &&
                    <IonButton
                      target="_blank"
                      size="big"
                      onClick={onGenerateSubscription}
                    >
                      Click Here
                    </IonButton>
                  }
                  {generatedCode &&
                    <div className="coupon-code-wrap">
                      {data?.code == null ? renderSoldOut(): renderCoupon() }
                      <div className="link">
                        <a href="Ott.com">
                          Click Here To Visit Ott.com
                        </a>
                      </div>
                    </div>
                  }
                </div>
                <div className="redeem-coupon-steps">

                  <div className="heading">Steps to redeem your gift coupon</div>
                  
                  <div className="users">For new Users</div>
                  <ul className="couponsteps">
                    <li>Go to www.Ott.com.</li>
                    <li>If you are a new member, enter your details and complete registration.</li>
                    <li>just above the signup button you can see a dedicated box for coupons.</li>
                  </ul>
                  <div className="users">For Members</div>
                  <ul className="couponsteps">
                    <li>If you are a registered member, Login to your account and click on account button located at the bottom right
                      of home page. Here you will find a dedicated box for coupons.</li>
                    <li>Enter the code and enjoy the content anytime and at any device!</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          </PageWrapperComponent>
    </>
  );
};

export default Coupon;
