import React, { useState } from "react";
import "./Coins.scss";
import {
  useIonViewWillEnter,
  useIonViewWillLeave,
  IonThumbnail,
  IonSkeletonText,
} from "@ionic/react";
import { PageWrapperComponent } from "modules/common";
import { getProducts } from "../../../../actions/services";

const Product = ({ product }) => {
  const [isLoading, setIsLoading] = useState(true);

  return (
    <div className="coin-card">
      <div className="product-details">
        <img
          src={product?.image}
          alt={product?.name}
          style={{ visibility: isLoading ? "hidden" : "visible" }}
          onLoad={() => setIsLoading(false)}
        />
      </div>
      <div className="product-desc">{product?.name}</div>
      <div className="product-price">
        <div className="actual-price">
          <strike>&#8377; {product?.price}</strike>
        </div>
        <div className="discounted-price">
          {" "}
          &#8377; {product?.discount_price}
        </div>
      </div>
    </div>
  );
};

const Coins = ({ handleSelect, locale }) => {
  const [products, setProducts] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useIonViewWillEnter(() => {
    getProducts()
      .then((res) => {
        if (res?.data?.success && res?.data?.data) {
          setProducts(res?.data?.data);
        }
      })
      .catch((err) => { })
      .finally(() => setIsLoading(false));
  });

  useIonViewWillLeave(() => {
    setProducts(null);
    setIsLoading(true);
  });

  return (
    <>
      <PageWrapperComponent>
        <div className="coins-container">
          <div className="coins">
            <div className="coin-head-block">
              <div className="coin-box">
                <div className="total-coins">
                  <img src="https://private-ott.ams3.cdn.digitaloceanspaces.com/coin.jpeg" />
                  500
                </div>
                <div className="coins-earned">Coins Earned</div>
                <div className="coins-desc">
                  Use Coins to buy exclusive content & products{" "}
                </div>
              </div>
            </div>
            <div className="tabs-wrapper">
              <div className="coin-card-wrapper">
                {isLoading
                  ? [0, 1, 2, 3].map((s, i) => (
                    <IonThumbnail
                      key={`coin-thumb-${i}`}
                      className="skeleton-card-short"
                      style={{
                        height: "150px",
                        width: "40%",
                        margin: "5px",
                      }}
                    >
                      <IonSkeletonText animated />
                    </IonThumbnail>
                  ))
                  : products?.map((product, i) => (
                    <Product key={`product-${i}`} product={product} />
                  ))}
              </div>
            </div>
          </div>
        </div>
      </PageWrapperComponent>
    </>
  );
};

export default Coins;
