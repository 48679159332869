import React from "react";
import "./CastList.scss";

const CastList = ({ artists }) => {
  console.log(artists);
  const artistsData = artists?.map((artist, i) => (
    <div className="col-sm-4 col-md-3">
      <div className="castimg-wrap">
        <img src={artist.avatar}></img>
        <figcaption>{artist.name}</figcaption>
      </div>
    </div>
  ));

  return (
    <>
      <div className="castpopup-body-wrap">
        <div className="row">{artistsData}</div>
      </div>
    </>
  );
};

export default CastList;
