import { ANALYTICS_BACKEND_API, APP_BACKEND_API } from "../clientConfig";

const enviroments = {
  dev: "dev",
  prod: "prod"
};

const getAnalyticsUrl = (env = "dev") => {
  return ANALYTICS_BACKEND_API;
};

const getBackendApiUrl = (env = "dev") => {
  return APP_BACKEND_API;
};

export { getAnalyticsUrl, getBackendApiUrl };
