import React, { useState, useEffect } from "react";
import {
  isPlatform,
  getPlatforms,
  IonLoading,
  IonButton
} from "@ionic/react";
import { useAnalytics } from "use-analytics";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import { InAppPurchase2 } from "@ionic-native/in-app-purchase-2";
import { payWithRazor } from "modules/common/services/RazorPayPaymentService";
import { payWithRazorNative } from "modules/common/services/RazorPayPaymentNativeService";
import {
  saveFromApp,
  setPlayerUrl,
} from "store/slices/paymentSlice";
import { setIsNative } from "store/slices/appSlice";
import { PAYMENT } from "../../../../clientConfig";
import { savePlanDetails } from "store/slices/userSlice";
import {
  getAllPlans,
  verifyGooglePlayPurchase,
} from "actions/services/user.service";
import { TRACK_EVENT } from "analytics/analyticsHelper";
import { PageWrapperComponent } from "modules/common";
import { MoviePaymentComponent } from "modules/common";


const Watch = ({
  match,
  setIsNativeAction,
  saveFromAppAction,
  userPlans,
  savePlanDetailsAction,
}) => {
  const userName = "";
  const { track, page, identify } = useAnalytics();
  const history = useHistory();
  const [tier, setTier] = useState(null);
  // TODO: fetch it from backend if pending initite the payment otherwise open the video
  const [showPaymentPop, setShowPaymentPop] = useState(false);
  const [isnative, setIsNative] = useState(false);
  const [showLoading, setShowLoading] = useState(true);
  const [isSubscription, setIsSubscription] = useState(false);
  const [movieId, setMovieId] = useState(null);
  const [collectionId, setCollectionId] = useState(null);
  const [hidePayePerView, setHidePayePerView] = useState(
    !PAYMENT.IS_PAY_PER_VIEW
  );
  const [paymentGateway, setPaymentGateway] = useState(null);
  const [showPaymentGateway, setShowPaymentGateway] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isFailure, setIsFailure] = useState(false);

  const initiateOrder = (planId) => {
    try {

      const plan = InAppPurchase2.get(planId);

      if (plan.valid && plan.loaded && !plan.owned) {
        InAppPurchase2.off();
        InAppPurchase2.error((err) => {
          alert(JSON.stringify(err));
        });
        InAppPurchase2.when(planId).cancelled(() => {
        });
        InAppPurchase2.when(planId).approved((product) => {
          verifyGooglePlayPurchase({
            transaction: product?.transaction,
            gateway: paymentGateway
          }).then((res) => {
            if (res?.data?.success) {
              product.finish();
              setShowLoading(false);
              setIsSuccess(true);
              setIsPayemntPending(false);
              history.goBack();
            } else{
              setIsFailure(true);
              alert('Some Backend issue occurred')
            }
          });
        });
        InAppPurchase2.order(planId);
      } else {
        if (!plan.loaded) {
          alert('Plan is not loaded yet');
        } else if (!plan.valid) {
          alert('Plan is not valid for purchase');
        } else if (plan.owned) {
          verifyGooglePlayPurchase({
            transaction: plan.transaction,
            gateway: paymentGateway
          }).then((res) => {
            if (res?.data?.success) {
              plan.finish();
              setShowLoading(false);
              setIsSuccess(true);
              setIsPayemntPending(false);
              history.goBack();
            } else{
              setIsFailure(true);
              alert('Some Backend issue occurred')
            }
          });
        }
      }
    } catch (err) { }
  };
  useEffect(() => {
    InAppPurchase2.refresh();
  }, [userPlans]);

  const getPlans = () => {
    getAllPlans().then((plans) => {
      savePlanDetailsAction(plans?.data?.data);
    });
  };

  useEffect(() => {
    page(TRACK_EVENT.APP_USAGE.SCREEN.Payment);
    if (!userPlans) {
      setShowLoading(true);
      getPlans();
    } else {
      const plans = userPlans?.map((p) => {
        return p?.gateways?.find((g) => g.name === "playstore_billing")
          ?.plan_id;
      });
      setShowLoading(false);
    }
    saveFromAppAction(false);
    const m_id = match?.params?.movieId;
    const c_id = match?.params?.collectionId;
    setMovieId(m_id);
    setCollectionId(c_id);
    const native =
      (isPlatform("ios") || isPlatform("android")) && !isPlatform("mobileweb");
    setIsNativeAction(native);
    setIsNative(native);
    setHidePayePerView(true);
    setShowPaymentPop(true);

    return () => InAppPurchase2.off();
  }, []);

  const paymentSuccess = () => {
    setIsSuccess(true);
    setIsPayemntPending(false);
    setShowLoading(false);
    history.goBack();
    if (!isnative) {
      history.goBack();
    }
    track(
      TRACK_EVENT.PAYMENT_EVENT.NAME,
      TRACK_EVENT.PAYMENT_EVENT.getViewProps(
        {
          Status: TRACK_EVENT.PAYMENT_EVENT.STATUS.PAYMENT_DONE,
          MovieId: movieId,
          collectionId: collectionId,
          Platforms: getPlatforms().join(','),
          PaymentGateway: paymentGateway
        }
      )
    );
  };
  const paymentFailure = (error) => {
    setShowLoading(false);
    setIsFailure(true);
    if (error) {
      alert(JSON.stringify(error));
      history.goBack();
    } else {
      alert("Something bad happened");
      history.goBack();
    }
    if (!isnative) {
      history.goBack();
    }
    track(
      TRACK_EVENT.PAYMENT_EVENT.NAME,
      TRACK_EVENT.PAYMENT_EVENT.getViewProps(
        {
          Status: TRACK_EVENT.PAYMENT_EVENT.STATUS.FAILURE,
          Error: JSON.stringify(error),
          MovieId: movieId,
          collectionId: collectionId,
          Platforms: getPlatforms().join(','),
          PaymentGateway: paymentGateway
        }
      )
    );
  };

  useEffect(() => {
    if (tier) {
      setShowPaymentGateway(true);
      track(
        TRACK_EVENT.APP_USAGE.NAME,
        TRACK_EVENT.APP_USAGE.getClickProps(
          TRACK_EVENT.APP_USAGE.CLICK_OPTIONS.PAYMENT_OPTION,
          TRACK_EVENT.APP_USAGE.SCREEN.Payment,
          {
            Tier: tier,
            MovieId: movieId,
            CollectionId: collectionId
          }
        )
      );
    }
  }, [tier]);

  const triggerRazorPay = () => {
    const planId = userPlans
      ?.find((p) => p.period === tier)
      ?.gateways?.find((g) => g.name === paymentGateway)?.plan_id;
    if (isnative) {
      payWithRazorNative(
        movieId,
        userName,
        paymentSuccess,
        paymentFailure,
        isSubscription,
        planId,
        track,
        identify
      );
    } else {
      payWithRazor(
        movieId,
        userName,
        paymentSuccess,
        paymentFailure,
        isSubscription,
        planId,
        track,
        identify
      );
    }
  };

  const triggerInAppPurchase = () => {
    const planId = userPlans
      ?.find((p) => p.period === tier)
      ?.gateways?.find((g) => g.name === paymentGateway)?.plan_id;
    initiateOrder(planId);
  };

  useEffect(() => {
    if (tier && paymentGateway) {
      setShowLoading(true);
      InAppPurchase2.refresh();
      setPaymentGateway(null);
      if (paymentGateway === "playstore_billing" || paymentGateway === "appstore_billing") {
        triggerInAppPurchase();
      } else {
        triggerRazorPay();
      }
      track(
        TRACK_EVENT.APP_USAGE.NAME,
        TRACK_EVENT.APP_USAGE.getClickProps(
          TRACK_EVENT.APP_USAGE.CLICK_OPTIONS.PAYMENT_METHOD,
          TRACK_EVENT.APP_USAGE.SCREEN.Payment,
          {
            PaymentGateway: paymentGateway,
            Tier: tier,
            MovieId: movieId,
            CollectionId: collectionId
          }
        )
      );
    }
  }, [paymentGateway]);

  return (
    <>
      <PageWrapperComponent>
        <IonLoading
          isOpen={showLoading}
          onDidDismiss={() => setShowLoading(false)}
          message={"Please wait..."}
          duration={5000}
        />
        {
            isSuccess && 
            <div className="payment-success-section">
              <div className="payment-heading">
                Your Payment is Successful
              </div>
              <IonButton
                className="btnprimary"
                color="secondary"
                shape="round"
                size="default"
                onClick={() => history.push("/")}
              >
                Go To Home
              </IonButton>
            </div>
          }
        {showPaymentPop && (
          <MoviePaymentComponent
            userPlans={userPlans}
            tier={tier}
            setTier={setTier}
            closePaymentPopUp={setShowPaymentPop}
            setIsSubscription={setIsSubscription}
            hidePayePerView={hidePayePerView}
            setPaymentGateway={setPaymentGateway}
            showPaymentGateway={showPaymentGateway}
          />
        )}
      </PageWrapperComponent>
    </>
  );
};

const mapStateToProps = ({ payment, user }) => {
  return {
    fromApp: payment?.fromApp,
    userPlans: user?.plans,
  };
};
const mapActionToProps = {
  setPlayerUrlAction: setPlayerUrl,
  setIsNativeAction: setIsNative,
  saveFromAppAction: saveFromApp,
  savePlanDetailsAction: savePlanDetails,
};

export default connect(mapStateToProps, mapActionToProps)(Watch);
