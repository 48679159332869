import {
  getPaymentOrder,
  verifyPaymentOrder,
} from "actions/services/user.service";
import {
  IDENTIFY_TRAITS,
  TRACK_EVENT,
} from "analytics/analyticsHelper";
import { BUSINESS_CONFIG, LOGO, RAZORPAY_THEME } from "../../../clientConfig";

const processPaymentResponse = (
  response,
  paymentSuccess,
  paymentFailure,
  track,
  identify,
  userName,
  isSubscription
) => {
  track(
    TRACK_EVENT.PAYMENT_EVENT.NAME,
    TRACK_EVENT.PAYMENT_EVENT.getViewProps({
      STATUS: TRACK_EVENT.PAYMENT_EVENT.STATUS.PAYMENT_DONE,
      ...response,
    })
  );
  identify(userName, {
    [IDENTIFY_TRAITS.PAYMENT.NAME]: IDENTIFY_TRAITS.PAYMENT.VALUES.PAYMENT_DONE,
  });

  if (
    response?.razorpay_payment_id &&
    (response?.razorpay_order_id || response?.razorpay_subscription_id) &&
    response?.razorpay_signature
  ) {
    verifyPaymentOrder({
      razorpay_signature_id: response?.razorpay_signature,
      razorpay_subscription_id: response?.razorpay_subscription_id,
      razorpay_payment_id: response?.razorpay_payment_id,
      razorpay_order_id: response?.razorpay_order_id,
    }).then((res) => {
      if (res?.statusText === "OK" && res?.data?.success) {
        track(
          TRACK_EVENT.PAYMENT_EVENT.NAME,
          TRACK_EVENT.PAYMENT_EVENT.getViewProps({
            STATUS: TRACK_EVENT.PAYMENT_EVENT.STATUS.VERIFIED,
            ...res?.data?.data,
          })
        );
        identify(userName, {
          [IDENTIFY_TRAITS.PAYMENT.NAME]:
            IDENTIFY_TRAITS.PAYMENT.VALUES.VERIFIED,
        });
        paymentSuccess();
      } else {
        paymentFailure(null);
      }
    });
  }
  if (
    response?.razorpay_payment_id &&
    response?.razorpay_order_id &&
    response?.razorpay_signature &&
    !isSubscription
  ) {
    verifyPaymentOrder({
      razorpay_signature_id: response?.razorpay_signature,
      razorpay_payment_id: response?.razorpay_payment_id,
      razorpay_order_id: response?.razorpay_order_id,
    }).then((res) => {
      if (res?.statusText === "OK" && res?.data?.success) {
        track(
          TRACK_EVENT.PAYMENT_EVENT.NAME,
          TRACK_EVENT.PAYMENT_EVENT.getViewProps({
            STATUS: TRACK_EVENT.PAYMENT_EVENT.STATUS.VERIFIED,
            ...res?.data?.data,
          })
        );
        identify(userName, {
          [IDENTIFY_TRAITS.PAYMENT.NAME]:
            IDENTIFY_TRAITS.PAYMENT.VALUES.VERIFIED,
        });
        paymentSuccess();
      } else {
        paymentFailure(null);
      }
    });
  }
};

export const payWithRazor = (
  movieId,
  userName,
  paymentSuccess,
  paymentFailure,
  isSubscription,
  tier,
  track,
  identify
) => {
  const prefill = {
    name: userName,
  };
  track(
    TRACK_EVENT.APP_USAGE.NAME,
    TRACK_EVENT.APP_USAGE.getClickProps(
      TRACK_EVENT.APP_USAGE.CLICK_OPTIONS.PAYMENT_CLICK,
      TRACK_EVENT.APP_USAGE.SCREEN.PAYMENT,
      prefill
    )
  );

  var options = {
    key: BUSINESS_CONFIG.RazorPayKey, // Enter the Key ID generated from the Dashboard
    // "amount": "50000", // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
    // "currency": "INR",
    // "name": "Acme Corp",

    image: LOGO,
    payment_capture: true,
    // "order_id": "order_9A33XWu170gUtm", //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
    handler: function(response) {
      processPaymentResponse(
        response,
        paymentSuccess,
        paymentFailure,
        track,
        identify,
        userName,
        isSubscription
      );
    },
    prefill: {
      prefill,
    },
    notes: {
      address: "Razorpay Corporate Office",
    },
    theme: {
      color: RAZORPAY_THEME,
    },
    modal: {
      ondismiss: () => paymentFailure(),
    },
  };
  track(
    TRACK_EVENT.PAYMENT_EVENT.NAME,
    TRACK_EVENT.PAYMENT_EVENT.getViewProps({
      STATUS: TRACK_EVENT.PAYMENT_EVENT.STATUS.INIT,
      ...prefill,
    })
  );
  identify(userName, {
    [IDENTIFY_TRAITS.PAYMENT.NAME]: IDENTIFY_TRAITS.PAYMENT.VALUES.INIT,
  });
  getPaymentOrder({
    id: movieId,
    isSubscription: isSubscription,
    tier: tier,
  })
    .then((res) => {
      track(
        TRACK_EVENT.PAYMENT_EVENT.NAME,
        TRACK_EVENT.PAYMENT_EVENT.getViewProps({
          STATUS: TRACK_EVENT.PAYMENT_EVENT.STATUS.IN_PROGRESS,
          ...res?.data?.data,
        })
      );
      identify(userName, {
        [IDENTIFY_TRAITS.PAYMENT.NAME]:
          IDENTIFY_TRAITS.PAYMENT.VALUES.IN_PROGRESS,
      });
      let data = res?.data?.data;
      if (
        res?.data?.success &&
        data?.pending &&
        data?.id &&
        (data?.amount || isSubscription) &&
        (data?.status === "created" || data?.existing || isSubscription)
      ) {
        if (isSubscription) {
          options["subscription_id"] = data?.id;
        } else {
          options["order_id"] = data?.id;
        }
        if (data?.amount_due) {
          options["amount"] = data?.amount_due;
        }
        if (data?.currency) {
          options["currency"] = data?.currency;
        }
        if (data?.notes) {
          options["notes"] = { id: data?.notes };
        }
        var rzp1 = new Razorpay(options); // eslint-disable-line no-undef
        rzp1.on("payment.failed", function(response) {
          const errorData = {
            code: response.error.code,
            description: response.error.description,
            payment_id: response.error.metadata.payment_id,
            order_id: response.error.metadata.order_id,
            reason: response.error.reason,
            source: response.error.source,
            step: response.error.step,
          };
          track(
            TRACK_EVENT.PAYMENT_EVENT.NAME,
            TRACK_EVENT.PAYMENT_EVENT.getViewProps({
              STATUS: TRACK_EVENT.PAYMENT_EVENT.STATUS.FAILURE,
              ...errorData,
            })
          );
          paymentFailure(errorData);
        });
        rzp1.open();
      } else if (!data?.pending) {
        paymentSuccess();
      } else {
        paymentFailure(null);
      }
    })
    .catch((error) => {
      const err = error;
      console.log(err);
      identify(userName, {
        [IDENTIFY_TRAITS.PAYMENT.NAME]: IDENTIFY_TRAITS.PAYMENT.VALUES.FAILURE,
      });
      paymentFailure(null);
    });
};
