import { ROUTES, ROUTES_ID } from "helpers/routeHelper";
import {
  AuthWeb,
  HomeWeb,
  MovieListWeb,
  SettingsWeb,
  NotFoundWeb,
  SearchResultWeb,
  ProfileWeb,
  WatchWeb,
  MovieInfoMobileWeb,
  MovieInfoWeb,
  ExploreWeb,
  SeriesListWeb,
  SeriesInfoWeb,
  WatchHistoryWeb,
  PaymentHistoryWeb,
  CoinsWeb,
  CouponWeb,
  VideoPlayerIosSafari,
  VideoJsPlayer,
  VideoPlayer,
  VideoPlayerIosMobileWeb,
  ShopWeb,
  AboutUsWeb,
  DisclaimerWeb,
  TermsOfServiceWeb,
  PrivacyPolicyWeb,
  HelpandSupportWeb,
  GenreListWeb
} from ".";
import { ComponentWrapperComponent } from "modules/common";
import { isPlatform } from "@ionic/react";
import { isMobileSafari, isSafari } from 'react-device-detect';
import { Redirect, Switch, Route } from 'react-router-dom';

// import  {
// VideoNative
// } from "./../native";

const getPlayer = () => {
  // IOS SAFARI DESKTOP
  if(isSafari && !isPlatform("mobileweb")) {
    // return VideoJsPlayer;
    return VideoPlayerIosSafari;
  }
  // IOS Web/MOBILE WEB
  if (isMobileSafari) {
    // return VideoJsPlayer;
    return VideoPlayerIosMobileWeb;
  }
  return VideoPlayer;
};

export const getWebRoutes = (isMobile, propsToPassed) => {
  const routes = [
    <ComponentWrapperComponent
      key="home"
      routeId={ROUTES_ID.HOME}
      path={ROUTES.HOME}
      component={HomeWeb}
      {...propsToPassed}
    />,
    <ComponentWrapperComponent
      key="movie_list"
      routeId={ROUTES_ID.MOVIES_LIST}
      path={ROUTES.MOVIES_LIST}
      component={MovieListWeb}
      {...propsToPassed}
    />,
    <ComponentWrapperComponent
      key="movie_list"
      routeId={ROUTES_ID.MOVIES_LIST}
      path={ROUTES.MOVIES}
      component={MovieListWeb}
      {...propsToPassed}
    />,
    <ComponentWrapperComponent
      key="series_list"
      routeId={ROUTES_ID.SERIES_LIST}
      path={ROUTES.SERIES_LIST}
      component={SeriesListWeb}
      {...propsToPassed}
    />,
    <ComponentWrapperComponent
      key="auth"
      routeId={ROUTES_ID.AUTH}
      path={ROUTES.AUTH}
      component={AuthWeb}
      {...propsToPassed}
    />,
    <ComponentWrapperComponent
      key="user_profile"
      routeId={ROUTES_ID.USER_PROFILE}
      path={ROUTES.USER_PROFILE}
      component={ProfileWeb}
      authRoute={true}
      {...propsToPassed}
    />,
    <ComponentWrapperComponent
      key="search"
      routeId={ROUTES_ID.SEARCH}
      path={ROUTES.SEARCH}
      component={SearchResultWeb}
      {...propsToPassed}
    />,
    <ComponentWrapperComponent
      key="settings"
      routeId={ROUTES_ID.SETTINGS}
      path={ROUTES.SETTINGS}
      component={SettingsWeb}
      {...propsToPassed}
    />,
    <ComponentWrapperComponent
      key="payment_history"
      routeId={ROUTES_ID.PAYMENT_HISTORY}
      path={ROUTES.PAYMENT_HISTORY}
      component={PaymentHistoryWeb}
      authRoute={true}
      {...propsToPassed}
    />,
    <ComponentWrapperComponent
      key="watch_history"
      routeId={ROUTES_ID.WATCH_HISTORY}
      path={ROUTES.WATCH_HISTORY}
      component={WatchHistoryWeb}
      authRoute={true}
      {...propsToPassed}
    />,
    <Route
      exact
      key="home"
      path="/"
      render={() => <Redirect to={ROUTES.HOME} />}
    />,
    <ComponentWrapperComponent
      key="movie_info"
      routeId={ROUTES_ID.MOVIE_INFO}
      path={ROUTES.MOVIE_INFO}
      component={isMobile ? MovieInfoMobileWeb : MovieInfoWeb}
      {...propsToPassed}
    />,
    <ComponentWrapperComponent
      key="series_info"
      routeId={ROUTES_ID.SERIES_INFO}
      path={ROUTES.SERIES_INFO}
      component={SeriesInfoWeb}
      {...propsToPassed}
    />,
    <ComponentWrapperComponent
      key="watch"
      routeId={ROUTES_ID.WATCH}
      path={ROUTES.WATCH}
      component={WatchWeb}
      // authRoute={true}
      {...propsToPassed}
    />,
    <ComponentWrapperComponent
      key="_watch"
      routeId={ROUTES_ID.WATCH_SUBSCRIBE}
      path={ROUTES.WATCH_SUBSCRIBE}
      component={WatchWeb}
      // authRoute={true}
      {...propsToPassed}
    />,
    <ComponentWrapperComponent
      key="player"
      routeId={ROUTES_ID.PLAYER}
      path={ROUTES.PLAYER}
      component={getPlayer()}
    // authRoute={true}
    />,
    <ComponentWrapperComponent
      key="coins"
      routeId={ROUTES_ID.COINS}
      path={ROUTES.COINS}
      component={CoinsWeb}
      authRoute={true}
      {...propsToPassed}
    />,
    <ComponentWrapperComponent
      key="explore"
      routeId={ROUTES_ID.EXPLORE}
      path={ROUTES.EXPLORE}
      component={ExploreWeb}
      // authRoute={true}
      {...propsToPassed}
    />,
    <ComponentWrapperComponent
      key="coupons"
      routeId={ROUTES_ID.COUPON}
      path={ROUTES.COUPON}
      component={CouponWeb}
      {...propsToPassed}
    />,
    <ComponentWrapperComponent
    key="shop"
    routeId={ROUTES_ID.SHOP}
    path={ROUTES.SHOP}
    component={ShopWeb}
    {...propsToPassed}
  />,
  <ComponentWrapperComponent
  key="aboutus"
  routeId={ROUTES_ID.ABOUT_US}
  path={ROUTES.ABOUT_US}
  component={AboutUsWeb}
  {...propsToPassed}
/>,
<ComponentWrapperComponent
  key="description"
  routeId={ROUTES_ID.DISCLAIMER}
  path={ROUTES.DISCLAIMER}
  component={DisclaimerWeb}
  {...propsToPassed}
/>,
<ComponentWrapperComponent
  key="termsofservice"
  routeId={ROUTES_ID.TERMS_OF_SERVICE}
  path={ROUTES.TERMS_OF_SERVICE}
  component={TermsOfServiceWeb}
  {...propsToPassed}
/>,
<ComponentWrapperComponent
  key="privacypolicy"
  routeId={ROUTES_ID.PRIVACY_POLICY}
  path={ROUTES.PRIVACY_POLICY}
  component={PrivacyPolicyWeb}
  {...propsToPassed}
/>,
<ComponentWrapperComponent
  key="helpandsupport"
  routeId={ROUTES_ID.HELP_AND_SUPPORT}
  path={ROUTES.HELP_AND_SUPPORT}
  component={HelpandSupportWeb}
  {...propsToPassed}
/>,
<ComponentWrapperComponent
      key="genre_list"
      routeId={ROUTES_ID.GENRE_LIST}
      path={ROUTES.GENRE_LIST}
      component={GenreListWeb}
      {...propsToPassed}
    />,
    <ComponentWrapperComponent key="not_found" component={NotFoundWeb} />,
  ];

  return <Switch>{routes}</Switch>
};
