import React, { useState } from "react";
import "./SuccessPayment.scss";
import { useAnalytics } from "use-analytics";
import { IonButton } from "@ionic/react";
import { connect } from "react-redux";
import { saveUserDetails } from "store/slices/userSlice";

const SuccessPayment = ({ setShow }) => {
  const { track, page, identify } = useAnalytics();

  return (
    <div className={`ionPage`}>
      <div className="main-body">
        <div className="successpayment">
          <div className="main-heading-container">
            <div className="main-heading">Email Verified</div>
            <div className="main-heading-sub">
              You have successfully Email Id Verify.
            </div>
            <div className="align-center">
              <IonButton
                className="btnprimary"
                color="secondary"
                onClick={() => setShow(false)}
              >
                Done
              </IonButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ user }) => ({
  userVerified: user?.verified,
});

const mapDispatchToProps = {
  saveUserDetailsAction: saveUserDetails,
};

export default connect(mapStateToProps, mapDispatchToProps)(SuccessPayment);
