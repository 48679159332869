import { getAnalyticsUrl } from "../config/config";

const publicIp = require("public-ip");

const getIpDetails = async () => {
  try {
    let data = {};
    const ip = await publicIp?.v4();
    if (ip) {
      sessionStorage.setItem("IPAddress", JSON.stringify(ip));

      const response = await fetch(
        `${getAnalyticsUrl()}/${ip}`,
        {
          method: "GET",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
          },
          referrerPolicy: "no-referrer",
        }
      );
      const res = await response.json();
      //   response.json().then(res => {
      console.log(res.data.fullip.geo);
      const geo = res?.data?.fullip?.geo;
      data = {
        address: geo?.address,
        city: geo?.city,
        city_geoname_id: geo?.city_geoname_id,
        continent: geo?.continent,
        continent_geoname_id: geo?.continent_geoname_id,
        country: geo?.country,
        country_geoname_id: geo?.country_geoname_id,
        region: geo?.region,
        region_geoname_id: geo?.region_geoname_id,
        time_zone: geo?.time_zone,
        latitude: geo?.latitude,
        longitude: geo?.longitude,
        postal: geo?.postal,
      };
      sessionStorage.removeItem("IPDetails");
      sessionStorage.setItem("IPDetails", JSON.stringify(data));
    }
    return data;
  } catch (e) {}
  //   });

  // console.log(await publicIp.v6());
  // //=> 'fe80::200:f8ff:fe21:67cf'
};

export { getIpDetails };
