import React, { useState, useMemo } from "react";
import PropTypes from "prop-types";
import "./MovieCardNormal.scss";
import { Link } from "react-router-dom";
import { useAnalytics } from "use-analytics";
import { ROUTES } from "helpers/routeHelper";
import { TRACK_EVENT } from "analytics/analyticsHelper";
import DiamondOutline from "assets/img/diamond.svg";
import SkeletonLoader from "modules/common/components/SkeletonLoader/SkeletonLoader";
import { getPercentangeFromDuration } from "helpers/commonHelper";
import RadialProgress from "../../AuthenticationMobile/RadialProgress/RadialProgress";
import { SHOW_CARD_TITLE_DESKTOP } from "clientConfig";

const MovieCardNormal = ({
  movie,
  screen,
  query,
  searchLimit,
  disableLink,
}) => {
  const { track, page, identify } = useAnalytics();
  const trackEvent = () => {
    track(
      TRACK_EVENT.APP_USAGE.NAME,
      TRACK_EVENT.APP_USAGE.getClickProps(
        TRACK_EVENT.APP_USAGE.CLICK_OPTIONS.MOVIE_CARD,
        screen,
        {
          Card: "Normal",
          MovieId: movie?.id,
          Title: movie?.title,
          Type: movie?.multipart ? "Series" : "Movie",
          SearchQuery: query || "",
        }
      )
    );
  };
  const url = useMemo(() =>
    movie?.multipart
      ? ROUTES.SERIES_INFO.replace(":movieId", movie?.id)
      : ROUTES.MOVIE_INFO.replace(":movieId", movie?.id)
  );

  const percentage = useMemo(
    () =>
      getPercentangeFromDuration(
        movie?.state?.item?.duration,
        movie?.state?.time
      ),
    [movie]
  );

  return (
    <>
      <Link
        to={{
          pathname: disableLink ? "" : url,
          state: { movie: movie },
        }}
        routerDirection="forward"
        onClick={() => trackEvent()}
        className="cardlinking"
      >
        <div className="movie-normal">
          {movie?.paid && (
            <div className="flag">
              <img src={DiamondOutline} alt="diamond" />
            </div>
          )}

          <SkeletonLoader srcName={movie?.banners?.[0]} />

          <div className="text-movie-normal-cont">
            <div className="mr-grid">
              {disableLink && percentage && (
                // <div class="progress">
                //   <div
                //     class="progress-bar"
                //     style={{ width: percentage + "%" }}
                //   ></div>
                // </div>
                <RadialProgress percentage={percentage} />
              )}
              {SHOW_CARD_TITLE_DESKTOP && (<h5 className="movie-normal-title">
                {/* {searchLimit ? (movie?.title?.slice(0, 10)) : (movie?.title?.slice(0, 32))} */}
                {movie?.title?.slice(0, 32)}
              </h5>)}
            </div>
          </div>
        </div>
      </Link>
    </>
  );
};

MovieCardNormal.prototype = {
  searchLimit: PropTypes.bool,
};

export default MovieCardNormal;
