import React, { useState } from "react";
import { IonIcon } from "@ionic/react";
import "./popup.scss";
import { Modal } from "react-bootstrap";
import CastList from "../castlist/CastList";
import { informationCircleOutline } from "ionicons/icons";

const CastPopup = ({ artists }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

	return (
		<>
			<IonIcon className="icon" icon={informationCircleOutline} onClick={()=> handleShow()}/>
			<Modal show={show} onHide={handleClose} size="lg" centered className="castpopupwrap">
				<Modal.Header closeButton>
					<Modal.Title>Cast & Crew</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<CastList artists={artists} />
				</Modal.Body>
			</Modal>
		</>
	);
}

export default CastPopup;
