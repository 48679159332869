import React, { useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import "./PaymentHistory.scss";
import {
  IonThumbnail,
  IonSkeletonText,
  useIonViewWillEnter,
} from "@ionic/react";
import { getPaymentHistory } from "../../../../actions/services";
import { TRACK_EVENT } from "../../../../analytics/analyticsHelper";
import { useAnalytics } from "use-analytics";
import { PageWrapperComponent } from "modules/common";
import { PaymentHistoryCardComponent } from "modules/common";

const PaymentHistory = () => {
  const { page } = useAnalytics();
  const [pastPayments, setPastPayments] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    page(TRACK_EVENT.APP_USAGE.SCREEN.PAYMENT_HISTORY);
    getPaymentHistory()
      .then((res) => {
        if (res?.data?.success && res?.data?.data) {
          setPastPayments(res?.data?.data);
        }
      })
      .catch(() => {})
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const cards = isLoading
    ? [0, 1, 2, 3].map((s, i) => (
        <IonThumbnail
          key={i}
          className="skeleton-card-short"
          style={{ height: "150px", width: "100%", margin: "5px" }}
        >
          <IonSkeletonText animated />
        </IonThumbnail>
      ))
    : pastPayments?.map((paymentItem, i) => (
        <PaymentHistoryCardComponent key={i} paymentItem={paymentItem} />
      ));
  return (
    <PageWrapperComponent>
      <div className="paymentsContainer">
        <div className="payments">
          <h3>
            <FormattedMessage id="payments.title" />
          </h3>
          <div className="paymentsList">{cards}</div>
        </div>
      </div>
    </PageWrapperComponent>
  );
};

export default PaymentHistory;
