import React from "react";
import "./FilterTabs.scss";
import { Link } from "react-router-dom";
import { ROUTES } from "helpers/routeHelper";

const FilterTabs = ({ filterList, filter }) => {
  return (
    <>
      <div className="filter-btn-wrap">
        <ul className="filter-tabs">
          {filterList &&
            filterList.map((f, index) => (
              <li className={`${f.value === filter ? "active" : ""}`} key={f.value}>
                <Link
                  routerDirection="forward"
                  to={`${ROUTES.MOVIES_LIST.replace(
                    ":filter",
                    f.value
                  )}?filter=${f.value}`}
                >
                  {f?.label}
                </Link>
              </li>
            ))}
        </ul>
      </div>
    </>
  );
};
export default FilterTabs;
