import React, { useState, useMemo } from "react";
import PropTypes from "prop-types";
import "./MovieCardShort.scss";
import { Link } from "react-router-dom";
import { useAnalytics } from "use-analytics";
import { ROUTES } from "helpers/routeHelper";
import { TRACK_EVENT } from "analytics/analyticsHelper";
import DiamondOutline from "assets/img/diamond.svg";
import SkeletonLoader from "modules/common/components/SkeletonLoader/SkeletonLoader";
import { getPercentangeFromDuration } from "helpers/commonHelper";
import RadialProgress from "../../AuthenticationMobile/RadialProgress/RadialProgress";
import { SHOW_CARD_TITLE } from "clientConfig";

const propTypes = {
  movie: PropTypes.shape({
    id: PropTypes.number.isRequired,
    multipart: PropTypes.bool.isRequired,
    // rating: PropTypes.string,
    title: PropTypes.string.isRequired,
    genre: PropTypes.array,
    duration: PropTypes.string.isRequired
    // likes: PropTypes.number,
    // actors: PropTypes.array,
    // description: PropTypes.string.isRequired
  }),
  screen: PropTypes.string.isRequired,
  query: PropTypes.string,
   banners: PropTypes.bool
};

const MovieCardShort = ({ movie, screen, query, banners, disableLink }) => {
  const { track, page, identify } = useAnalytics();
  const trackEvent = () => {
    track(
      TRACK_EVENT.APP_USAGE.NAME,
      TRACK_EVENT.APP_USAGE.getClickProps(
        TRACK_EVENT.APP_USAGE.CLICK_OPTIONS.MOVIE_CARD,
        screen,
        {
          Card: "Short",
          MovieId: movie.id,
          Title: movie?.title,
          Type: movie?.multipart ? 'Series' : 'Movie',
          SearchQuery: query || '',
        }
      )
    );
  };

  const url = useMemo(() =>
    movie?.multipart
      ? ROUTES.SERIES_INFO.replace(":movieId", movie?.id)
      : ROUTES.MOVIE_INFO.replace(":movieId", movie?.id)
  );
  const percentage = useMemo(
    () =>
      getPercentangeFromDuration(
        movie?.state?.item?.duration,
        movie?.state?.time
      ),
    [movie]
  );
  return (
    <>
      <Link
        routerDirection="forward"
        to={{
          pathname: disableLink ? '' : url,
          state: { movie: movie },
        }}
        onClick={() => trackEvent()}
        className="cardlinking"
      >
        <div className="movie-short">
          {/* <div
            className="movie-short-img"
            style={{ backgroundImage: `url(${movie?.thumbnails?.[0]})` }}
          /> */}
          {movie?.paid && (
            <div className="flag">
              <img src={DiamondOutline} alt="diamond" width='100%' height='100%'/>
            </div>
          )}
          {banners ? <SkeletonLoader srcName={movie?.thumbnails?.[0]} /> : <SkeletonLoader srcName={movie?.banners?.[0]} />}
          {/* <SkeletonLoader srcName={movie?.banners?.[0]} /> */}
          {disableLink && percentage && (
            
                // <div class="progress">
                //   <div
                //     class="progress-bar"
                //     style={{ width: percentage + "%" }}
                //   ></div>
                // </div>
            <RadialProgress percentage={percentage} />
            
              )}
          {SHOW_CARD_TITLE && (<h5>{movie?.title?.slice(0, 30)}</h5>)}
          {/* <div className="text-movie-short-cont">
            <div className="mr-grid">
              <div className="col1">
                <h5>{movie?.title?.slice(0, 35)}</h5>
                <ul className="movie-short-gen">
                  <li>{movie?.duration}</li>
                </ul>
              </div>
            </div>
          </div> */}
        </div>
      </Link>
    </>
  );
};

export default MovieCardShort;
