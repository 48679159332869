import React, { useState, useEffect, useRef } from "react";
import "./MovieInfo.scss";
import { useAnalytics } from "use-analytics";
import { IonLoading, IonPage, isPlatform } from "@ionic/react";
import { getMovieInfo } from "actions/services";
import { TRACK_EVENT } from "analytics/analyticsHelper";
import { connect } from "react-redux";
import { saveFromApp } from "store/slices/paymentSlice";
import { useParams } from "react-router-dom";
import { setPlayerUrl } from "store/slices/paymentSlice";
import { isMobileSafari, isSafari } from "react-device-detect";
import { getFeaturedMovie } from "store/slices/persistSlice/persistSlice";
import { setPageTitle } from "helpers/seoHelper";
import { MovieCarouselComponent } from "modules/common";
import { LongSingleCardComponent } from "modules/common";
import { PageWrapperComponent } from "modules/common";

const MovieInfo = ({
  saveFromAppAction,
  setPlayerUrlAction,
  getFeaturedMovieAction,
  featuredMovieList,
}) => {
  const params = useParams();
  const [movie, setMovie] = useState(null);
  const [movieId, setMovieId] = useState(null);
  const [showVideo, setShowVideo] = useState(false);
  const [featuredMovies, setFeaturedMovies] = useState([]);
  const iosWeb = isPlatform("ios") && isPlatform("mobileweb");
  const [showLoading, setShowLoading] = useState(false);
  const contentEl = useRef(null);

  const { track, page } = useAnalytics();
  useEffect(() => {
    setShowVideo(!(isMobileSafari || isSafari || iosWeb));
    page(TRACK_EVENT.APP_USAGE.SCREEN.MOVIE_INFO);
    getFeaturedMovieAction();
  }, [params]);

  useEffect(() => {
    if (featuredMovieList) {
      setFeaturedMovies(featuredMovieList);
    }
  }, [featuredMovieList]);

  const fetch = () => {
    const id = params?.movieId;
    if (id && id !== movieId) {
      contentEl?.current?.scrollToTop();
      setMovieId(id);
      setShowLoading(true);
      getMovieInfo(id)
        .then((res) => {
          if (res?.data?.success && res?.data?.data) {
            setMovie(res?.data?.data);
          }
          setPageTitle("MOVIE_INFO", res?.data?.data?.collection?.title);
        })
        .catch(() => {
          setShowLoading(false);
        })
        .finally(() => setShowLoading(false));
    }
    saveFromAppAction(true);
    setPlayerUrlAction({}, null);
  };

  useEffect(() => {
    fetch();
  }, [params]);

  return (
    <PageWrapperComponent contentEl={contentEl}>
      <IonLoading
        isOpen={showLoading}
        onDidDismiss={() => setShowLoading(false)}
        message={"Please wait..."}
      />
      {movie && (
        <div>
          <div>
            <LongSingleCardComponent
              key={`long-single-card-web-${movie?.collection?.id}`}
              movie={movie?.item}
              collection={movie?.collection}
              artists={movie?.artists}
              screen={"MovieInfoWeb"}
              showVideo={showVideo}
              addSeo
            />
          </div>
          <div>
            {featuredMovies?.map((data, i) =>
              data?.values?.length ? (
                <MovieCarouselComponent
                  key={`featured-${i}`}
                  moviesList={data?.values}
                  name={"You might also like"}
                  screen={TRACK_EVENT.APP_USAGE.SCREEN.MOVIE_INFO}
                  isBanner={false}
                  loop={true}
                  showVideo={false}
                />
              ) : (
                <></>
              )
            )}
          </div>
        </div>
      )}
    </PageWrapperComponent>
  );
};

const mapStateToProps = ({ payment, persist }) => {
  return {
    playerUrl: payment?.playerUrl,
    featuredMovieList: persist?.featuredMovies?.data,
  };
};
const mapDispatchToProps = {
  saveFromAppAction: saveFromApp,
  setPlayerUrlAction: setPlayerUrl,
  getFeaturedMovieAction: getFeaturedMovie,
};

export default connect(mapStateToProps, mapDispatchToProps)(MovieInfo);

