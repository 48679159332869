import { ROUTES, ROUTES_ID } from "../../helpers/routeHelper";
import {
  AuthNative,
  HomeNative,
  MovieListNative,
  SettingsNative,
  NotFoundNative,
  SearchResultNative,
  ProfileNative,
  WatchNative,
  MovieInfoMobileNative,
  MovieInfoWebNative,
  VideoNative,
  VideoPlayerIosNative,
  ExploreNative,
  SeriesListNative,
  SeriesInfoNative,
  WatchHistoryNative,
  PaymentHistoryNative,
  CoinsNative,
  CouponNative,
  ShopNative,
} from ".";
import { ComponentWrapperComponent } from "modules/common";
import { Redirect, Route } from "react-router";
import { isPlatform } from "@ionic/react";
import { isMobileSafari } from 'react-device-detect';

const getPlayer = () => {
  // IOS NATIVE/MOBILE WEB
  if (isPlatform("ios") || isMobileSafari) {
    return VideoPlayerIosNative;
  }
  return VideoNative;
};

export const getNativeRoutes = (isMobile, propsToPassed) => {
  return [
    <ComponentWrapperComponent
      key="home"
      routeId={ROUTES_ID.HOME}
      path={ROUTES.HOME}
      component={HomeNative}
      {...propsToPassed}
    />,
    <ComponentWrapperComponent
      key="movie_list"
      routeId={ROUTES_ID.MOVIES_LIST}
      path={ROUTES.MOVIES_LIST}
      component={MovieListNative}
      {...propsToPassed}
    />,
    <ComponentWrapperComponent
      key="movie_list"
      routeId={ROUTES_ID.MOVIES_LIST}
      path={ROUTES.MOVIES}
      component={MovieListNative}
      {...propsToPassed}
    />,
    <ComponentWrapperComponent
      key="series_list"
      routeId={ROUTES_ID.SERIES_LIST}
      path={ROUTES.SERIES_LIST}
      component={SeriesListNative}
      {...propsToPassed}
    />,
    <ComponentWrapperComponent
      key="auth"
      routeId={ROUTES_ID.AUTH}
      path={ROUTES.AUTH}
      component={AuthNative}
      {...propsToPassed}
    />,
    <ComponentWrapperComponent
      key="user_profile"
      routeId={ROUTES_ID.USER_PROFILE}
      path={ROUTES.USER_PROFILE}
      component={ProfileNative}
      authRoute={true}
      {...propsToPassed}
    />,
    <ComponentWrapperComponent
      key="search"
      routeId={ROUTES_ID.SEARCH}
      path={ROUTES.SEARCH}
      component={SearchResultNative}
      {...propsToPassed}
    />,
    <ComponentWrapperComponent
      key="settings"
      routeId={ROUTES_ID.SETTINGS}
      path={ROUTES.SETTINGS}
      component={SettingsNative}
      {...propsToPassed}
    />,
    <ComponentWrapperComponent
      key="payment_history"
      routeId={ROUTES_ID.PAYMENT_HISTORY}
      path={ROUTES.PAYMENT_HISTORY}
      component={PaymentHistoryNative}
      authRoute={true}
      {...propsToPassed}
    />,
    <ComponentWrapperComponent
      key="watch_history"
      routeId={ROUTES_ID.WATCH_HISTORY}
      path={ROUTES.WATCH_HISTORY}
      component={WatchHistoryNative}
      authRoute={true}
      {...propsToPassed}
    />,
    <Route
      exact
      key="home"
      path="/"
      render={() => <Redirect to={ROUTES.HOME} />}
    />,
    <ComponentWrapperComponent
      key="movie_info"
      routeId={ROUTES_ID.MOVIE_INFO}
      path={ROUTES.MOVIE_INFO}
      component={isMobile ? MovieInfoMobileNative : MovieInfoWebNative}
      {...propsToPassed}
    />,
    <ComponentWrapperComponent
      key="series_info"
      routeId={ROUTES_ID.SERIES_INFO}
      path={ROUTES.SERIES_INFO}
      component={SeriesInfoNative}
      {...propsToPassed}
    />,
    <ComponentWrapperComponent
      key="watch"
      routeId={ROUTES_ID.WATCH}
      path={ROUTES.WATCH}
      component={WatchNative}
      // authRoute={true}
      {...propsToPassed}
    />,
    <ComponentWrapperComponent
      key="_watch"
      routeId={ROUTES_ID.WATCH_SUBSCRIBE}
      path={ROUTES.WATCH_SUBSCRIBE}
      component={WatchNative}
      // authRoute={true}
      {...propsToPassed}
    />,
    <ComponentWrapperComponent
      key="player"
      routeId={ROUTES_ID.PLAYER}
      path={ROUTES.PLAYER}
      component={getPlayer()}
    // authRoute={true}
    />,
    <ComponentWrapperComponent
      key="coins"
      routeId={ROUTES_ID.COINS}
      path={ROUTES.COINS}
      component={CoinsNative}
      authRoute={true}
      {...propsToPassed}
    />,
    <ComponentWrapperComponent
      key="explore"
      routeId={ROUTES_ID.EXPLORE}
      path={ROUTES.EXPLORE}
      component={ExploreNative}
      // authRoute={true}
      {...propsToPassed}
    />,
    <ComponentWrapperComponent
      key="coupons"
      routeId={ROUTES_ID.COUPON}
      path={ROUTES.COUPON}
      component={CouponNative}
      {...propsToPassed}
    />,
    <ComponentWrapperComponent
      key="shop"
      routeId={ROUTES_ID.SHOP}
      path={ROUTES.SHOP}
      component={ShopNative}
      {...propsToPassed}
    />,
    <ComponentWrapperComponent key="not_found" component={NotFoundNative} />,
  ];
};
