import React, { useState, useEffect, useMemo } from "react";
import "./SeriesList.scss";
import { useIonViewWillEnter, useIonViewDidEnter } from "@ionic/react";
import { useAnalytics } from "use-analytics";
import { MovieCarouselComponent } from "modules/common";
import { getSeriesList, getMovieFeatured } from "../../../../actions/services";
import { seriesSkeleton } from "../../../../constants/skeletonConstant";
import { TRACK_EVENT } from "../../../../analytics/analyticsHelper";
import { PageWrapperComponent } from "modules/common";
import { getSeriesListData, getFeaturedMovie } from "store/slices/persistSlice/persistSlice";
import { connect } from "react-redux";
import { BUSINESS_CONFIG } from "clientConfig";
import { PageTitleCd } from "helpers/seoHelper";
import Helmet from "react-helmet";

const SeriesList = ({
  featuredMovieList,
  series,
  getFeaturedMovieAction,
  getSeriesListDataAction
}) => {
  const { track, page, identify } = useAnalytics();
  const [seriesList, setSeriesList] = useState(seriesSkeleton.list);
  const [featuredMovies, setFeaturedMovies] = useState(seriesSkeleton.featured);
  const [showSkeleton, setShowSkeleton] = useState({
    short: true,
    long: true,
    normal: true,
  });
  useIonViewWillEnter(() => {
    page(TRACK_EVENT.APP_USAGE.SCREEN.MOVIE_LIST);
  });

  useIonViewDidEnter(() => {
    page(TRACK_EVENT.APP_USAGE.SCREEN.SERIES_List);
    getSeriesListDataAction();
    getFeaturedMovieAction();
  });

  useEffect(() => {
    if (featuredMovieList) {
      setFeaturedMovies(featuredMovieList);
      setShowSkeleton((showSkeleton) => ({
        ...showSkeleton,
        long: false,
      }));
    }
  }, [featuredMovieList]);

  useEffect(() => {
    if (series) {
      setSeriesList(series);
      setShowSkeleton((showSkeleton) => ({
        ...showSkeleton,
        short: false,
        normal: false,
      }));
    }
  }, [series]);

  const keywords = useMemo(() => {
    const titles = seriesList
      ?.map((x) => x?.values)
      ?.reduce((acc, val) => acc.concat(val), [])
      .map((x) => x?.title)
      ?.join(", ");
    return titles;
  }, [seriesList]);

  return (
    <>
    {
        <Helmet>
          <meta
            content={`${BUSINESS_CONFIG.Title}${PageTitleCd?.["SERIES_LIST"] ||
              PageTitleCd.HOME} || ${keywords}`}
            name="description"
          />
          {/* <meta content={keywords} name="keywords" /> */}
        </Helmet>
      }
      <PageWrapperComponent>
        {featuredMovies?.map((data, i) =>
          data?.values?.length ? (
            <MovieCarouselComponent
              key={i}
              moviesList={data?.values}
              name={data?.name}
              screen={"SeriesListing"}
              isBanner
              showSkeleton={showSkeleton}
              carkey={i + showSkeleton?.long}
              showVideo={false}
            />
          ) : null
        )}
        {seriesList?.map((data, i) =>
          data?.values?.length ? (
            <MovieCarouselComponent
              key={`series-${i}`}
              isBanner={false}
              moviesList={data?.values}
              name={data?.name}
              screen={"SeriesListing"}
              showSkeleton={showSkeleton}
              carkey={i + `${showSkeleton?.short || showSkeleton?.normal}`}
              showVideo={false}
            />
          ) : null
        )}
      </PageWrapperComponent>
    </>
  );
};

const mapStateToProps = ({ persist }) => ({
  featuredMovieList: persist?.featuredMovies?.data,
  series: persist?.seriesList?.data,
});
const mapDispatchToProps = {
  getSeriesListDataAction: getSeriesListData,
  getFeaturedMovieAction: getFeaturedMovie,
};
export default connect(mapStateToProps, mapDispatchToProps)(SeriesList);
