import React, { useEffect, useMemo, useState } from "react";
import "./EpisodeListWeb.scss";
import Slider from "react-slick";
import SkeletonLoader from "modules/common/components/SkeletonLoader/SkeletonLoader";
import PlayButton from "../Play/PlayButton";

function EpisodeListWeb({ series, onWatchClick, selectedSeason }) {
  const [count, setCount] = useState(0);


  useEffect(() => {
    setCount(series?.length || 0);
  }, [series]);
  
  const settings = useMemo(() => {
    return {
      // lazyLoad: true,
      dots: false,
      infinite: count > 6,
      speed: 500,
      swipeToSlide: true,
      slidesToShow: 5,
      slidesToScroll: 1,
      focusOnSelect: true,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
      responsive: [
        {
          breakpoint: 1350,
          settings: {
            slidesToShow: 5,
          },
        },
        {
          breakpoint: 1280,
          settings: {
            slidesToShow: 4,
          },
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
          },
        },
        {
          breakpoint: 900,
          settings: {
            slidesToShow: 2.4,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2.2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1.5,
          },
        },
      ],
    };
  }, [count]);

  return (
    <div className="seriesList">
      <div>
      <Slider {...settings} className="series">
        {series?.seasons[selectedSeason]?.items?.map((item, i) => (
          <PlayButton movieId={item?.id} collectionId={item?.collection_id} collectionItemCombo={item} >
          <div className="episodeCard" key={`${selectedSeason}-${i}`}>
            <div className="episodeImg">
              <img  src={item?.thumbnails?.[0]} alt="Episode Thumbnail"/>
            </div>

            <div className="episodeInfo">
              <h2 className="title-font">{item?.prefix || item?.title}</h2>
              <h2 className="description-font">{item?.description}</h2>
            </div>
          </div>
          </PlayButton>
        ))}
        </Slider>
      </div>
    </div>
  );
}

export default EpisodeListWeb;

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    />
  );
}