import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";
import { ROUTES } from "helpers/routeHelper";
import PlayButton from "../Play/PlayButton";

const propTypes = {
  collection: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    date: PropTypes.string,
    duration: PropTypes.string,
    rating: PropTypes.string,
    genre: PropTypes.array,
    times: PropTypes.number,
    banners: PropTypes.array,
  }),
};

const HistoryItem = ({ collection }) => {
  const history = useHistory();

  const url = useMemo(() => {
    return collection?.multipart
      ? ROUTES.SERIES_INFO.replace(":movieId", collection?.id)
      : ROUTES.MOVIE_INFO.replace(":movieId", collection?.id);
  });

  return (
    <div className="watchHistoryListItem">
      <ion-card>
        <div className="card-inside-wrap">
          <div className="img-wrapper">
            <img className="historyimg" src={collection?.banners[0]} />
          </div>
          <div className="card-content">
            <ion-card-header>
              <ion-card-subtitle>{collection?.title}</ion-card-subtitle>
            </ion-card-header>
            <ion-card-content>
              <div className="moviedetail">
                {collection?.duration} &nbsp; {collection?.rating} &nbsp;{" "}
                {collection?.genre?.[0]}
              </div>
              {/* <div className="lastseen">
                <FormattedMessage id="history.lastSeen" />: {collection?.date}
              </div>
              <div className="seencount">
                <FormattedMessage id="history.timesSeen" />: {collection?.times}
              </div> */}
              <div className="watchHistoryListItemBtnGroup">
                <button
                  onClick={() => {
                    history.push(url);
                  }}
                >
                  <FormattedMessage id="history.watchAgain" />
                </button>
                <PlayButton
                  movieId={collection?.state?.item?.id}
                  collectionId={collection?.id}
                  collectionItemCombo={{
                    collection: { ...collection, state: null },
                    item: collection?.state?.item,
                  }}
                  playerState={collection?.state}
                >
                  <button>Continue Watching</button>
                </PlayButton>
              </div>
            </ion-card-content>
          </div>
        </div>
      </ion-card>
    </div>
  );
};

HistoryItem.propTypes = propTypes;

export default HistoryItem;
