import { APP_THEME } from "clientConfig";
import React from "react";
import { Modal } from 'react-bootstrap';
import PlansPayment from "./PlansPayment";

const PlansPaymentPopup = ({ setShow, show, isVerificationOnly }) => {

  return (
    <>
      <Modal show={show} onHide={() => setShow(false)} size="md" centered className={`planpayment  ${APP_THEME}`}>
        {/* <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header> */}
        <Modal.Body>
          <PlansPayment setShow={setShow} isVerificationOnly={isVerificationOnly}/>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default PlansPaymentPopup;