import React, { useState, useEffect, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import {
  IonSkeletonText,
  IonThumbnail,
  useIonViewWillEnter,
} from "@ionic/react";
import "./WatchHistory.scss";
import { getHistory } from "../../../../actions/services";
import { TRACK_EVENT } from "../../../../analytics/analyticsHelper";
import { useAnalytics } from "use-analytics";
import { setPageTitle } from "../../../../helpers/seoHelper";
import { ComponentWrapperComponent } from "modules/common";
import { HistoryItemComponent } from "modules/common";
import { PageWrapperComponent } from "modules/common";

const WatchHistory = ({}) => {
  const { track, page, identify } = useAnalytics();
  const [watchedItems, setWatchedItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useIonViewWillEnter(() => {
    setPageTitle('WATCH_HISTORY');
    page(TRACK_EVENT.APP_USAGE.SCREEN.WATCH_HISTORY);
    getHistory()
      .then((res) => {
        if (res?.data?.success && res?.data?.data) {
          setWatchedItems(res?.data?.data);
        }
      })
      .catch((err) => {})
      .finally(() => {
        setIsLoading(false);
      });
  })

  const cards = isLoading
    ? [0, 1, 2, 3].map((s, i) => (
        <IonThumbnail
          key={i}
          className="skeleton-card-short"
          style={{ height: "150px", width: "100%", margin: "5px" }}
        >
          <IonSkeletonText animated />
        </IonThumbnail>
      ))
    : watchedItems?.length
    ? watchedItems?.map((item, i) => <HistoryItemComponent key={i} collection={item} />)
    : "There is no watch history available.";

  return (
    <PageWrapperComponent>
        {/* <div className="watchHistoryContainer"> */}
          {/* <div className="watchHistory"> */}
            {/* <h3>
              <FormattedMessage id="history.title" />
            </h3> */}
            <div className="watchHistoryList">{cards}</div>
          {/* </div> */}
        {/* </div> */}
        </PageWrapperComponent>
  );
};

export default WatchHistory;
