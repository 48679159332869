import { apiV1 } from "./config";
const autoSignIn = () => {
  const response = apiV1().get(`/users/auth`);
  return response;
};

const getAllPlans = (data) => {
  const response = apiV1().post(`/plans/`, data);
  return response;
};

const verifyGooglePlayPurchase = (data) => {
  const response = apiV1().post(`/verify_google/`, data);
  return response;
};

const cancelSubscription = (data) => {
  const response = apiV1().post(`/unsubscribe/`, data);
  return response;
};

const getUserSubscriptionDetails = () => {
  const response = apiV1().get(`/subscription_details`);
  return response;
};

const signUpUser = (credentials) => {
  const response = apiV1(false).post(`/users/signup/`, credentials);
  return response;
};

const signInUser = (credentials) => {
  const response = apiV1(false).post(`/users/signin/`, credentials);
  console.log(response);
  return response;
};

const signOutUser = (credentials) => {
  const response = apiV1().post(`/users/signout/`, credentials);
  return response;
};

const resetPassword = (credentials) => {
  const response = apiV1(false).post(`/passwd/reset/`, credentials);
  console.log(response);
  return response;
};

const resetPasswordVerify = (credentials) => {
  const response = apiV1(false).post(`/passwd/reset/cnfrm/`, credentials);
  console.log(response);
  return response;
};

const resendOtp = () => {
  const response = apiV1().get(`/resend_otp`);
  return response;
};
const verifyOtp = (otp) => {
  const response = apiV1().post(`/verify_user/`, { otp });
  return response;
};

const applyCoupon = (couponCode) => {
  const response = apiV1().post(`/add_coupon/`, { couponCode });
  return response;
};

const generateCoupon = () => {
  const response = apiV1().get(`/generate_coupon`);
  return response;
};

const getPaymentOrder = (credentials) => {
  let response = null;
  if (credentials?.isSubscription) {
    response = apiV1().post(`/subscribe/`, { plan_id: credentials.tier });
  } else {
    response = apiV1().post(`/order/`, { id: credentials.id });
  }
  return response;
};

const verifyPaymentOrder = (credentials) => {
  let response = null;
  if (credentials?.razorpay_order_id) {
    delete credentials.razorpay_subscription_id;
    response = apiV1().post(`/verify_order/`, credentials);
  }
  if (credentials?.razorpay_subscription_id) {
    delete credentials.razorpay_order_id;
    response = apiV1().post(`/verify_subscription/`, credentials);
  }
  return response;
};

const getPaymentStatus = () => {
  return { data: true };
};

export {
  signInUser,
  signUpUser,
  getAllPlans,
  autoSignIn,
  getPaymentStatus,
  resetPassword,
  getPaymentOrder,
  verifyPaymentOrder,
  resetPasswordVerify,
  verifyGooglePlayPurchase,
  cancelSubscription,
  getUserSubscriptionDetails,
  resendOtp,
  verifyOtp,
  applyCoupon,
  generateCoupon
};
