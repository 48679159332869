import React, { useState, useEffect, useMemo } from "react";
import "./Auth.scss";
import { IonButton, IonIcon, isPlatform } from "@ionic/react";
import { logoGoogle, logoFacebook } from "ionicons/icons";
import countryCodes from "country-codes-list";
import Select from "react-select";
import { Link } from "react-router-dom";
import {
  BUSINESS_CONFIG,
  LOGO,
  SHOW_SOCIAL_AUTH,
} from "../../../../clientConfig";
import { ROUTES } from "helpers/routeHelper";
import { Capacitor } from "@capacitor/core";

const AuthenticationPageWeb = ({
  signIn,
  handleChange,
  user,
  forgetPassword,
  resetForm,
  signUp,
  error,
  resetError,
  errorMessage,
  successMessage,
  isForgetPasswordClicked,
  setMessage,
  setUser,
  setIsForgetPasswordClicked,
}) => {
  const [authToggle, setAuthToggle] = useState(false);
  const [country, setCountry] = useState(null);
  const countryOptions = useMemo(
    () =>
      countryCodes.all().map((x) => ({
        value: x.countryCallingCode,
        label: `${x.countryNameEn}`,
      })),
    []
  );

  return (
    <div className="authPage">
      <div className="signInHeader">
        {/* <Link to={ROUTES.HOME} routerDirection="back">
          <img src={LOGO} alt="" />
        </Link> */}
        <div className="auth-top">
          <div className="auth-top-heading">
            {authToggle
              ? "Create an Account"
              : !isForgetPasswordClicked
                ? "Welcome"
                : "Forgot Password"}
          </div>
          <div className="auth-top-desc">
            {authToggle
              ? "Please sign up to your account to continue with App."
              : !isForgetPasswordClicked
                ? "Please sign in to your account to continue with App."
                : "Passcode sent to your registered email address"}
          </div>
        </div>
        {/* <div className="authBtnGroup">
          <IonButton
            size="small"
            color={authToggle ? "light" : "primary"}
            className={authToggle ? "authBtn" : "authBtn-active"}
            onClick={() => {
              setAuthToggle(false);
              setMessage("");
              resetForm(true);
              resetError();
              setIsForgetPasswordClicked(false);
            }}
          >
            Sign In
          </IonButton>

          <IonButton
            size="small"
            color={authToggle ? "primary" : "light"}
            className={authToggle ? "authBtn-active" : "authBtn"}
            onClick={() => {
              setAuthToggle(true);
              setMessage("");
              resetForm(false);
              resetError();
              setIsForgetPasswordClicked(false);
            }}
          >
            Sign Up
          </IonButton>
        </div> */}
      </div>
      <div className="authForm">
        {authToggle ? (
          <>
            <div className="input-group">
              <label className="input-label">Name</label>
              <input
                className="input-control"
                type="text"
                placeholder="Display Name"
                name="fullName"
                value={user.fullName}
                onChange={handleChange}
              />
              <span className="error text-danger">{error?.fullName}</span>
            </div>
            <div className="input-group">
              <label className="input-label">Email Address</label>
              <input
                className="input-control"
                type="text"
                placeholder="Email"
                name="email"
                value={user.email}
                onChange={handleChange}
              />
              <span className="error text-danger">{error?.email}</span>
            </div>
            {/* <div className="input-group">
              <div className="numbercode-wrap">
                <Select
                  isSearchable={true}
                  className="countrycode"
                  placeholder="Country Code"
                  value={country}
                  onChange={(e) => {
                    setUser({
                      ...user,
                      countryCode: e?.value,
                    });
                    setCountry(e);
                  }}
                  name="countryCode"
                  options={countryOptions}
                />
                <input
                  className="input-control"
                  type="text"
                  placeholder="Phone number"
                  name="phone"
                  value={user.phone}
                  onChange={handleChange}
                />
                <span className="error text-danger">{error?.countryCode}</span>
                <span className="error text-danger">{error?.phone}</span>
              </div>
            </div> */}
            <div className="input-group">
              <label className="input-label">Password</label>
              <input
                className="input-control"
                type="password"
                placeholder="Password"
                data-type="password"
                name="password"
                value={user.password}
                onChange={handleChange}
              />
              <span className="error text-danger">{error?.password}</span>
            </div>
            <div className="input-group">
              <label className="input-label">Confirm Password</label>
              <input
                className="input-control"
                type="password"
                placeholder="Confirm Password"
                data-type="password"
                name="repeatPassword"
                value={user.repeatPassword}
                onChange={handleChange}
              />
              <span className="error text-danger">{error?.repeatPassword}</span>
            </div>
            {/* { !(Capacitor.isNativePlatform() && isPlatform("ios")) &&
            <div className="input-group">
              <input
                className="input-control"
                type="text"
                placeholder="Coupon Code"
                data-type="couponCode"
                name="couponCode"
                value={user.couponCode}
                onChange={handleChange}
              />
            </div>
            } */}
            <div className="input-group">
              <label className="privacypolicy">
                <input
                  type="checkbox"
                  name="acceptPrivacyPolicy"
                  value={user.acceptPrivacyPolicy}
                  onChange={handleChange}
                />
                <a target="_blank" href={BUSINESS_CONFIG.PrivacyPolicy}>
                  I have read the privacy policy & terms of use and agree to the
                  same
                </a>
              </label>
              <span className="error text-danger">
                {error?.acceptPrivacyPolicy}
              </span>
            </div>
          </>
        ) : (
          <>
            <div className="input-group">
              <label className="input-label">Email Address</label>
              <input
                className="input-control"
                type="text"
                placeholder="Email/Phone Number(with country code)"
                id="user"
                name="email"
                readOnly={isForgetPasswordClicked}
                value={user.email}
                onChange={handleChange}
              />
              <span className="error text-danger">{error?.email}</span>
            </div>
            <div className="input-group">
              <label className="input-label">Password</label>
              <input
                className="input-control"
                type="password"
                placeholder="Password"
                id="pass"
                data-type="password"
                name="password"
                value={user.password}
                onChange={handleChange}
              />
              <span className="error text-danger">{error?.password}</span>
            </div>

            {isForgetPasswordClicked && (
              <>
                <div className="input-group">
                  <label className="input-label">Confirm Password</label>
                  <input
                    className="input-control"
                    type="password"
                    placeholder="Confirm Password"
                    data-type="password"
                    name="repeatPassword"
                    value={user.repeatPassword}
                    onChange={handleChange}
                  />
                  <span className="error text-danger">
                    {error?.repeatPassword}
                  </span>
                </div>
                <div className="input-group">
                  <label className="input-label">Passcode</label>
                  <input
                    className="input-control"
                    type="text"
                    placeholder="Passcode"
                    id="passcode"
                    autoComplete="otp"
                    name="passcode"
                    value={user.passcode}
                    onChange={handleChange}
                  />
                  <span className="error text-danger">{error?.passcode}</span>
                </div>
              </>
            )}
          </>
        )}

        {errorMessage && (
          <span className="error text-danger">{errorMessage}</span>
        )}
        {successMessage && (
          <span className="text text-success">{successMessage}</span>
        )}
        {authToggle ? (
          // <span className="forgotPassword">
          //   Already a member?
          // </span>
          <></>
        ) : (
          <>
            {!isForgetPasswordClicked && (
              <span
                onClick={() => {
                  forgetPassword();
                }}
                className="forgotPassword"
              >
                forgot your password?
              </span>
            )}
          </>
        )}
        {authToggle ? (
          // <button onClick={signUp}>Sign Up</button>
          <>
            <IonButton onClick={signUp} className="signbtn" color="primary">
              Sign Up
            </IonButton>
            <span className="auth-toggler">
              Already Have an account &nbsp;
              <span
                className=""
                onClick={() => {
                  setAuthToggle(false);
                  setMessage("");
                  resetForm(true);
                  resetError();
                  setIsForgetPasswordClicked(false);
                }}
              >
                Sign In
              </span>
            </span>
          </>
        ) : isForgetPasswordClicked ? (
          <IonButton
            onClick={() => forgetPassword(true)}
            className="signbtn"
            color="secondary"
          >
            Submit
          </IonButton>
        ) : (
          <>
            <IonButton onClick={signIn} className="signbtn" color="primary">
              Sign In
            </IonButton>
            <span className="auth-toggler">
              Don't Have an account? &nbsp;
              <span
                className=""
                onClick={() => {
                  setAuthToggle(true);
                  setMessage("");
                  resetForm(true);
                  resetError();
                  setIsForgetPasswordClicked(false);
                }}
              >
                Sign Up
              </span>
            </span>
          </>
        )}
        {SHOW_SOCIAL_AUTH && (
          <div className="signWithSocial">
            <IonButton className="socialbtn" color="white">
              Signin with <IonIcon className="socialicon" icon={logoGoogle} />
              oogle{" "}
            </IonButton>
            <IonButton className="socialbtn" color="white">
              Signin with <IonIcon className="socialicon" icon={logoFacebook} />
              facebook{" "}
            </IonButton>
          </div>
        )}
      </div>
    </div>
  );
};

export default AuthenticationPageWeb;
