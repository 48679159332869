import React, { useState, useEffect, useRef } from "react";
import "./MovieInfo.scss";
import { useAnalytics } from "use-analytics";
import {
  IonPage,
  IonContent,
  useIonViewWillLeave,
  useIonViewWillEnter,
  isPlatform,
  useIonViewDidEnter,
} from "@ionic/react";
import { getMovieInfo, getVideoUrl } from "../../../../actions/services";
import { TRACK_EVENT } from "../../../../analytics/analyticsHelper";
import { connect } from "react-redux";
import { saveFromApp } from "../../../../store/slices/paymentSlice";
import { useHistory, useParams } from "react-router-dom";
import { ROUTES } from "helpers/routeHelper";
import { setPlayerUrl } from "../../../../store/slices/paymentSlice";
import {browserName, isMobileSafari, isSafari} from 'react-device-detect';
import { getFeaturedMovie } from "../../../../store/slices/persistSlice/persistSlice";
import { setPageTitle } from "../../../../helpers/seoHelper";
import { MovieCarouselComponent } from "modules/common";
import { LongSingleCardComponent } from "modules/common";
import { PageWrapperComponent } from "modules/common";
import { v4 as uuidv4 } from 'uuid';

const MovieInfo = ({ saveFromAppAction, setPlayerUrlAction,
  getFeaturedMovieAction,
  featuredMovieList, }) => {
  const history = useHistory();
  const params = useParams();
  const [movie, setMovie] = useState(null);
  const [movieId, setMovieId] = useState(null);
  const [showLoading, setShowLoading] = useState(false);
  const [showVideo, setShowVideo] = useState(false);
  const [featuredMovies, setFeaturedMovies] = useState([]);
  const iosWeb = isPlatform('ios') && isPlatform('mobileweb');
  const contentEl = useRef(null);

  const { track, page, identify } = useAnalytics();
  // const url = "https://dash.akamaized.net/akamai/bbb_30fps/bbb_30fps.mpd";

  useIonViewWillEnter(() => {
    setShowVideo(!(isMobileSafari || isSafari || iosWeb));
    page(TRACK_EVENT.APP_USAGE.SCREEN.MOVIE_INFO);
  });

  useIonViewWillLeave(() => {
    setShowVideo(false);
    setMovie(null);
    setMovieId(null);
    // setMovieFeatured(null);
    console.log("ionViewWillLeave event fired: web info");
    var videoElement = document.getElementById('trailer-video');
    if(videoElement){
    videoElement.pause();
    videoElement.removeAttribute('src'); // empty source
    videoElement.load();
    }
  });

  useIonViewDidEnter(() => {
    getFeaturedMovieAction();
  });

  useEffect(() => {
    if (featuredMovieList) {
      setFeaturedMovies(featuredMovieList);
    }
  }, [featuredMovieList]);

  const fetch = () => {
    const id = params?.movieId;
    if (id && id !== movieId) {
      contentEl?.current?.scrollToTop()
      setShowLoading(true);
      setMovieId(id);
      getMovieInfo(id)
        .then((res) => {
          if (res?.data?.success && res?.data?.data) {
            setMovie(res?.data?.data);
          }
          setPageTitle('MOVIE_INFO', res?.data?.data?.collection?.title);
        })
        .catch((err) => {})
        .finally(() => setShowLoading(false));
    }
    saveFromAppAction(true);
    setPlayerUrlAction({}, null);
  };

  useEffect(() => {
    fetch();
  }, [params]);

  const onWatchClick = (movieId, collectionId) => {
    getVideoUrl(movieId, isPlatform("ios"), isPlatform("mobileweb"), browserName)
      .then((res) => {
        const data = res?.data?.data;
        if (res?.data?.success && data?.url && data.status === "verified") {
          setPlayerUrlAction({ url: data?.url, movieId: movieId, sessionId: uuidv4() }, movie);
          history.push(ROUTES.PLAYER);
        } else {
          history.push(
            ROUTES.WATCH.replace(":movieId", movieId).replace(
              ":collectionId",
              collectionId
            )
          );
        }
      })
      .catch((err) => {
        if (err?.response?.status === 402) {
          history.replace(
            ROUTES.WATCH.replace(":movieId", movieId).replace(
              ":collectionId",
              collectionId
            )
          );
        }
      })
      .finally();
    track(
      TRACK_EVENT.APP_USAGE.NAME,
      TRACK_EVENT.APP_USAGE.getClickProps(
        TRACK_EVENT.APP_USAGE.CLICK_OPTIONS.WATCH_NOW,
        TRACK_EVENT.APP_USAGE.SCREEN.MOVIE_INFO,
        {
          MovieId: movieId,
          CollectionId: collectionId,
          Title: movie?.collection?.title,
          Type: movie?.collection?.multipart ? 'Series' : 'Movie'
        }
      )
    );
  };

  return (
    <PageWrapperComponent contentEl={contentEl}>
        {movie && (
          <div>
            <div>
              <LongSingleCardComponent
                key={`long-single-card-web-${movie?.collection?.id}`}
                movie={movie?.item}
                collection={movie?.collection}
                artists={movie?.artists}
                screen={"MovieInfoWeb"}
                onWatchClick={onWatchClick}
                showVideo={showVideo}
                addSeo
              />
            </div>
            <div>
            {featuredMovies?.map((data, i) =>
                        data?.values?.length ? (
                          <MovieCarouselComponent
                            key={`featured-${i}`}
                            moviesList={data?.values}
                            name={"You might also like"}
                            screen={TRACK_EVENT.APP_USAGE.SCREEN.MOVIE_INFO}
                            isBanner={false}
                            loop={true}
                            showVideo={false}
                          />
                        ) : (
                          <></>
                        )
                      )}
            </div>
          </div>
        )}
      </PageWrapperComponent>
  );
};

const mapStateToProps = ({ payment, persist }) => {
  return {
    playerUrl: payment?.playerUrl,
    featuredMovieList: persist?.featuredMovies?.data,
  };
};
const mapDispatchToProps = {
  saveFromAppAction: saveFromApp,
  setPlayerUrlAction: setPlayerUrl,
  getFeaturedMovieAction: getFeaturedMovie,
};

export default connect(mapStateToProps, mapDispatchToProps)(MovieInfo);
