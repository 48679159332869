export const ROUTES = {
  AUTH: "/one/auth",
  HOME: "/one/home",
  MOVIES_LIST: "/one/movielist/:filter",
  MOVIES: "/one/movielist",
  SERIES_LIST: "/one/serieslist",
  USER_PROFILE: "/one/profile",
  SETTINGS: "/one/settings",
  MOVIE_INFO: "/one/movieinfo/:movieId",
  SERIES_INFO: "/one/seriesinfo/:movieId",
  PAYMENT_HISTORY: "/one/payment-history",
  SEARCH: "/one/search/:searchKey",
  WATCH_HISTORY: "/one/history",
  WATCH: "/one/watch/:collectionId/:movieId",
  WATCH_SUBSCRIBE: "/one/watch",
  PLAYER: "/one/player",
  COINS: "/one/rewards",
  EXPLORE: "/one/explore",
  COUPON: "/one/coupon",
  SHOP: "/one/shop",
  ABOUT_US:"/one/aboutus",
  DISCLAIMER:"/one/disclaimer",
  HELP_AND_SUPPORT:"/one/helpandsupport",
  TERMS_OF_SERVICE:"/one/termofservice",
  PRIVACY_POLICY:"/one/privacypolicy",
  GENRE_LIST:"/one/genrelist/:genre"
};
export const ROUTES_ID = {
  AUTH: 1,
  HOME: 2,
  MOVIES_LIST: 3,
  SERIES_LIST: 4,
  USER_PROFILE: 5,
  SETTINGS: 6,
  MOVIE_INFO: 7,
  SERIES_INFO: 8,
  PAYMENT_HISTORY: 9,
  SEARCH: 10,
  WATCH_HISTORY: 11,
  PLAYER: 12,
  COINS: 13,
  EXPLORE: 14,
  WATCH: 15,
  COUPON: 16,
  WATCH_SUBSCRIBE: 17,
  SHOP: 18,
  ABOUT_US:19,
  DISCLAIMER:20,
  HELP_AND_SUPPORT:21,
  TERMS_OF_SERVICE:22,
  PRIVACY_POLICY:23,
  GENRE_LIST:24,

};

export const getBackUrl = (currentUrl) => {
  const backUrl = ROUTES.HOME;
  if (
    [
      ROUTES.MOVIES_LIST,
      ROUTES.MOVIE_INFO,
      ROUTES.AUTH,
      ROUTES.SERIES_INFO,
      ROUTES.SERIES_LIST,
      ROUTES.SETTINGS,
      ROUTES.WATCH_HISTORY,
      ROUTES.USER_PROFILE,
      ROUTES.WATCH,
      ROUTES.HOME,
      ROUTES.COINS,
      ROUTES.EXPLORE,
      ROUTES.COUPON,
      ROUTES.ABOUT_US,
      ROUTES.DISCLAIMER,
      ROUTES.PRIVACY_POLICY,
      ROUTES.HELP_AND_SUPPORT,
      ROUTES.TERMS_OF_SERVICE,
    ].includes(currentUrl)
  ) {
    return backUrl;
  }
  return "/";
};

export const findGetParameter = (parameterName) => {
  let result = null;
  let tmp = [];
  window.location.search
    .substr(1)
    .split("&")
    .forEach((item) => {
      tmp = item.split("=");
      if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
    });
  return result;
};
