import { PageWrapperComponent } from "modules/common";
import React from "react";
import "./Loading.scss";

const Loading = () => {
  return (
    <PageWrapperComponent hideHeader hideFooter>
      <div>
        <div className="load-wrap">
          <h1 className="loader">Loading... Please wait!</h1>
        </div>
      </div>
    </PageWrapperComponent>
  );
};

export default Loading;
