import React, { useState, useEffect } from "react";
import {
  IonLoading,
  IonPage,
  IonContent,
  useIonViewDidEnter,
  useIonViewDidLeave,
} from "@ionic/react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { useAnalytics } from "use-analytics";
import { FirebaseMessaging } from "@ionic-native/firebase-messaging";
import { saveUserDetails } from "store/slices/userSlice";

import {
  signInUser,
  signUpUser,
  resetPassword,
  resetPasswordVerify,
} from "actions/services/user.service";
import { TRACK_EVENT } from "analytics/analyticsHelper";

import {
  setShowHeader,
  setShowSideMenu,
  setShowTabs,
  setDefault,
  setFcmRegistrationToken,
} from "store/slices/appSlice";
import {
  AuthenticationPageWebComponent,
  AuthenticationMobileComponent,
} from "modules/common";

const Auth = ({
  isAuthUser,
  isMobile,
  isNative,
  fcmRegistrationToken,
  saveUserDetailsAction,
  setShowHeaderAction,
  setShowSideMenuAction,
  setShowTabsAction,
  setDefaultAction,
  setFcmRegistrationTokenAction,
}) => {
  const history = useHistory();
  useEffect(() => {
    if (isAuthUser) {
      history.replace("/");
    }
  }, [isAuthUser]);
  const { track, page, identify } = useAnalytics();
  const [showLoading, setShowLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [isForgetPasswordClicked, setIsForgetPasswordClicked] = useState(false);
  const emptyModel = {
    email: "",
    fullName: "",
    password: "",
    repeatPassword: "",
    passcode: "",
    phone: "",
    countryCode: "",
    acceptPrivacyPolicy: false,
    couponCode: "",
  };
  const [user, setUser] = useState(emptyModel);
  const [error, setError] = useState(emptyModel);

  const resetError = () => {
    setError(emptyModel);
    setSuccessMessage("");
  };

  useIonViewDidEnter(() => {
    setShowHeaderAction(false);
    setShowSideMenuAction(false);
    setShowTabsAction(false);
  });

  useIonViewDidLeave(() => {
    setDefaultAction();
    setIsForgetPasswordClicked(false);
  });

  useEffect(() => {
    if (isNative) {
      FirebaseMessaging.requestPermission({ forceShow: true }).then(() => {
        FirebaseMessaging.getToken()
          .then((token) => setFcmRegistrationTokenAction(token)) // save the token server-side and use it to push notifications to this device
          .catch((err) => console.error("Error getting token", err));
      });
    }
  }, []);

  const resetForm = (isSignIn) => {
    setUser(emptyModel);
    resetError();
  };
  const onSuccess = (data) => {
    if (data?.token) {
      localStorage.setItem("token", data?.token);
    }
    if (data) {
      identify(data?.ref_id);
      saveUserDetailsAction(data);
      history.replace("/");
    }
  };

  const signIn = () => {
    const params = {
      email: user.email,
      password: user.password,
      fcmRegistrationToken,
    };
    let isErr = false;
    const err = {
      email: "",
      password: "",
    };
    if (!params.email) {
      isErr = true;
      err.email = "Please enter a valid Email";
    }
    if (!params.password) {
      isErr = true;
      err.password = "Please enter the Password";
    }
    if (isErr) {
      setError(err);
      return;
    } else {
      resetError();
    }
    setShowLoading(true);
    signInUser(params)
      .then((res) => {
        if (res.status === 200) {
          // TODO save token and
          onSuccess(res?.data);
        }
        setShowLoading(false);
      })
      // eslint-disable-next-line no-shadow
      .catch((err) => {
        // if (err.response.status === 400) {
        // }
        console.log("login", err);
        setShowLoading(false);
        setMessage("The provided email/password is not correct");
      });
    track(
      TRACK_EVENT.APP_USAGE.NAME,
      TRACK_EVENT.APP_USAGE.getClickProps(
        TRACK_EVENT.APP_USAGE.CLICK_OPTIONS.SIGNIN,
        TRACK_EVENT.APP_USAGE.SCREEN.AUTH,
        { email: user.email }
      )
    );
  };
  const signUp = () => {
    const params = {
      fullName: user.fullName,
      email: user.email,
      password: user.password,
      repeatPassword: user.repeatPassword,
      confirm_password: user.repeatPassword,
      fcmRegistrationToken,
      phone: user.phone,
      countryCode: user.countryCode,
      privacy_policy: user.acceptPrivacyPolicy,
      couponCode: user.couponCode,
    };
    let isErr = false;
    // eslint-disable-next-line no-useless-escape
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const err = emptyModel;
    if (!params.fullName) {
      isErr = true;
      err.fullName = "Please enter the Display Name";
    }
    if (
      (!params.email || !re.test(String(params?.email).toLowerCase())) &&
      !params.phone
    ) {
      isErr = true;
      err.email = "Please enter a valid Email";
    }
    if (!params.email && !params.phone) {
      isErr = true;
      err.phone = " Please enter a valid Phone Number";
    }
    if (params.phone && !params.countryCode) {
      isErr = true;
      err.countryCode = "Please select Country Code";
    }
    if (!params.password) {
      isErr = true;
      err.password = "Please enter the Password";
    }
    if (params.password !== params.repeatPassword) {
      isErr = true;
      err.repeatPassword = "Please re-enter the same password";
    }
    if (params.privacy_policy !== true) {
      isErr = true;
      err.acceptPrivacyPolicy =
        "Kindly read and accept the Privacy and policy.";
    }
    if (isErr) {
      setError(err);
      return;
    } else {
      resetError();
    }
    setShowLoading(true);
    signUpUser(params)
      .then((res) => {
        if (res.status === 201) {
          identify({
            fullName: user.fullName,
            email: user.email,
            phone: user.phone,
            countryCode: user.countryCode,
          });
          // TODO save token and
          localStorage.setItem("showSubscribePopup", 1);
          onSuccess(res?.data);
        }
        setShowLoading(false);
      })
      .catch((e) => {
        if (e.response.status === 409) {
          setMessage("The provided email/phone-number is already exists");
        } else {
          setMessage("The provided details are not correct");
        }
        setShowLoading(false);
      });
    track(
      TRACK_EVENT.APP_USAGE.NAME,
      TRACK_EVENT.APP_USAGE.getClickProps(
        TRACK_EVENT.APP_USAGE.CLICK_OPTIONS.SIGNUP,
        TRACK_EVENT.APP_USAGE.SCREEN.AUTH,
        {
          fullName: user.fullName,
          email: user.email,
          phone: user.phone,
          countryCode: user.countryCode,
        }
      )
    );
  };
  const forgetPassword = (isSubmit = false) => {
    const params = {
      email: user.email,
    };
    let isErr = false;
    const err = emptyModel;
    if (isSubmit) {
      params.activation_key = user.passcode;
      params.password = user.password;
      params.confirm_password = user.repeatPassword;
      delete params.email;
    } else {
      // eslint-disable-next-line no-useless-escape
      const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (
        !params.email
        // || !re.test(String(params?.email).toLowerCase())
      ) {
        isErr = true;
        err.email = "Please enter a valid Email";
      }
    }
    if (isSubmit && !params?.activation_key) {
      isErr = true;
      err.passcode = "Please enter the Passcode";
    }
    if (isSubmit && !params.password) {
      isErr = true;
      err.password = "Please enter the Password";
    }
    if (isSubmit && params.password !== user.repeatPassword) {
      isErr = true;
      err.repeatPassword = "Please re-enter the same password";
    }
    if (isErr) {
      setError(err);
      return;
    } else {
      resetError();
    }
    setShowLoading(true);
    setIsForgetPasswordClicked(true);
    if (isSubmit) {
      resetPasswordVerify(params)
        .then((res) => {
          if (res.status === 201) {
            setSuccessMessage("The password has been successfully changed.");
            onSuccess(res?.data);
          } else {
            setMessage("Something went wrong, Kindly try again.");
          }
          setShowLoading(false);
        })
        .catch((e) => {
          if (e.response.status === 400) {
            setMessage("The provided details are not correct");
          }
          setShowLoading(false);
        });
    } else {
      resetPassword(params)
        .then((res) => {
          if (res.status === 201) {
            setSuccessMessage(
              "Pass code has been sent on your registered email."
            );
          } else {
            setMessage("Something went wrong, Kindly retry again.");
          }
          setShowLoading(false);
        })
        .catch(() => {
          if (e.response.status === 400) {
            setMessage("Pass code has been sent on your registered email.");
          }
          setShowLoading(false);
        });
    }

    track(
      TRACK_EVENT.APP_USAGE.NAME,
      TRACK_EVENT.APP_USAGE.getClickProps(
        TRACK_EVENT.APP_USAGE.CLICK_OPTIONS.FORGET_PASS,
        TRACK_EVENT.APP_USAGE.SCREEN.AUTH,
        { email: user.email }
      )
    );
  };

  const handleChange = (evt) => {
    const value =
      evt.target.type === "checkbox" ? evt.target.checked : evt.target.value;
    setUser({
      ...user,
      [evt.target.name]: value,
    });
  };

  return (
    <>
      <IonPage className="ionPage authentication">
        <IonContent
          id="main-content"
          className="main-body"
          scrollX="true"
          scrollY="true"
          fullscreen="false"
        >
          {isMobile ? (
            <AuthenticationMobileComponent
              handleChange={handleChange}
              forgetPassword={forgetPassword}
              signUp={signUp}
              signIn={signIn}
              user={user}
              resetForm={resetForm}
              error={error}
              resetError={resetError}
              errorMessage={message}
              isForgetPasswordClicked={isForgetPasswordClicked}
              setIsForgetPasswordClicked={setIsForgetPasswordClicked}
              successMessage={successMessage}
              setMessage={setMessage}
              setUser={setUser}
            />
          ) : (
            <AuthenticationPageWebComponent
              handleChange={handleChange}
              forgetPassword={forgetPassword}
              signUp={signUp}
              signIn={signIn}
              user={user}
              resetForm={resetForm}
              error={error}
              resetError={resetError}
              errorMessage={message}
              isForgetPasswordClicked={isForgetPasswordClicked}
              setIsForgetPasswordClicked={setIsForgetPasswordClicked}
              successMessage={successMessage}
              setMessage={setMessage}
              setUser={setUser}
            />
          )}
          <IonLoading
            cssClass="my-custom-class"
            isOpen={showLoading}
            onDidDismiss={() => setShowLoading(false)}
            message={
              isForgetPasswordClicked
                ? "Sending the passcode.."
                : "Signing you in..."
            }
            duration={5000}
          />
        </IonContent>
      </IonPage>
    </>
  );
};

const mapStateToProps = ({ user, app }) => ({
  userName: user?.userName,
  isAuthUser: user?.isAuthUser,
  isNative: app?.isNative,
  userId: user?.userId,
  lastRoute: app?.lastRoute,
  fcmRegistrationToken: app?.fcmRegistrationToken,
});
const mapDispatchToProps = {
  saveUserDetailsAction: saveUserDetails,
  setShowHeaderAction: setShowHeader,
  setShowSideMenuAction: setShowSideMenu,
  setShowTabsAction: setShowTabs,
  setDefaultAction: setDefault,
  setFcmRegistrationTokenAction: setFcmRegistrationToken,
};

export default connect(mapStateToProps, mapDispatchToProps)(Auth);
