import React from "react";

export const Defination = () => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M53.3333 0H6.66667C2.96667 0 0 3 0 6.66667V53.3333C0 57 2.96667 60 6.66667 60H53.3333C57 60 60 57 60 53.3333V6.66667C60 3 57 0 53.3333 0ZM26.6667 40H21.6667V33.3333H15V40H10V20H15V28.3333H21.6667V20H26.6667V40ZM33.3333 20H46.6667C48.5 20 50 21.5 50 23.3333V36.6667C50 38.5 48.5 40 46.6667 40H33.3333V20ZM38.3333 35H45V25H38.3333V35Z"
        fill="white"
      />
    </svg>
  );
};

export const Forward10 = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 90 113"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M78.75 67.5C78.75 86.1188 63.6188 101.25 45 101.25C26.3813 101.25 11.25 86.1188 11.25 67.5C11.25 48.8812 26.3813 33.75 45 33.75V56.25L73.125 28.125L45 0V22.5C20.1375 22.5 0 42.6375 0 67.5C0 92.3625 20.1375 112.5 45 112.5C69.8625 112.5 90 92.3625 90 67.5H78.75Z"
        fill="white"
      />
      <path
        d="M38.5875 84.0373V60.0186H38.0813L28.125 63.5623V67.4436L33.8063 65.6998V84.0373H38.5875Z"
        fill="white"
      />
      <path
        d="M46.4066 69.9751V74.1376C46.4066 84.8251 53.7753 84.3751 54.5066 84.3751C55.2941 84.3751 62.6066 84.8814 62.6066 74.1376V69.9751C62.6066 59.2876 55.2378 59.7376 54.5066 59.7376C53.7191 59.7376 46.4066 59.2314 46.4066 69.9751ZM57.8816 69.3001V74.7564C57.8816 79.0876 56.7003 80.5501 54.5628 80.5501C52.4253 80.5501 51.1878 79.0876 51.1878 74.7564V69.3001C51.1878 65.0814 52.4253 63.6189 54.5066 63.6189C56.6441 63.5626 57.8816 65.0814 57.8816 69.3001Z"
        fill="white"
      />
    </svg>
  );
};

export const Fullscreen = () => {
  return (
    <svg width="25" height="25" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M63.5455 39.5H56.8182V49.6667H46.7273V56.4444H63.5455V39.5ZM23.1818 29.3333H33.2727V22.5556H16.4545V39.5H23.1818V29.3333ZM70.2727 9H9.72727C6.02727 9 3 12.05 3 15.7778V63.2222C3 66.95 6.02727 70 9.72727 70H70.2727C73.9727 70 77 66.95 77 63.2222V15.7778C77 12.05 73.9727 9 70.2727 9ZM70.2727 63.2561H9.72727V15.7439H70.2727V63.2561Z" fill="white" />
    </svg>
  );
};

export const Next = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M2 47L33.875 24.5L2 2V47ZM39.5 2V47H47V2H39.5Z" fill="white" />
    </svg>
  );
};

export const Prev = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M2 2H9.5V47H2V2ZM15.125 24.5L47 47V2L15.125 24.5Z" fill="white" />
    </svg>
  );
};

export const Repeat = () => {
  return (
    <svg width="24" height="22" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M30 13V2L16.25 15.75L30 29.5V18.5C39.1025 18.5 46.5 25.8975 46.5 35C46.5 44.1025 39.1025 51.5 30 51.5C20.8975 51.5 13.5 44.1025 13.5 35H8C8 47.155 17.845 57 30 57C42.155 57 52 47.155 52 35C52 22.845 42.155 13 30 13Z" fill="white" />
    </svg>
  );
};

export const Rewind10 = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 90 113"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M45 22.5V0L16.875 28.125L45 56.25V33.75C63.6188 33.75 78.75 48.8812 78.75 67.5C78.75 86.1188 63.6188 101.25 45 101.25C26.3813 101.25 11.25 86.1188 11.25 67.5H0C0 92.3625 20.1375 112.5 45 112.5C69.8625 112.5 90 92.3625 90 67.5C90 42.6375 69.8625 22.5 45 22.5Z"
        fill="white"
      />
      <path
        d="M38.8122 84.375H34.0309V66.0375L28.3497 67.7812V63.9L38.3059 60.3562H38.8122V84.375Z"
        fill="white"
      />
      <path
        d="M62.8874 74.4747C62.8874 76.2747 62.7186 77.8497 62.3249 79.0872C61.9311 80.3247 61.3686 81.4497 60.6936 82.2935C60.0186 83.1372 59.1186 83.756 58.1624 84.1497C57.2061 84.5435 56.0811 84.7122 54.8436 84.7122C53.6061 84.7122 52.5374 84.5435 51.5249 84.1497C50.5124 83.756 49.6686 83.1372 48.9374 82.2935C48.2061 81.4497 47.6436 80.381 47.2499 79.0872C46.8561 77.7935 46.6311 76.2747 46.6311 74.4747V70.3122C46.6311 68.5122 46.7999 66.9372 47.1936 65.6997C47.5874 64.4622 48.1499 63.3372 48.8249 62.4935C49.4999 61.6497 50.3999 61.031 51.3561 60.6372C52.3124 60.2435 53.4374 60.0747 54.6749 60.0747C55.9124 60.0747 56.9811 60.2435 57.9936 60.6372C59.0061 61.031 59.8499 61.6497 60.5811 62.4935C61.3124 63.3372 61.8749 64.406 62.2686 65.6997C62.6624 66.9935 62.8874 68.5122 62.8874 70.3122V74.4747ZM58.1061 69.6372C58.1061 68.5685 58.0499 67.6685 57.8811 66.9372C57.7124 66.206 57.4874 65.6435 57.2061 65.1935C56.9249 64.7435 56.5874 64.406 56.1374 64.2372C55.6874 64.0685 55.2374 63.956 54.7311 63.956C54.2249 63.956 53.7186 64.0685 53.3249 64.2372C52.9311 64.406 52.5374 64.7435 52.2561 65.1935C51.9749 65.6435 51.7499 66.206 51.5811 66.9372C51.4124 67.6685 51.3561 68.5685 51.3561 69.6372V75.0935C51.3561 76.1622 51.4124 77.0622 51.5811 77.7935C51.7499 78.5247 51.9749 79.1435 52.2561 79.5935C52.5374 80.0435 52.8749 80.381 53.3249 80.5497C53.7749 80.7185 54.2249 80.831 54.7311 80.831C55.2374 80.831 55.7436 80.7185 56.1374 80.5497C56.5311 80.381 56.9249 80.0435 57.2061 79.5935C57.4874 79.1435 57.7124 78.5247 57.8249 77.7935C57.9374 77.0622 58.0499 76.1622 58.0499 75.0935V69.6372H58.1061Z"
        fill="white"
      />
    </svg>
  );
};

export const Selecticon = () => {
  return (
    <svg
      width="20"
      height="30"
      viewBox="0 0 13 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="6.5"
        cy="6.5"
        r="6.5"
        transform="rotate(90 6.5 6.5)"
        fill="white"
      />
      <circle
        cx="6.5"
        cy="22.5"
        r="6.5"
        transform="rotate(90 6.5 22.5)"
        fill="white"
      />
      <circle
        cx="6.5"
        cy="38.5"
        r="6.5"
        transform="rotate(90 6.5 38.5)"
        fill="white"
      />
    </svg>
  );
};
