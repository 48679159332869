import React from "react";
import {
  FacebookShareButton,
  InstapaperShareButton,
  // LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  FacebookIcon,
  InstapaperIcon,
  // LinkedinIcon,
  TwitterIcon,
  WhatsappIcon
} from "react-share";

const SocialShare = ({url}) => {

    const _url = window.location.host + '/' + url;

    return <>
          <FacebookShareButton url={_url} ><FacebookIcon /></FacebookShareButton>
          <InstapaperShareButton url={_url}><InstapaperIcon></InstapaperIcon></InstapaperShareButton>
          <WhatsappShareButton url={_url}><WhatsappIcon></WhatsappIcon> </WhatsappShareButton>
          <TwitterShareButton url={_url}><TwitterIcon></TwitterIcon></TwitterShareButton>
    </>
}

export default SocialShare;