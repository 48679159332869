import React, { useMemo, useState } from "react";
import { connect } from "react-redux";
import "./MovieCardLongMobile.scss";
import { Link } from "react-router-dom";
import { ROUTES } from "helpers/routeHelper";
import { useAnalytics } from "use-analytics";
import { TRACK_EVENT } from "analytics/analyticsHelper";
import SocialShare from "../../../SocialShare/SocialShare";
import { shareSocialOutline, heartOutline, heart, volumeHigh, volumeMute } from "ionicons/icons";
import { IonIcon } from "@ionic/react";
import {isMobileSafari, isSafari} from 'react-device-detect';
import { Helmet } from "react-helmet";
import RippleEffect from "modules/common/components/Ripple/Ripple";

const MovieCardLongMobile = ({ collection, screen, showVideo, addSeo, disableLink }) => {
  const { track, page, identify } = useAnalytics();
  const [showThumbnail, setShowThumbnail] = useState(true);
  const trackEvent = () => {
    track(
      TRACK_EVENT.APP_USAGE.NAME,
      TRACK_EVENT.APP_USAGE.getClickProps(
        TRACK_EVENT.APP_USAGE.CLICK_OPTIONS.MOVIE_PLAY,
        screen,
        { Card: "LongMobile", CollectionId: collection.id }
      )
    );
  };
  const [toggle, setToggle] = useState(false);
  const [isLiked, setIsLiked] = useState(false);
  const [muteVid, setMuteVid] = useState(false);
  const url = useMemo(() => {
    // !isAuthUser ? ROUTES.AUTH :
    return collection?.multipart
      ? ROUTES.SERIES_INFO.replace(":movieId", collection?.id)
      : ROUTES.MOVIE_INFO.replace(":movieId", collection?.id);
  });

  const onVideoReady = () => {
    if (showVideo) {
      setTimeout(() => {
        setShowThumbnail(false);
      }, 1000);
    }
  };

  const vidmute = () =>{
    // const mutebtn2 = document.getElementById("mute_icon");
    const vid = document.getElementById("trailer-video");
    if(vid.muted){
      vid.muted = false;
      setMuteVid(false);
      // mutebtn2.style.color =  "#EFCF02"
    } else {
      vid.muted = true;
      setMuteVid(true);
      // mutebtn2.style.color = "#EB1E4E"
    }
  }
  
  return (
    <>
        {
          addSeo && 
          <Helmet>
            <meta content={collection?.description} name="description" />
            <meta content={collection?.thumbnails?.[0]} property="og:image" />
          </Helmet>
        }
      <Link
        to={{
          pathname: disableLink? '' : url,
        }}
        routerDirection="forward"
        onClick={() => trackEvent()}
      >
        
        <div className="section-mobile one">
          {showVideo && (
            <>
            {isMobileSafari || isSafari ?
            <video
              src={`${collection?.trailer}`}
              key={`video${collection?.id}`}
              id="trailer-video"
              poster={collection?.banners?.[0]}
              width="100%"
              height="100%"
              autoPlay
              className={`movie-card-long-video`}
            />
            :
            <video
              id="trailer-video"
              key={`video${collection?.id}`}
              onLoadedData={() => onVideoReady()}
              width="100%"
              height="100%"
              autoPlay="autoplay"
              playsInline={true}
              className={`movie-card-long-video ${showThumbnail ? "hide" : ""}`}
            >
              {showVideo && (
                <source src={`${collection?.trailer}`} type="video/mp4" />
              )}
            </video>
            }
            <div className="mute-btn" id="mute_btn" onClick={vidmute}>
                {muteVid ?
                  (
                    <IonIcon
                      id="mute_icon"
                      icon={volumeMute}
                    ></IonIcon>
                  ) : (
                    <IonIcon
                      id="mute_icon"
                      icon={volumeHigh}
                    ></IonIcon>
                  )
                }
            </div>
            </>
          )}
          <RippleEffect>
          <img
            key={`poster${collection?.id}`}
            className={`movie-card-long-img ${!showThumbnail ? "hide" : ""}`}
            src={`${collection?.banners?.[0]}`}
            alt="poster"
          />
          </RippleEffect>
          <div className="movie-detail-icons">
            <div className="socialiconswrap">
              <IonIcon
                onClick={() => setToggle(!toggle)}
                className="shareimg"
                icon={shareSocialOutline}
              ></IonIcon>
              <div className={toggle ? "btnsdrawer show" : "btnsdrawer"}>

                <SocialShare url={url}/>
                {/* <SocialShare url={url}/> */}
              </div>
            </div>
            {isLiked ? (
              <IonIcon
                className="likeicon"
                onClick={() => setIsLiked(!isLiked)}
                icon={heart}
              ></IonIcon>
            ) : (
              <IonIcon
                className="likeicon"
                onClick={() => setIsLiked(!isLiked)}
                icon={heartOutline}
              ></IonIcon>
            )}
          </div>
          <div className="movie-info-mobile">
            <h4>{collection?.title}</h4>
            <p>
              <span className="rating">
                {collection?.rating} {collection?.duration}{" "}
              </span>
            </p>
          </div>
          </div>
          
      </Link>
    </>
  );
};

const mapStateToProps = ({ user }) => ({
  userId: user?.userId,
  isAuthUser: user?.isAuthUser,
});
const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MovieCardLongMobile);
