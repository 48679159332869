import React, { useEffect, useState } from "react";
import "./Settings.scss";
import { FormattedMessage } from "react-intl";
import {
  IonToggle,
  IonButton,
} from "@ionic/react";
import { BUSINESS_CONFIG } from "../../../../clientConfig";
import { setPageTitle } from "helpers/seoHelper";
import { PageWrapperComponent } from "modules/common";

const Settings = ({ handleSelect, locale }) => {
  const [lang] = useState([
    { key: "en", val: "English" },
    { key: "hn", val: "हिंदी" },
    { key: "mr", val: "मराठी" },
  ]);
  useEffect(() => {
    setPageTitle("SETTING");
  }, []);
  return (
    <>
      <PageWrapperComponent>
        <div className="settingsContainer">
          <div className="settings">
            <h3>App Settings</h3>

            <div className="infoBlock">
              <div className="infoBlockRow">
                <div className="infoType">
                  <FormattedMessage id="setting.Language" />
                </div>
                <select
                  className="infoValue"
                  onChange={handleSelect}
                  defaultValue={locale}
                >
                  {lang.map((l) => (
                    <option value={l.key} key={l.key}>
                      {l.val}
                    </option>
                  ))}
                </select>
                {/* <div className="infoValue">English</div> */}
              </div>
              <div className="infoBlockRow">
                <div className="infoType">
                  <FormattedMessage id="setting.Notifications" />
                </div>
                <div className="infoValue">
                  <IonToggle checked={true} />
                </div>
              </div>
              {/* <div className="infoBlockRow">
                  <div className="infoType">
                    <FormattedMessage id="setting.Notifications" />
                  </div>
                  <div className="infoValue">
                    <IonToggle checked={true} />
                  </div>
                </div> */}
              <div className="infoBlockRow">
                <div className="infoType">
                  <FormattedMessage id="setting.Contactus" />:
                </div>
                <div className="infoValue">
                  <a href="mailto:backstage@Ott.com?subject=Contact Us">
                    backstage@Ott.com
                  </a>
                </div>
              </div>
              <div className="infoBlockRow">
                <div className="infoType">
                  <FormattedMessage id="setting.Privacypolicy" />
                </div>
                <div className="infoValue">
                  {/* <a target="_blank" href={BUSINESS_CONFIG.PrivacyPolicy}>
                      Download PDF
                    </a> */}
                  <IonButton
                    target="_blank"
                    href={BUSINESS_CONFIG.PrivacyPolicy}
                    size="small"
                    color="secondary"
                  >
                    Download PDF
                  </IonButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      </PageWrapperComponent>
    </>
  );
};

export default Settings;
