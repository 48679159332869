import { createSlice } from "@reduxjs/toolkit";

export const paymentSlice = createSlice({
  name: "payment",
  initialState: {
    // userId: "",
    movieId: null,
    tier: null,
    isSubscription: false,
    isPayPerView: false,
    isPaymentPending: true,
    playerUrl: {},
    continueWatchTime: 0,
  },
  reducers: {
    savePaymentDetailsReducer: (state, action) => {
      state.userId = action.payload;
    },
    setTierReducer: (state, action) => {
      state.setTier = action.payload;
    },
    setMovieIdReducer: (state, action) => {
      state.movieId = action.payload;
    },
    setIsSubscriptionReducer: (state, action) => {
      state.isSubscription = action.payload;
    },
    setIsPayPerViewReducer: (state, action) => {
      state.isPayPerView = action.payload;
    },
    setIsPaymentPendingReducer: (state, action) => {
      state.isPaymentPending = action.payload;
    },
    setPlayerUrlReducer: (state, action) => {
      state.playerUrl = action.payload;
    },
    setMovieInfoReducer: (state, action) => {
      state.movieInfo = action.payload;
    },
    setFromApp: (state, action) => {
      state.fromApp = action.payload;
    },
    setContinueWatch: (state, action) => {
      state.continueWatchTime = action.payload
    },
  },
});

export const {
  savePaymentDetailsReducer,
  setTierReducer,
  setMovieIdReducer,
  setIsSubscriptionReducer,
  setIsPayPerViewReducer,
  setIsPaymentPendingReducer,
  setPlayerUrlReducer,
  setMovieInfoReducer,
  setFromApp,
  setContinueWatch,
} = paymentSlice.actions;

export const saveFromApp = (data) => (dispatch) => {
  dispatch(setFromApp(data));
};
export const savePaymentDetails = (data) => (dispatch) => {
  dispatch(savePaymentDetailsReducer(data));
};
export const setTier = (data) => (dispatch) => {
  dispatch(setTierReducer(data));
};
export const setMovieId = (data) => (dispatch) => {
  dispatch(setMovieIdReducer(data));
};
export const setTierType = (data) => (dispatch) => {
  dispatch(savePaymentDetailsReducer(data));
};
export const setIsSubscription = (data) => (dispatch) => {
  dispatch(setIsSubscriptionReducer(data));
};
export const setIsPayPerView = (data) => (dispatch) => {
  dispatch(setIsPayPerViewReducer(data));
};
export const setIsPaymentPending = (data) => (dispatch) => {
  dispatch(setIsPaymentPendingReducer(data));
};
export const setPlayerUrl = (data, movieInfo, playerState) => (dispatch) => {
  dispatch(setPlayerUrlReducer(data));
  dispatch(setMovieInfoReducer(movieInfo));
  dispatch(setContinueWatch(playerState?.time || 0));
};

export default paymentSlice.reducer;
