import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import "./SeriesInfo.scss";
import { browserName, isMobileSafari, isSafari } from "react-device-detect";
import { isPlatform, useIonViewWillEnter, IonButton, useIonAlert, IonIcon } from "@ionic/react";
import { saveFromApp, setPlayerUrl } from "store/slices/paymentSlice";
import { useAnalytics } from "use-analytics";
import { TRACK_EVENT } from "../../../../analytics/analyticsHelper";
import { getSeriesInfo } from "../../../../actions/services";
import Slider from "react-slick";
import {
  MovieCardLongMobileComponent,
  LongSingleCardComponent
} from "modules/common";
import { MovieCardLongWebComponent } from "modules/common";
import { CastCardMobileComponent } from "modules/common";
import { CastCardWebComponent } from "modules/common";
import { EpisodeListWebComponent } from "modules/common";
import { EpisodeListMobileComponent } from "modules/common";
import { PageWrapperComponent } from "modules/common";
import { getMovieInfo, getVideoUrl } from "actions/services";
import { ROUTES } from "helpers/routeHelper";
import { useHistory, useParams } from "react-router-dom";
import { CONTENT_WATCH_WARNING } from "clientConfig";
import PlayButton from "modules/common/components/Play/PlayButton";
import { chevronForward } from "ionicons/icons";
import {IonPage,} from "@ionic/react";
import { HeaderComponent } from "modules/common";

const SeriesInfo = ({
  location,
  playerUrl,
  setPlayerUrlAction,
  saveFromAppAction
}) => {
  const isMobile = isPlatform("mobile");
  const history = useHistory();
  const params = useParams();
  const [present] = useIonAlert();
  const { track, page, identify } = useAnalytics();
  const [series, setSeriesData] = useState(null);
  const [viewEpisodes, setViewEpisodes] = useState(false);
  const [selectedSeason, setSelectedSeason] = useState(0);

  useEffect(() => {
    page(TRACK_EVENT.APP_USAGE.SCREEN.SERIES_INFO);
    track(
      TRACK_EVENT.APP_USAGE.NAME,
      TRACK_EVENT.APP_USAGE.getViewProps(
        TRACK_EVENT.APP_USAGE.SCREEN.SERIES_INFO
      )
    );
    const id = params?.movieId;
    getSeriesInfo(id || location?.state?.movie?.id)
      .then(res => {
        if (res?.data?.success && res?.data?.data) {
          setSeriesData(res?.data?.data);
        }
      })
      .catch(err => {});
    setPlayerUrlAction({}, null);
  }, [location]);

  const settings = {
    lazyLoad: true,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1
        }
      }
    ]
  };

  // const handleContentWarning = (movieId, collectionId) => {
  //   const warning = CONTENT_WATCH_WARNING.find(w => w.id ===
  //     series?.collection?.rating);
  //   if (warning) {
  //     present({
  //       cssClass: "my-css",
  //       header: warning.header,
  //       message: warning.message,
  //       buttons: [
  //         "No",
  //         { text: "Yes", handler: d => { onWatchClick(movieId, collectionId) } }
  //       ],
  //       onDidDismiss: e => { }
  //     });
  //   } else {
  //     onWatchClick(movieId, collectionId);
  //   }
  // }

  // const onWatchClick = (movieId, collectionId) => {
  //   getVideoUrl(
  //     movieId,
  //     isPlatform("ios"),
  //     isPlatform("mobileweb"),
  //     browserName
  //   )
  //     .then(res => {
  //       const data = res?.data?.data;
  //       if (res?.data?.success && data?.url && data.status === "verified") {
  //         setPlayerUrlAction({ url: data?.url, movieId: movieId }, series);
  //         history.push(ROUTES.PLAYER);
  //       } else {
  //         history.push(
  //           ROUTES.WATCH.replace(":movieId", movieId).replace(
  //             ":collectionId",
  //             collectionId
  //           )
  //         );
  //       }
  //     })
  //     .catch(err => {
  //       if (err?.response?.status === 402) {
  //         history.replace(
  //           ROUTES.WATCH.replace(":movieId", movieId).replace(
  //             ":collectionId",
  //             collectionId
  //           )
  //         );
  //       }
  //     })
  //     .finally();
  //   track(
  //     TRACK_EVENT.APP_USAGE.NAME,
  //     TRACK_EVENT.APP_USAGE.getClickProps(
  //       TRACK_EVENT.APP_USAGE.CLICK_OPTIONS.WATCH_NOW,
  //       TRACK_EVENT.APP_USAGE.SCREEN.MOVIE_INFO,
  //       {
  //         MovieId: movieId,
  //         CollectionId: collectionId,
  //         Title: series?.collection?.title,
  //         Type: series?.collection?.multipart ? "Series" : "Movie"
  //       }
  //     )
  //   );
  // };

  const getEpisode = (season, episode) => {
    return {
      collection: {...series?.collection, seasons: null },
      item: series?.collection?.seasons?.[season-1]?.items?.[episode-1]
    };
  }

  return (
    <IonPage className="ionPage">
        <HeaderComponent hideSearch hideLogo={true}/>
    <PageWrapperComponent>
      {series && (
        <div>
          <div>
            {isMobile ? (
              <MovieCardLongMobileComponent
                key={`mobile-movie-card-long-1${series?.collection?.id}`}
                collection={series?.collection}
                movie={getEpisode(1,1)?.item}
                screen={"SeriesInfo"}
                hideWatchNowBtn
              />
            ) : (
              <LongSingleCardComponent
                key={`long-single-card-web-${series?.collection?.id}`}
                movie={getEpisode(1,1)?.item}
                collection={series?.collection}
                artists={series?.artists}
                screen={"SeriesInfoWeb"}
                addSeo
              />
            )}
          </div>
          <div className="SeriesInfoContainer">
            {isMobile && (
              <div className="series-header">
                <h2 className="title">{series?.collection?.title}</h2>
                <ul className="series-detail-wrap-1">
                  <li className="series-detail-list">
                    {series?.collection?.rating}
                  </li>
                  {/* <li class="series-detail-list">
                    {series?.collection?.language}
                    </li>
                  <li class="series-detail-list">
                    {series?.collection?.genre?.[0]}
                  </li> */}
                </ul>
                <ul className="series-detail-wrap">
                  {/* <li class="series-detail-list">
                    {series?.collection?.rating}
                  </li> */}
                  <li className="series-detail-list">
                    {series?.collection?.language}
                    </li>
                  <li className="series-detail-list">
                    {series?.collection?.genre?.[0]}
                  </li>
                </ul>

                <PlayButton movieId={getEpisode(1,1)?.item?.id} collectionId={series?.collection?.id} collectionItemCombo={getEpisode(1,1)}
                btnClass="btnprimary">
                  <IonButton
                  size="large"
                  className="btnprimary"
                  color=""
                >
                  <img className="play-icon" src="/icons/play.svg" alt="" />
                  Watch Now
                  
                </IonButton>
                </PlayButton>
                {/* <div className="series-desc">
                  {series?.collection?.description} */}
                <div className="series-desc">
                <ul className="series-story-info">
                    <li className="series-story-header">
                        Story Line
                    </li>
                     <li className="series-story-description">
                        {series?.collection?.description}
                    </li>
                    </ul>
                </div>
              </div>
            )}
            {isMobile && (
              <div className="cast-row d-flex justify-content-between align-items-center">
                <h2 className="cast-title">Cast & Crew</h2>
                <IonIcon icon={chevronForward} color="primary" />
                {/* <OwlCarousel
                    className="owl-theme"
                    options={castCarouselOptions}
                  > */}
                {/* <Slider {...settings}>
                  {!isMobile
                    ? series?.cast_members?.map((actor, i) => (
                        <CastCardMobileComponent actor={actor} key={i} />
                      ))
                    : series?.cast_members?.map((actor, i) => (
                        <CastCardWebComponent actor={actor} key={i} />
                      ))} */}
                  {/* </OwlCarousel> */}
                {/* </Slider> */}
              </div>
            )}
            <div>
              <div className="episode-head-row">
                <div className="episodes">Episodes</div>
                <div
                  className="select-episodes"
                  onClick={() => setViewEpisodes(true)}
                >
                  {" "}
                  Season {selectedSeason + 1}
                </div>
                <div
                  className={`reveal-episodes d-flex justify-content-center align-items-center ${
                    viewEpisodes ? "true" : ""
                  }`}
                >
                  <ul className="reveal-episodes-list">
                    {series?.collection?.seasons?.map((x, i) => (
                      <li
                        className={selectedSeason === i ? "selected" : ""}
                        onClick={() => setSelectedSeason(i)}
                      >
                        Season {i + 1}
                      </li>
                    ))}
                  </ul>
                  <div
                    className="close"
                    onClick={() => setViewEpisodes(false)}
                  ></div>
                </div>
              </div>
              {isMobile ? (
                <EpisodeListMobileComponent
                collection={series?.collection}
                  
                  selectedSeason={selectedSeason}
                />
              ) : (
                <EpisodeListWebComponent
                  series={series?.collection}
                  
                  selectedSeason={selectedSeason}
                />
              )}
            </div>
          </div>
        </div>
      )}
    </PageWrapperComponent>
    </IonPage>
  );
};

const mapStateToProps = ({ payment, persist }) => {
  return {
    playerUrl: payment?.playerUrl
    // featuredMovieList: persist?.featuredMovies?.data,
  };
};
const mapDispatchToProps = {
  saveFromAppAction: saveFromApp,
  setPlayerUrlAction: setPlayerUrl
  // getFeaturedMovieAction: getFeaturedMovie,
};

export default connect(mapStateToProps, mapDispatchToProps)(SeriesInfo);
