import React, { useMemo, useState } from "react";
import "./LongSingleCard.scss";
import { heartOutline, shareSocialOutline, heart, volumeHigh, volumeMute } from "ionicons/icons";
import { IonIcon } from "@ionic/react";
import CastPopup from "../popup/popup";
import SocialShare from "../../../SocialShare/SocialShare";
import {isMobileSafari, isSafari} from 'react-device-detect';
import { Helmet } from "react-helmet";
import { ROUTES } from "../../../../../../helpers/routeHelper";
import PlayButton from "modules/common/components/Play/PlayButton";

const LongSingleCardWeb = ({
  movie,
  collection,
  artists,
  screen,
  showVideo,
  addSeo,
  hideWatchNowBtn
}) => {
  const [toggle, setToggle] = useState(false);
  const [showThumbnail, setShowThumbnail] = useState(true);
  const [isLiked, setIsLiked] = useState(false);
  const [muteVid, setMuteVid] = useState(false);
  const showWatchNowButton = () => collection?.release_state === "released" && !hideWatchNowBtn;

  const onVideoReady = () => {
    if (showVideo) {
      setTimeout(() => {
        setShowThumbnail(false);
      }, 1000);
    }
  };

  const url = useMemo(() => {
    // !isAuthUser ? ROUTES.AUTH :
    return collection?.multipart
      ? ROUTES.SERIES_INFO.replace(":movieId", collection?.id)
      : ROUTES.MOVIE_INFO.replace(":movieId", collection?.id);
  });
  // const url = useMemo(() =>
  //   movie?.multipart
  //     ? ROUTES.SERIES_INFO.replace(":movieId", collection?.id)
  //     : ROUTES.MOVIE_INFO.replace(":movieId", collection?.id)
  // );

  const vidmute = () =>{
    const mutebtn2 = document.getElementById("mute_icon");
    const vid = document.getElementById("trailer-video");
    if(vid.muted){
      vid.muted = false;
      setMuteVid(false);
      mutebtn2.style.color =  "#EFCF02"
    } else {
      vid.muted = true;
      setMuteVid(true);
      mutebtn2.style.color = "#EB1E4E"
    }
}

  return (
    <>
    {
      addSeo && 
      <Helmet>
        <meta content={collection?.description} name="description" />
        <meta content={collection?.thumbnails?.[0]} property="og:image" />
      </Helmet>
    }
      <div className="movie-info-wrapper">
        <div className="big-box">
          <div className="movie-details-box">
            <div className="movie-title">{collection?.title}</div>
            <div className="movie-ratings-duration-wrap">
              <ul className="movie-rating-duration-list">
                <li className="list">{collection?.duration}</li>
                <li className="list">{collection?.rating}</li>
                <li className="list">{collection?.language}</li>
                <li className="list">{collection?.genre?.[0]} </li>
              </ul>
            </div>
            <div className="movie-description-wrap">
              <div className="movie-desc">{collection?.description}</div>
              <div className="movie-starcast">
                <ul>
                  <li>
                    <strong>Main Cast:</strong>{" "}
                    {artists?.map((actor, key) => actor?.name)?.join(", ")}
                  </li>
                  <li>
                    <strong>Created By:</strong> {collection?.director}
                  </li>
                  <li>
                    <strong>Studio:</strong> {collection?.studio}
                  </li>
                </ul>
              </div>
            </div>
            <div className="d-flex justify-content-between align-items-center mtop">
              <div className="action-btn-wrap">
                {showWatchNowButton() && (
                  <PlayButton movieId={movie?.id} collectionId={collection?.id} collectionItemCombo={movie}
                  btnClass="play-icon">
                    <button
                    className="btn btn-lg btn-primary"
                  >
                    <img className="play-icon" src="/icons/play.svg" alt="" />
                    Play
                  </button>
                  </PlayButton>
                )}
                {/* </Link> */}
              </div>
              <div className="movie-detail-icons">
                <div className="socialiconswrap">
                  <IonIcon
                    onClick={() => setToggle(!toggle)}
                    className="shareimg"
                    icon={shareSocialOutline}
                  ></IonIcon>
                  <div className={toggle ? "btnsdrawer show" : "btnsdrawer"}>
                    <SocialShare url={url}/>
                    {/* <SocialShare url={url}/> */}
                  </div>
                </div>
                <CastPopup artists={artists} />
                {isLiked ? (
                  <IonIcon
                    className="likeicon"
                    onClick={() => setIsLiked(!isLiked)}
                    icon={heart}
                    color="primary"
                  ></IonIcon>
                ) : (
                  <IonIcon
                    className="likeicon"
                    onClick={() => setIsLiked(!isLiked)}
                    icon={heartOutline}
                  ></IonIcon>
                )}
              </div>
            </div>
          </div>
        </div>
        {/* <div className="single-movie-section-wrap"></div> */}
        <div className="video-wrap">
          {showVideo && (
            <>
            {isMobileSafari || isSafari ?
              <video
                src={`${collection?.trailer}`}
                key={`video${collection?.id}`}
                id="trailer-video"
                poster={collection?.banners?.[0]}
                width="100%"
                height="100%"
                autoPlay
                className={`movie-card-long-video`}
              />
              :
              <video
                key={`video${collection?.id}`}
                id="trailer-video"
                onLoadedData={() => onVideoReady()}
                width="100%"
                height="100%"
                autoPlay="autoplay"
                playsInline={true}
                className={`movie-card-long-video ${showThumbnail ? "hide" : ""}`}
              >
                {showVideo && (
                  <source src={`${collection?.trailer}`} type="video/mp4" />
                )}
              </video>
            }
              <div className="mute-btn" id="mute_btn" onClick={vidmute}>
                {muteVid ?
                  (
                    <IonIcon
                      id="mute_icon"
                      icon={volumeMute}
                    ></IonIcon>
                  ) : (
                    <IonIcon
                      id="mute_icon"
                      icon={volumeHigh}
                    ></IonIcon>
                  )
                }
            </div>
            </>
          )}
          <img
            key={`poster${collection?.id}`}
            className={`movie-card-long-img ${!showThumbnail ? "hide" : ""}`}
            src={`${collection?.banners?.[0]}`}
            alt="poster"
          />
        </div>
      </div>
      {/* <div className="seprator"></div> */}
    </>
  );
};

export default LongSingleCardWeb;
