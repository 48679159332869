import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { AnalyticsProvider } from "use-analytics";
import AppRouter from "./components/App";
import ErrorBoundary from "./ErrorBoundary/ErrorBoundary";
import store, { persistor } from "./store/store";
import * as serviceWorker from "./serviceWorker";
import { analytics } from "./analytics/analytics";
import reportWebVitals from './reportWebVitals';
import { PersistGate } from "redux-persist/integration/react";
import { SplashScreen } from '@capacitor/splash-screen';

import "@ionic/react/css/core.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// SplashScreen.show();

const app = (
  <ErrorBoundary>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <AnalyticsProvider instance={analytics}>
          {/* <IonReactRouter> */}
          {/* <AnimatedSwitch /> */}
          <AppRouter />

          {/* </IonReactRouter> */}
        </AnalyticsProvider>
      </PersistGate>
    </Provider>
  </ErrorBoundary>
);

ReactDOM.render(app, document.getElementById("root"));

serviceWorker.register();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
function sendToAnalytics(metric) {
  console.log('metric', metric);
  // const body = JSON.stringify(metric);
  // const url = 'https://example.com/analytics';

  // // Use `navigator.sendBeacon()` if available, falling back to `fetch()`
  // if (navigator.sendBeacon) {
  //   navigator.sendBeacon(url, body);
  // } else {
  //   fetch(url, { body, method: 'POST', keepalive: true });
  // }

  // /** If you use Google Analytics, use the id value to make
  //  * it easier to construct metric distributions manually
  //  * (to calculate percentiles, etc…). */
  // ga('send', 'event', {
  //   eventCategory: 'Web Vitals',
  //   eventAction: name,
  //   eventValue: Math.round(name === 'CLS' ? value * 1000 : value), // values must be integers
  //   eventLabel: id, // id unique to current page load
  //   nonInteraction: true, // avoids affecting bounce rate
  // });
}
reportWebVitals(sendToAnalytics);
