import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import "./Shop.scss";
import { connect } from "react-redux";
import { PageWrapperComponent } from "modules/common";
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser';
import { useIonViewDidEnter } from "@ionic/react";
import { SHOP_URL } from "clientConfig";
import { Button } from "react-bootstrap";
import { ROUTES } from "helpers/routeHelper";

const Shop = ({

}) => {

    const history = useHistory();
    useIonViewDidEnter(() => {
      var ref = InAppBrowser.create(SHOP_URL, '_blank',{
        hideurlbar: 'yes',
      });

      ref.on('exit').subscribe(event => {
        history.push(ROUTES.HOME);
        ref.close();
     });
      // ref.addEventListener('exit', (event) => {
      //   console.log('closed');

      //   });
    });

  return (
    <PageWrapperComponent showTabs={false}>
      <></>
      {/* <Button onClick={() => InAppBrowser.create(SHOP_URL, '_self',{})}>LANCH</Button> */}
    </PageWrapperComponent>
  );
};

const mapStateToProps = ({ }) => ({
});

export default connect(mapStateToProps, null)(Shop);
