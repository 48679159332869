import React, { useEffect, useState } from "react";
import "./Shop.scss";
import { connect } from "react-redux";
import { PageWrapperComponent } from "modules/common";
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser';
import { useIonViewDidEnter } from "@ionic/react";
// import { SHOP_URL } from "clientConfig";
import { GAME_URL } from "clientConfig";

const Shop = ({

}) => {

  useIonViewDidEnter(() => {
    InAppBrowser.create(GAME_URL, '_self', {});
  });

  return (
    <PageWrapperComponent>

    </PageWrapperComponent>
  );
};

const mapStateToProps = ({ user }) => ({
});

export default connect(mapStateToProps, null)(Shop);
