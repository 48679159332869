import React from "react";
import "./RadialProgress.scss";

const cleanPercentage = (percentage) => {
  const tooLow = !Number.isFinite(+percentage) || percentage < 0;
  const tooHigh = percentage > 100;
  return tooLow ? 0 : tooHigh ? 100 : +percentage;
};

const Circle = ({ colour, pct }) => {
  const r = 14;
  const circ = 2 * Math.PI * r;
  const strokePct = ((100 - pct) * circ) / 100;
  return (
    <circle
      r={r}
      cx={50}
      cy={50}
      fill="transparent"
      stroke={strokePct !== circ ? colour : ""}
      strokeWidth={"3px"}
      strokeDasharray={circ}
      strokeDashoffset={pct ? strokePct : 0}
      strokeLinecap="round"
    ></circle>
  );
};

const RadialProgress = ({ percentage, colour }) => {
  const pct = cleanPercentage(percentage);
  return (
    <div className="radial-play">
      <svg width={30} height={30}>
        <g transform={`rotate(-90 ${"15 50"})`}>
          <Circle colour="#666" />
          <Circle colour="var(--ion-color-primary)" pct={pct} />
        </g>
      </svg>
    </div>
  );
};

export default RadialProgress;
