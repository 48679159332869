import React, { useState, useEffect, Suspense } from "react";
import {
  IonRouterOutlet,
  IonApp,
  IonLoading,
  IonMenuButton,
} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { Capacitor } from "@capacitor/core";
import { App } from "@capacitor/app";
import SideMenu from "./Themes/ThemeOne/SideMenu/sideMenu";
import { Helmet } from "react-helmet";

import {
  homeOutline,
  filmOutline,
  tvOutline,
  trophyOutline,
  personOutline,
  bagOutline,
  gameController,
  menuOutline,
} from "ionicons/icons";
import "./App.scss";
import { IntlProvider } from "react-intl";

import {
  IonTabBar,
  IonTabButton,
  IonTabs,
  IonLabel,
  IonIcon,
  getPlatforms,
} from "@ionic/react";
import { getIpDetails } from "../helpers/iphelper";
import { browerInfo } from "../helpers/browserHelper";
import { analytics } from "../analytics/analytics";

import localStore from "./../utils/localStore";
import { savePlanDetails, saveUserDetails } from "./../store/slices/userSlice";

import en from "./../translation/en.json";
import es from "./../translation/es.json";
import fr from "./../translation/fr.json";
import de from "./../translation/de.json";
import hn from "./../translation/hindi.json";
import mr from "./../translation/marathi.json";
import { FormattedMessage } from "react-intl";
import "@formatjs/intl-numberformat/polyfill";
import "@formatjs/intl-numberformat/locale-data/en";
import { autoSignIn, getAllPlans } from "../actions/services/user.service";
import { connect } from "react-redux";
import { setDefault } from "../store/slices/appSlice";
import { FirebaseMessaging } from "@ionic-native/firebase-messaging";
import {
  APP_THEME,
  BUSINESS_CONFIG,
  ENABLE_ADS_BLOCKER,
  META_KEYWORDS,
  SHOW_TABS,
} from "../clientConfig";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { Geolocation } from "@ionic-native/geolocation";
import { ScreenOrientation } from "@ionic-native/screen-orientation";
import { SplashScreen } from "@capacitor/splash-screen";
import { setPageTitle } from "../helpers/seoHelper";
import { getNativeRoutes } from "modules/native/nativeRoutes";
import { LoadingNative } from "modules/native";
import { getWebRoutes } from "modules/web/webRoutes";
import { ROUTES } from "helpers/routeHelper";
import { BrowserRouter as Router } from "react-router-dom";
import DetectAdBlock from "./DetectAdBlock";

const AppRouter = ({
  // history,
  isMobile,
  currentRoute,
  saveUserDetailsAction,
  setDefaultAction,
  showSideMenu,
  showTabs,
  showHeader,
  isAuthUser,
  savePlanDetailsAction,
}) => {
  const messages = {
    en,
    es,
    fr,
    de,
    hn,
    mr,
  };
  const [locale, setLocale] = useState("en");
  const [showLoading, setShowLoading] = useState(false);
  // const [showPlansPayment, setPlansPaymentShow] = useState(false);

  useEffect(() => {
    setPageTitle("HOME");
    if (Capacitor.isNativePlatform()) {
      App.addListener("backButton", async(e) => {
        if (window.location.pathname === "/") {
          // Show A Confirm Box For User to exit app or not
          let ans = window.confirm("Exit Ott");
          if (ans) {
            await App.exitApp();
          }
        } else if (window.location.pathname === ROUTES.HOME) {
          // Show A Confirm Box For User to exit app or not
          let ans = window.confirm("Exit Ott");
          if (ans) {
           await  App.exitApp();
          }
        }
      });
    }

    if (Capacitor.isNativePlatform()) {
      FirebaseMessaging.onMessage(function(payload) {
        console.log("New foreground FCM message: ", payload);
      });
      FirebaseMessaging.onBackgroundMessage(function(payload) {
        console.log("New foreground FCM message: ", payload);
      });

      ScreenOrientation.lock(ScreenOrientation.ORIENTATIONS.PORTRAIT);
    }
  }, []);

  const handleSelect = (e) => {
    setLocale(e.target.value);
  };

  const propsToPassed = {
    handleSelect,
    locale,
  };

  const getPlans = async () => {
    let coordinates = null;
    try {
      coordinates = await Geolocation.getCurrentPosition();
    } catch (e) {
      console.log("Unable to fetch co-ordinates");
    }
    getAllPlans({
      latitude: coordinates?.coords?.latitude || "",
      longitude: coordinates?.coords?.longitude || "",
    }).then((plans) => {
      savePlanDetailsAction(plans?.data?.data);
    });
  };

  const getUser = async (token) => {
    if (token) {
      setShowLoading(true);
      const user = await autoSignIn();
      if (user) {
        saveUserDetailsAction(user?.data);
      }
      SplashScreen.hide();
      setShowLoading(false);
    } else {
      saveUserDetailsAction(null);
    }
    const _loader = document.getElementById("website-loader");
    if (_loader) {
      _loader.remove();
    }
  };

  useEffect(() => {
    async function initConfig() {
      getPlans();
      setDefaultAction({
        initial: true,
        plateforms: getPlatforms(),
        isNative: Capacitor.isNativePlatform(),
      });
      const token = localStore.getToken();
      getUser(token);
      let info = JSON.parse(sessionStorage.getItem("IPDetails"));
      //
      if (!info) {
        info = await getIpDetails();
      }
      const browser = browerInfo;
      analytics.plugins.appCustom.UserLocation({ ...info, ...browser });
    }
    initConfig();
  }, []);

  if (showLoading) {
    return <></>;
  }

  return (
    <>
      <ToastContainer />
      <Helmet>
        <meta name="keywords" content={META_KEYWORDS} />
        <meta name="description" content={BUSINESS_CONFIG.Title} />
      </Helmet>
      <IntlProvider locale={locale} messages={messages[locale]}>
        <IonApp className={APP_THEME}>
          {ENABLE_ADS_BLOCKER && (
            <DetectAdBlock pathname={window.location.pathname} />
          )}
          <RouterWrapper>
            <Suspense fallback={<LoadingNative />}>
              <IonLoading
                isOpen={showLoading}
                onDidDismiss={() => setShowLoading(false)}
                message={"Please wait..."}
              />
              <SideMenu
                disabled={!showSideMenu}
                handleSelect={handleSelect}
                locale={locale}
                {...propsToPassed}
              ></SideMenu>

              {showTabs && Capacitor.isNativePlatform() ? (
                <AppTabs
                  isAuthUser={isAuthUser}
                  currentRoute={currentRoute}
                  propsToPassed={propsToPassed}
                  isMobile={isMobile}
                />
              ) : (
                <AppRoute isMobile={isMobile} propsToPassed={propsToPassed} />
              )}
            </Suspense>
          </RouterWrapper>
        </IonApp>
      </IntlProvider>
    </>
  );
};

const mapStateToProps = ({ user, app }) => ({
  isAuthUser: user?.isAuthUser,
  showSideMenu: app?.showSideMenu,
  showTabs: app?.showTabs,
  showHeader: app?.showHeader,
  currentRoute: app?.currentRoute,
  isMobile: app?.isMobile,
});
const mapDispatchToProps = {
  savePlanDetailsAction: savePlanDetails,
  saveUserDetailsAction: saveUserDetails,
  setDefaultAction: setDefault,
};

export default connect(mapStateToProps, mapDispatchToProps)(AppRouter);

const RouterWrapper = ({ children }) => {
  return Capacitor.isNativePlatform() ? (
    <IonReactRouter>{children}</IonReactRouter>
  ) : (
    <Router>{children}</Router>
  );
};
const AppRoute = ({ propsToPassed, isMobile }) => {
  return (
    <IonRouterOutlet id="main-content">
      {Capacitor.isNativePlatform()
        ? getNativeRoutes(isMobile, propsToPassed)
        : getWebRoutes(isMobile, propsToPassed)}
    </IonRouterOutlet>
  );
};

const AppTabs = ({ currentRoute, propsToPassed, isAuthUser, isMobile }) => {
  return (
    <IonTabs className="tabs">
      <IonRouterOutlet id="main-content">
        {Capacitor.isNativePlatform()
          ? getNativeRoutes(isMobile, propsToPassed)
          : getWebRoutes(isMobile, propsToPassed)}
      </IonRouterOutlet>

      <IonTabBar slot="bottom" className="customTab">
        {SHOW_TABS.HOME && (
          <IonTabButton
            key="tab_home"
            tab="tab.home"
            href={ROUTES.HOME}
            className={
              currentRoute === ROUTES.HOME || currentRoute === "/"
                ? "customTabButton-active"
                : "customTabButton"
            }
          >
            <IonIcon icon={homeOutline} />
            <IonLabel>
              <FormattedMessage id="tab.Home" />
            </IonLabel>
          </IonTabButton>
        )}
        {SHOW_TABS.MOVIES_LIST && (
          <IonTabButton
            tab="tab2"
            key="tab_movie_list"
            href={ROUTES.MOVIES}
            className={
              currentRoute === ROUTES.MOVIES_LIST
                ? "customTabButton-active"
                : "customTabButton"
            }
          >
            <IonIcon icon={filmOutline} />
            <IonLabel>
              <FormattedMessage id="tab.Movies" />
            </IonLabel>
          </IonTabButton>
        )}
        {SHOW_TABS.SERIES_LIST && (
          <IonTabButton
            tab="tab3"
            key="tab_series_list"
            href={ROUTES.SERIES_LIST}
            className={
              currentRoute === ROUTES.SERIES_LIST
                ? "customTabButton-active"
                : "customTabButton"
            }
          >
            <IonIcon icon={tvOutline} />
            <IonLabel>
              <FormattedMessage id="tab.Webseries" />
            </IonLabel>
          </IonTabButton>
        )}
        {SHOW_TABS.COINS && (
          <IonTabButton
            tab="tab4"
            key="tab_rewards"
            href={ROUTES.COINS}
            className={
              currentRoute === ROUTES.COINS
                ? "customTabButton-active"
                : "customTabButton"
            }
          >
            <IonIcon icon={trophyOutline} />
            <IonLabel>
              <FormattedMessage id="tab.Rewards" />
            </IonLabel>
          </IonTabButton>
        )}
        {SHOW_TABS.EXPLORE && (
          <IonTabButton
            tab="tab6"
            key="tab_explore"
            href={ROUTES.EXPLORE}
            className={
              currentRoute === ROUTES.EXPLORE
                ? "customTabButton-active"
                : "customTabButton"
            }
          >
            <IonIcon icon={tvOutline} />
            <IonLabel>
              <FormattedMessage id="tab.Explore" />
            </IonLabel>
          </IonTabButton>
        )}
        {SHOW_TABS.SHOP && (
          <IonTabButton
            tab="tab7"
            key="tab_shop"
            href={ROUTES.SHOP}
            className={
              currentRoute === ROUTES.SHOP
                ? "customTabButton-active"
                : "customTabButton"
            }
          >
            <IonIcon icon={bagOutline} />
            <IonLabel>
              <FormattedMessage id="tab.Shop" />
            </IonLabel>
          </IonTabButton>
        )}
        {SHOW_TABS.GAME && (
          <IonTabButton
            tab="tab9"
            key="tab_game"
            href={ROUTES.SHOP}
            className={
              currentRoute === ROUTES.SHOP
                ? "customTabButton-active"
                : "customTabButton"
            }
          >
            <IonIcon icon={gameController} />
            <IonLabel>
              <FormattedMessage id="tab.Games" />
            </IonLabel>
          </IonTabButton>
        )}
        {SHOW_TABS.USER_PROFILE && (
          <IonTabButton
            tab="tab5"
            key="tab_user_profile"
            href={isAuthUser ? ROUTES.USER_PROFILE : ROUTES.AUTH}
            className={
              currentRoute === ROUTES.USER_PROFILE
                ? "customTabButton-active"
                : "customTabButton"
            }
          >
            <IonIcon icon={personOutline} />
            <IonLabel>
              <FormattedMessage id="tab.Account" />
            </IonLabel>
          </IonTabButton>
        )}
        <IonTabButton>
          <IonMenuButton color="primary">
            <IonIcon icon={menuOutline} />
          </IonMenuButton>

          <div className="tabs-label">Menu</div>
        </IonTabButton>
      </IonTabBar>
    </IonTabs>
  );
};
