const Screens = {
  AUTH: "Authentication",
  HOME: "Home",
  MOVIE_LIST: "Movie List",
  MOVIE_DESC: "Movie Description",
  MOVIE_PLAYER: "Movie Player",
  PAYMENT: "Payment",
  PAYMENT_HISTORY: "Payment History",
  WATCH_HISTORY: "Watch History",
  SERIES_INFO: "Series Info",
  SERIES_List: "Series list",
  MOVIE_INFO: "Movie Info",
  EXPLORE: "Explore",
  USER_PROFILE: "User Profile",
  PLAYER_WEB: "Player Web",
  PLAYER_NATIVE: "Player Native",
  PLAYER_IOS: "Player Ios",
  WATCH: 'Watch',
  SEARCH_RESULT: 'Search Result',
};

const APP_USAGE = {
  NAME: "Application Usages",
  ACTIONS: {
    CLICKED: "Clicked",
    VIEWED: "Viewed"
  },
  SCREEN: Screens,
  CLICK_OPTIONS: {
    MOVIE_CARD: "Movie Card",
    MOVIE_PLAY: "Movie Play",
    PAYMENT_CLICK: "Payment Click",
    SIGNIN: "SignIn",
    SIGNUP: "SignUp",
    FORGET_PASS: "Forgot Password",
    PAYMENT: "Payment",
    WATCH_NOW: "Watch Now",
    PAYMENT_OPTION: "Payment Option",
    PAYMENT_METHOD: "Payment Method",
    SEARCH: "search",
    DOWNLOAD_APP: "Download App",
    CLOSE_DOWNLOAD_APP_BANNER: "Close Download App Banner"
  },
  getClickProps: (clickOption, screen, extraProps) => ({
    Action: "Clicked",
    Screen: screen,
    ClickOption: clickOption,
    ...extraProps
  }),
  getViewProps: (screen, extraProps) => ({
    Action: "Viewed",
    Screen: screen,
    ...extraProps
  })
};

const PAYMENT_EVENT = {
  NAME: "Payment Event",
  ACTIONS: {
    CLICKED: "Clicked",
    VIEWED: "Viewed"
  },
  STATUS: {
    INIT: "Payment Initialted",
    IN_PROGRESS: "Payment InProgress",
    PAYMENT_DONE: "Payment Done",
    FAILURE: "Payment Failure",
    VERIFIED: "Payment Verified"
  },
  getViewProps: extraProps => ({
    Action: "Viewed",
    ...extraProps
  }),
  getClickProps: (clickOption, extraProps) => ({
    Action: "Clicked",
    ClickOption: clickOption,
    ...extraProps
  }),
};

const PLAYER_EVENT = {
  NAME: "Player Usage",
  ACTION: {
    INITILIZED: "Stream Initialized",
    STARTED: "Video Started",
    QUALITY_CHANGED: "Quality Changed",
    ENDED: "Video Ended",
    PAUSED: "Video Paused",
    PLAYED: "Video Played",
    PLAYED_PAUSED: "Video Played/Paused",
    ERROR: "Player Error",
    SEEK: "Video Seeked",
    EXIT: "Player Exit"
  },
  CLICK: {
    PLAY: "Play",
    PAUSE: "Pause",
    FULLSCREEN: "Fullscreen",
    QUALITY: "Quality Selector",
    SEEK_10_PLUS: "Seek Ten Plus",
    SEEK_10_MINUS: "Seek Ten  Minus",
    NEXT: "Next",
    PREVIOUS: "Previous",
    MUTE: "Mute",
    REPEAT: "Repeat"
  },
  getClickProps: (name, extraProps) => ({
    "CLICK OPTION": name,
    Action: "Clicked",
    ...extraProps
  }),
  getProps: (action, extraProps) => ({
    Action: action,
    ...extraProps
  })
};

export const TRACK_EVENT = {
  PAYMENT_EVENT,
  APP_USAGE,
  PLAYER_EVENT
};

export const IDENTIFY_TRAITS = {
  PAYMENT: {
    NAME: "PaymentStatus",
    VALUES: {
      NOT_STARTED: "Not Started",
      INIT: "Payment Initialted",
      IN_PROGRESS: "In Progress",
      SUBSCRIBED: "Subscribed",
      SUBSCRIBE_CANCEL: "Subscribtion Cancelled",
      PAYMENT_DONE: "Payment Done",
      FAILURE: "Payment Failure",
      CANCLED: "Payment Cancled",
      VERIFIED: "Payment Verified"
    }
  },
  LOGIN: {
    NAME: "UserRegistration",
    VALUES: {
      NOT_REGISTERD: "Not Registered",
      SIGNUP_STARTED: "Signup Started",
      REGISTERD: "Registered"
    }
  }
};
