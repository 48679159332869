import React, { useEffect, useRef, useState } from "react";
import "./VideoPlayer.scss";
import dashjs from "dashjs";
import ControlBar from "modules/common/ControlBar";
import "./icomoon.ttf";
import {
  Defination,
  Selecticon,
  Rewind10,
  Repeat,
  Prev,
  Next,
  Fullscreen,
  Forward10,
} from "./Icons";
import { useAnalytics } from "use-analytics";
import { TRACK_EVENT } from "analytics/analyticsHelper";
import { getPlatforms } from "@ionic/react";
import {
  setShowHeader,
  setShowSideMenu,
  setShowTabs,
  setDefault,
} from "store/slices/appSlice";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import Slider from "react-slick";
import localStore from "utils/localStore";
import { getBackendApiUrl } from "config/config";
import { CastCardMobileComponent } from "modules/common";
import { PageWrapperComponent } from "modules/common";
import { updateMovieWatchTime } from "actions/services/movie.service";

const VideoPlayer = ({
  url,
  movieInfo,
  setShowHeaderAction,
  setShowSideMenuAction,
  setShowTabsAction,
  setDefaultAction,
  isMobile,
  sessionId,
  continueWatchTime,
  playNextUrl,
}) => {
  const history = useHistory();
  const { track, page, identify } = useAnalytics();
  const [isTimeout, setIstimeout] = useState(true);
  const [showRating, setShowRating] = useState(true);
  const [showLoader, setShowLoader] = useState(false);
  const [metaLoader, setMetaLoader] = useState(false);
  const [showNextVideo, setShowNextVideo] = useState(false);

  const playerRef = useRef(null);
  const playerDashRef = useRef(null);
  const trackData = useRef(null);

  useEffect(() => {
    page(TRACK_EVENT.APP_USAGE.SCREEN.PLAYER_WEB);
    setShowHeaderAction(false);
    setShowSideMenuAction(false);
    setShowTabsAction(false);
    trackData.current = {
      sessionId: sessionId,
      MovieId: movieInfo?.item?.id,
      SeasonId: movieInfo?.item?.season_id,
      CollectionId: movieInfo?.collection?.id,
      Title: movieInfo?.collection?.title,
      Type: movieInfo?.collection?.multipart ? "Series" : "Movie",
      Player: "Videoplayer.JS",
    };
    if (!url) {
      history.replace("/");
    }

    return () => {
      try {
        track(
          TRACK_EVENT.PLAYER_EVENT.NAME,
          TRACK_EVENT.PLAYER_EVENT.getProps(
            TRACK_EVENT.PLAYER_EVENT.ACTION.EXIT,
            { ...trackData.current }
          )
        );
        try {
          const currentTime = playerRef.current.currentTime;
          if (currentTime > 0 && movieInfo?.item?.id) {
            updateMovieWatchTime(movieInfo?.item?.id, currentTime);
          }
        } catch (e) {}
        try {
          playerDashRef.current.destroy();
        } catch (e) {
          playerRef.current.pause();
          playerRef.current.removeAttribute("src"); // empty source
          playerRef.current.load();
        }
      } catch (e) {}
      setDefaultAction();
    };
  }, []);

  const startVideo = () => {
    //
    let videoElement = document.querySelector(".videoContainer video");
    let player = dashjs.MediaPlayer().create();
    const protData = {
      "org.w3.clearkey": {
        serverURL: `${getBackendApiUrl()}/license/`,
      },
    };
    player.setProtectionData(protData);

    var filterLicenseRequest = function(request) {
      /* Here you can modify/overwrite the licens request (url, headers, data...) */
      request.data = JSON.stringify(request.data);
      if (localStore.getToken()) {
        request.headers["Authorization"] = `JWT ${localStore.getToken()}`;
      }
      return Promise.resolve();
    };
    player.initialize(videoElement, url, true);
    player.registerLicenseRequestFilter(filterLicenseRequest);
    const adsUrl =
      "https://pubads.g.doubleclick.net/gampad/live/ads?iu=/22669880101/Test_Video_Ad&description_url=http%3A%2F%2Fflowgiga.com&tfcd=0&npa=0&sz=400x300%7C640x480&ciu_szs=fluid&gdfp_req=1&output=vast&unviewed_position_start=1&env=vp&impl=s&correlator=";
    let controlbar = new ControlBar(player, false, adsUrl);
    controlbar.initialize();
    playerDashRef.current = player;
    player.on("streamInitialized", (e) => {
      setMetaLoader(false);
      const p = player;

      const data = {
        url,
        event: "PlayerInitilized",
        currentTime: p?.time(),
        Platforms: getPlatforms().join(","),
        movie: movieInfo,
      };
      console.log(data);

      setTimeout(() => {
        setShowRating(false);
      }, 3000);

      setTimeout(() => {
        if (continueWatchTime > 0) {
          player.seek(continueWatchTime);
          setShowLoader(false);
        }
      }, 500);

      track(
        TRACK_EVENT.PLAYER_EVENT.NAME,
        TRACK_EVENT.PLAYER_EVENT.getProps(
          TRACK_EVENT.PLAYER_EVENT.ACTION.INITILIZED,
          { ...data, ...trackData.current }
        )
      );
    });
    player.on("playbackSeeked", (e) => {
      const p = player;
      const data = {
        url,
        event: "VideoSeeked",
        currentTime: p?.time(),
        Platforms: getPlatforms().join(","),
        movie: movieInfo,
      };
      track(
        TRACK_EVENT.PLAYER_EVENT.NAME,
        TRACK_EVENT.PLAYER_EVENT.getProps(
          TRACK_EVENT.PLAYER_EVENT.ACTION.SEEK,
          { ...data, ...trackData.current }
        )
      );
    });
    player.on("playbackPaused", (e) => {
      const p = player;
      if (!e?.ended) {
        const data = {
          url,
          event: "VideoPaused",
          currentTime: p?.time(),
          Platforms: getPlatforms().join(","),
          movie: movieInfo,
        };
        const currentTime = playerRef.current.currentTime;
        if (currentTime > 0 && movieInfo?.item?.id) {
          updateMovieWatchTime(movieInfo?.item?.id, currentTime);
        }
        // track(
        //   TRACK_EVENT.PLAYER_EVENT.NAME,
        //   TRACK_EVENT.PLAYER_EVENT.getProps(
        //     TRACK_EVENT.PLAYER_EVENT.ACTION.PAUSED,
        //     data
        //   )
        // );
      }
    });
    player.on("playbackPlaying", (e) => {
      const p = player;
      if (e?.playingTime < 0) {
        const data = {
          url,
          event: "InitialPlayed",
          currentTime: p?.time(),
          duration: p.duration(),
          Platforms: getPlatforms().join(","),
          movie: movieInfo,
        };
      } else {
        const data = { url, event: "InitialPlayed", currentTime: p?.time() };
        console.log(data);
        // track(
        //   TRACK_EVENT.PLAYER_EVENT.NAME,
        //   TRACK_EVENT.PLAYER_EVENT.getProps(
        //     TRACK_EVENT.PLAYER_EVENT.ACTION.PLAYED,
        //     data
        //   )
        // );
      }
    });

    player.on("qualityChangeRequested", (e) => {
      const p = player;
      if (e?.oldQuality > 0) {
        const data = {
          url,
          event: "qualityChangeRequested",
          newQuality: e?.newQuality,
          oldQuality: e?.oldQuality,
          currentTime: p?.time(),
          videoQualityCount: p?.getBitrateInfoListFor("video")?.length,
          Platforms: getPlatforms().join(","),
          movie: movieInfo,
        };
        console.log(data);
        track(
          TRACK_EVENT.PLAYER_EVENT.NAME,
          TRACK_EVENT.PLAYER_EVENT.getProps(
            TRACK_EVENT.PLAYER_EVENT.ACTION.QUALITY_CHANGED,
            { ...data, ...trackData.current }
          )
        );
      }
    });
    player.on("error", (err) => {
      const p = player;
      const data = {
        url,
        event: "error",
        error: JSON.stringify(err),
        currentTime: p?.time(),
        videoQualityCount: p?.getBitrateInfoListFor("video")?.length,
        Platforms: getPlatforms().join(","),
        movie: movieInfo,
      };
      track(
        TRACK_EVENT.PLAYER_EVENT.NAME,
        TRACK_EVENT.PLAYER_EVENT.getProps(
          TRACK_EVENT.PLAYER_EVENT.ACTION.ERROR,
          { ...data, ...trackData.current }
        )
      );
    });

    player.on("playbackWaiting", (e) => {
      // setShowLoader(true);
    });

    player.on("playbackStarted", (e) => {
      setShowLoader(false);
    });

    if (playNextUrl) {
      player.on("playbackPlaying", (e) => {
        setShowLoader(false);
      });
    }

    player.on("playbackTimeUpdated", (e) => {
      if (!showNextVideo && e.timeToEnd < 30) {
        setShowNextVideo(true);
      }
    });

    player.on("playbackEnded", () => {
      const p = player;
      const data = {
        url,
        event: "playbackEnded",
        currentTime: p?.time(),
        videoQualityCount: p?.getBitrateInfoListFor("video")?.length,
        Platforms: getPlatforms().join(","),
        movie: movieInfo,
      };
      track(
        TRACK_EVENT.PLAYER_EVENT.NAME,
        TRACK_EVENT.PLAYER_EVENT.getProps(
          TRACK_EVENT.PLAYER_EVENT.ACTION.ENDED,
          { ...data, ...trackData.current }
        )
      );
    });
    return player;
  };
  useEffect(() => {
    if (url) {
      setMetaLoader(true);
      startVideo();
    }
  }, [url]);

  const playPause = () => {
    const data = {
      url,
      currentTime: playerDashRef.current?.time(),
      videoQualityCount: playerDashRef.current?.getBitrateInfoListFor("video")
        ?.length,
      Platforms: getPlatforms().join(","),
    };
    track(
      TRACK_EVENT.PLAYER_EVENT.NAME,
      TRACK_EVENT.PLAYER_EVENT.getProps(
        TRACK_EVENT.PLAYER_EVENT.ACTION.PLAYED_PAUSED,
        { ...data, ...trackData.current }
      )
    );
  };

  const mouseMoveEvent = () => {
    try {
      if (isTimeout) {
        var timer;
        var fadeInBuffer = false;
        if (!fadeInBuffer && timer) {
          clearTimeout(timer);
          timer = 0;
          document.body.style.cursor = "";
        } else {
          document.body.style.cursor = "default";
          document.getElementById("customControl").style.opacity = 1;
          document.getElementById("videoController").style.opacity = 1;
          fadeInBuffer = false;
        }

        timer = setTimeout(function() {
          try {
            document.body.style.cursor = "none";
            document.getElementById("customControl").style.opacity = 0;
            document.getElementById("videoController").style.opacity = 0;
            fadeInBuffer = true;
            setIstimeout(true);
          } catch (e) {}
        }, 4000);
        setIstimeout(false);
      }
    } catch (e) {}
  };

  const onRewind = () => {
    track(
      TRACK_EVENT.PLAYER_EVENT.NAME,
      TRACK_EVENT.PLAYER_EVENT.getClickProps(
        TRACK_EVENT.PLAYER_EVENT.CLICK.SEEK_10_MINUS,
        {
          url,
          Platforms: getPlatforms().join(","),
          movie: movieInfo,
          ...trackData.current,
        }
      )
    );
  };
  const onForward = () => {
    track(
      TRACK_EVENT.PLAYER_EVENT.NAME,
      TRACK_EVENT.PLAYER_EVENT.getClickProps(
        TRACK_EVENT.PLAYER_EVENT.CLICK.SEEK_10_PLUS,
        {
          url,
          Platforms: getPlatforms().join(","),
          movie: movieInfo,
          ...trackData.current,
        }
      )
    );
  };
  const onNext = () => {
    track(
      TRACK_EVENT.PLAYER_EVENT.NAME,
      TRACK_EVENT.PLAYER_EVENT.getClickProps(
        TRACK_EVENT.PLAYER_EVENT.CLICK.NEXT,
        {
          url,
          Platforms: getPlatforms().join(","),
          movie: movieInfo,
          ...trackData.current,
        }
      )
    );
  };
  const onPrevious = () => {
    track(
      TRACK_EVENT.PLAYER_EVENT.NAME,
      TRACK_EVENT.PLAYER_EVENT.getClickProps(
        TRACK_EVENT.PLAYER_EVENT.CLICK.PREVIOUS,
        {
          url,
          Platforms: getPlatforms().join(","),
          movie: movieInfo,
          ...trackData.current,
        }
      )
    );
  };
  const onRepeat = () => {
    track(
      TRACK_EVENT.PLAYER_EVENT.NAME,
      TRACK_EVENT.PLAYER_EVENT.getClickProps(
        TRACK_EVENT.PLAYER_EVENT.CLICK.REPEAT,
        {
          url,
          Platforms: getPlatforms().join(","),
          movie: movieInfo,
          ...trackData.current,
        }
      )
    );
  };
  const onFullScreen = () => {
    track(
      TRACK_EVENT.PLAYER_EVENT.NAME,
      TRACK_EVENT.PLAYER_EVENT.getClickProps(
        TRACK_EVENT.PLAYER_EVENT.CLICK.FULLSCREEN,
        {
          url,
          Platforms: getPlatforms().join(","),
          movie: movieInfo,
          ...trackData.current,
        }
      )
    );
  };
  const onMute = () => {
    track(
      TRACK_EVENT.PLAYER_EVENT.NAME,
      TRACK_EVENT.PLAYER_EVENT.getClickProps(
        TRACK_EVENT.PLAYER_EVENT.CLICK.MUTE,
        {
          url,
          Platforms: getPlatforms().join(","),
          movie: movieInfo,
          ...trackData.current,
        }
      )
    );
  };

  const settings = {
    lazyLoad: true,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <PageWrapperComponent hideHeader hideFooter pageClass="video-player">
        <div className="dash-video-player" onMouseMove={mouseMoveEvent}>
          {/* <!-- HTML structure needed for the ControlBar --> */}

          <div className="videoContainer" id="videoContainer">
            {/* <IonLoading
                isOpen={showLoader}
                onDidDismiss={() => setShowLoader(false)}
                message={"Loading..."}
              /> */}
            {(showLoader || metaLoader) && <div className="spinner"></div>}
            <div className="videocontainerinner">
              <div id="customControl" class={metaLoader ? "hide" : ""}>
                {showRating && (
                  <div className="videoratingwrap">
                    <div className="rating main">
                      {movieInfo?.collection?.rating}
                    </div>
                    <div className="rating semi">
                      {movieInfo?.item?.rating_tags?.join(", ")}
                    </div>
                  </div>
                )}
                <div
                  id="bitrateListBtn"
                  className="control-icon-layout bitrateicon"
                  title="Bitrate List"
                >
                  <Defination />
                  <Selecticon />
                </div>
                <div
                  id="rewindBtn"
                  className="btn-rewind"
                  title="Rewind"
                  onClick={() => {
                    onRewind();
                  }}
                >
                  <span id="iconrewind" className="icon-rewind">
                    <Rewind10 />
                    <span className="icon-forward10"></span>
                  </span>
                </div>
                <div
                  id="playPauseBtn"
                  className="btn-play-pause"
                  title="Play/Pause"
                  onClick={() => playPause()}
                >
                  <span id="iconPlayPause" className="icon-play"></span>
                </div>
                <div
                  id="forwardBtn"
                  className="btn-forward"
                  title="Forward"
                  onClick={() => {
                    onForward();
                  }}
                >
                  <span id="iconforward" className="icon-forward">
                    <Forward10 />
                  </span>
                </div>
              </div>
              <video
                className="videoElementWeb"
                id="videoElement"
                preload="auto"
                autoPlay={false}
                ref={playerRef}
                poster={movieInfo?.item?.thumbnails?.[0]}
              >
                {" "}
              </video>
              <div id="ad-container" className="videoElementWeb"></div>
              <div
                id="videoController"
                className="video-controller unselectable"
              >
                <div className="seekgrid">
                  <div className="seekContainer">
                    <div id="seekbar" className="seekbar seekbar-complete">
                      <div
                        id="seekbar-buffer"
                        className="seekbar seekbar-buffer"
                      ></div>
                      <div
                        id="seekbar-play"
                        className="seekbar seekbar-play"
                      ></div>
                    </div>
                  </div>
                  <span id="videoTime" className="time-display">
                    00:00:00
                  </span>
                  <span id="videoDuration" className="duration-display">
                    00:00:00
                  </span>
                </div>
                <div className="next-prev-wrap">
                  {false && (
                    <div
                      id="prev-btn"
                      className="btn-prev control-icon-layout"
                      title="Previous"
                      onClick={() => {
                        onPrevious();
                      }}
                    >
                      <Prev />
                    </div>
                  )}
                  <div
                    id="repeat-btn"
                    className="btn-repeat control-icon-layout hide"
                    title="Repeat"
                    onClick={() => {
                      onRepeat();
                    }}
                  >
                    <Repeat />
                  </div>
                  {false && (
                    <div
                      id="next-btn"
                      className="btn-next control-icon-layout"
                      title="Next"
                      onClick={() => {
                        onNext();
                      }}
                    >
                      <Next />
                    </div>
                  )}
                </div>
                <div className="bottom-controls">
                  <div className="volume-wrap">
                    <div
                      id="muteBtn"
                      className="btn-mute control-icon-layout"
                      title="Mute"
                      onClick={() => {
                        onMute();
                      }}
                    >
                      <span id="iconMute" className="icon-mute-off"></span>
                    </div>
                    <input
                      type="range"
                      id="volumebar"
                      className={`volumebar ${isMobile ? "hide" : ""}`}
                      min="0"
                      max="1"
                      step=".01"
                    />
                  </div>
                  <div className="right-controls">
                    <div
                      id="trackSwitchBtn"
                      className="control-icon-layout hide"
                      title="A/V Tracks"
                    >
                      <span className="icon-tracks"></span>
                    </div>
                    <div
                      id="captionBtn"
                      className="btn-caption control-icon-layout"
                      title="Closed Caption"
                    >
                      <span className="icon-caption"></span>
                    </div>
                    <div
                      id="fullscreenBtn"
                      className="btn-fullscreen control-icon-layout"
                      title="Fullscreen"
                      onClick={() => {
                        onFullScreen();
                      }}
                    >
                      <Fullscreen />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {movieInfo && isMobile && (
          <div>
            <div></div>
            <div className="movieInfoContainer">
              <div className="font-bold">
                <div className="titleBlock">
                  <div>
                    <h2 className="title">{movieInfo?.collection?.title}</h2>
                    <ul className="movie-details-wrap">
                      <li className="movie-details-list">
                        {movieInfo?.item?.duration}
                      </li>
                      <li className="movie-details-list">
                        {movieInfo?.collection?.rating}
                      </li>
                      <li className="movie-details-list">English</li>
                      <li className="movie-details-list">
                        {movieInfo?.collection?.genre?.[0]}
                      </li>
                      <li className="movie-details-list">
                        {movieInfo?.item?.rating_tags}
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="descriptionBlock">
                  <p>{movieInfo?.collection?.description}</p>
                </div>
              </div>
            </div>
          </div>
        )}
      </PageWrapperComponent>
    </>
  );
};

const mapStateToProps = ({ payment, app }) => ({
  isMobile: app?.isMobile,
  url: payment?.playerUrl?.url,
  sessionId: payment?.playerUrl?.sessionId,
  movieInfo: payment?.movieInfo,
  continueWatchTime: payment?.continueWatchTime,
  playNextUrl: payment?.playNextUrl,
});

const mapDispatchToProps = {
  setShowHeaderAction: setShowHeader,
  setShowSideMenuAction: setShowSideMenu,
  setShowTabsAction: setShowTabs,
  setDefaultAction: setDefault,
};

export default connect(mapStateToProps, mapDispatchToProps)(VideoPlayer);
