import React from "react";
import PropTypes from "prop-types";

import "./CastCardWeb.scss";

const propTypes = {
  actor: PropTypes.shape({
    avatar: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }),
};

const CastCardWeb = ({ actor }) => (
  <div className="cast-card">
    <div className="cast-img-wrap">
      <img className="cast-img" src={actor?.avatar} alt="" />
      </div>
    <h4 className="cast-name">{actor?.name}</h4>
  </div>
);

CastCardWeb.propTypes = propTypes;

export default CastCardWeb;
