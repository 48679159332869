import React, { useEffect, useState } from "react";
import "./StaticPagesMobile.scss";
import { useRouteMatch } from "react-router";
import {
    IonButton,
    IonPage,
} from "@ionic/react";
import { BUSINESS_CONFIG } from "../../../../clientConfig";
import { setPageTitle } from "helpers/seoHelper";
import { PageWrapperComponent } from "modules/common";
import { HeaderComponent } from "modules/common";
 
const HelpandSupport = () => {
    useEffect(() => {
        setPageTitle("Help & Support");
    }, []);
 
 
    const getTitle = () => {
        return "Help & Support"
    }
    return (
        <>
            <IonPage className="ionPage">
                <HeaderComponent pageTitle={getTitle()}  hideSearch hideLogo={true} hideMenuButton />
                <PageWrapperComponent>
                    <div className="body-text">Hello</div>
                </PageWrapperComponent>
            </IonPage>
        </>
    )
}
export default HelpandSupport;