import Analytics from "analytics";
import { BUSINESS_CONFIG } from "../clientConfig";
import { getAnalyticsUrl } from "../config/config";
import googleAnalytics from '@analytics/google-analytics'

const getCommonProps = () => {
  return {
    BusinessId: BUSINESS_CONFIG.BusinessId,
    BusinessName: BUSINESS_CONFIG.BusinessName
  };
};

/* This is an example plugin */
function ottoProviderPlugin(userConfig = {}) {
  return {
    name: "one",
    NAMESPACE: "Otto-example",
    config: userConfig,
    initialize: ({ payload }) => {
      console.log("Load stuff");
    },
    page: ({ payload }) => {
      const props = { ...getCommonProps(), ...payload };
      // console.log(`Example Page > [props: ${JSON.stringify(props, null, 2)}]`);
      const response = fetch(`${userConfig.apiUrl}/p`, {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        mode: "cors", // no-cors, *cors, same-origin
        // cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        // credentials: "same-origin", // include, *same-origin, omit
        headers: {
          "Content-Type": "application/json",
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        // redirect: "follow", // manual, *follow, error
        referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify(props) // body data type must match "Content-Type" header
      }).catch(() => {});
    },
    /* Track event */
    track: ({ payload }) => {
      const props = { ...getCommonProps(), ...payload };
      const response = fetch(`${userConfig.apiUrl}/t`, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json"
        },

        referrerPolicy: "no-referrer",
        body: JSON.stringify(props)
      }).catch(() => {});
    },
    /* Identify user */
    identify: ({ payload }) => {
      const props = { ...getCommonProps(), ...payload };
      const response = fetch(`${userConfig.apiUrl}/i`, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json"
        },
        referrerPolicy: "no-referrer",
        body: JSON.stringify(props)
      }).catch(() => {});
      const response1 = fetch(`${userConfig.apiUrl}/identify`, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json"
        },
        referrerPolicy: "no-referrer",
        body: JSON.stringify(props)
      }).catch(() => {});
    },
    loaded: () => true,
    ready: () => {
      console.log("ready: exampleProviderPlugin");
    },
  };
}

const appCustomPlugin = {
  name: "appCustom",
  // Custom functions to expose to analytics instance
  methods: {
    movieSearch: (payload) => {
      // eslint-disable-next-line no-use-before-define
      const props = { ...getCommonProps(), ...payload, anonymousId, userId };
      const response = fetch(`${getAnalyticsUrl()}/ms`, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json"
        },
        referrerPolicy: "no-referrer",
        body: JSON.stringify(props)
      }).catch(() => {});
    },
    UserLocation: payload => {
      // eslint-disable-next-line no-use-before-define
      const props = { ...getCommonProps(), ...payload, anonymousId, userId };
      const response = fetch(`${getAnalyticsUrl()}/ul`, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
        },
        referrerPolicy: "no-referrer",
        body: JSON.stringify(props)
      }).catch(() => {});
    }
  }
};

/* initialize analytics and load plugins */
export const analytics = Analytics({
  debug: true,
  plugins: [
    googleAnalytics({
      trackingId: BUSINESS_CONFIG.GoogleAnalytics_TrackingId,
    }),
    ottoProviderPlugin({
      apiUrl: getAnalyticsUrl()
    }),
    appCustomPlugin,
  ]
});

const anonymousId = analytics.user().anonymousId;
const userId = analytics.user().userId;
window.Analytics = analytics;

/* export analytics for usage through the app */
export default analytics;