/* eslint-disable*/
import React, { useEffect, useState, useRef } from "react";
import {
  useIonViewDidEnter,
  useIonViewWillEnter,
  useIonViewWillLeave,
  isPlatform,
  getPlatforms,
} from "@ionic/react";
import {
  setShowHeader,
  setShowSideMenu,
  setShowTabs,
  setDefault,
} from "store/slices/appSlice";
import {
  Rewind10,
  Repeat,
  Fullscreen,
  Forward10,
} from "./Icons";
import { connect } from "react-redux";
import { ScreenOrientation } from "@ionic-native/screen-orientation";
import { StatusBar } from "@ionic-native/status-bar";
import "./VideoPlayerIosNative.scss";
import { useAnalytics } from "use-analytics";
import {
  TRACK_EVENT,
} from "analytics/analyticsHelper";
import { useHistory } from "react-router";
import Slider from "react-slick";
import { PageWrapperComponent } from "modules/common";
import { CastCardMobileComponent } from "modules/common";

const VideoPlayerIosNative = ({
  url,
  movieInfo,
  setShowHeaderAction,
  setShowSideMenuAction,
  setShowTabsAction,
  setDefaultAction,
  isMobile,
  sessionId
}) => {
  const history = useHistory();
  const { track, page } = useAnalytics();
  const [fullScreen, setFullScreen] = useState(false);
  const playerRef = useRef(null);
  const [isTimeout, setIstimeout] = useState(true);
  const trackData = useRef(null);
  // var volumeRange = document.getElementById('volumebar');
  // var seekbar = document.getElementById('seekbar');
  useIonViewWillEnter(() => {
    page(TRACK_EVENT.APP_USAGE.SCREEN.PLAYER_IOS);
    setShowHeaderAction(false);
    setShowSideMenuAction(false);
    setShowTabsAction(false);
  });

  useIonViewDidEnter(() => {
    if (!url) {
      history.replace("/");
      return;
    }
    trackData.current = {
      sessionId: sessionId,
      MovieId: movieInfo?.item?.id,
      SeasonId: movieInfo?.item?.season_id,
      CollectionId: movieInfo?.collection?.id,
      Title: movieInfo?.collection?.title,
      Type: movieInfo?.collection?.multipart ? 'Series' : 'Movie',
      Player: 'VideoPlayerIosNative.JS'
    };
  });

  useIonViewWillLeave(() => {
    try {
      track(
        TRACK_EVENT.PLAYER_EVENT.NAME,
        TRACK_EVENT.PLAYER_EVENT.getProps(
          TRACK_EVENT.PLAYER_EVENT.ACTION.EXIT,
          {...trackData.current}
        )
      );
      playerRef.current.pause();
      playerRef.current.removeAttribute("src"); // empty source
      playerRef.current.load();
    } catch (e) {}
    setDefaultAction();
    if (
      (isPlatform("ios") || isPlatform("android")) &&
      !isPlatform("desktop")
    ) {
      ScreenOrientation.lock(ScreenOrientation.ORIENTATIONS.PORTRAIT);
    }
  });

  const videoExitedFullscreen = () => {
    //check if an element is currently full screen
    if (
      document.fullScreenElement ||
      document.webkitIsFullScreen == true ||
      document.mozFullScreen ||
      document.msFullscreenElement
    ) {
      ScreenOrientation.lock(ScreenOrientation.ORIENTATIONS.LANDSCAPE);
      StatusBar.hide();
      setFullScreen(true);
    } else {
      ScreenOrientation.lock(ScreenOrientation.ORIENTATIONS.PORTRAIT);
      StatusBar.show();
      setFullScreen(false);
      var video = document.getElementById("player-ios");
      if(video) {
        video.pause();
      }
    }
  };

  useEffect(() => {
    var video = document.getElementById("player-ios");
    video.addEventListener(
      "webkitendfullscreen",
      function() {
        videoExitedFullscreen(video);
      },
      false
    );
  }, []);

  const playPause = () => {
    var myVideo = document.getElementById("player-ios");
    var btnIcon = document.getElementById("iconPlayPause");
    if (myVideo.paused) {
      myVideo.play();
      if (btnIcon !== null) {
        btnIcon.classList.remove("icon-play");
        btnIcon.classList.add("icon-pause");
      }
      if (!fullScreen) {
        myVideo.webkitEnterFullscreen();
        ScreenOrientation.lock(ScreenOrientation.ORIENTATIONS.LANDSCAPE);
        StatusBar.hide();
        setFullScreen(true);
      }
    } else {
      myVideo.pause();
      if (btnIcon !== null) {
        btnIcon.classList.remove("icon-pause");
        btnIcon.classList.add("icon-play");
      }
    }
    track(
      TRACK_EVENT.PLAYER_EVENT.NAME,
      TRACK_EVENT.PLAYER_EVENT.getProps(
        TRACK_EVENT.PLAYER_EVENT.ACTION.PLAYED_PAUSED,
        { ...trackData.current, url, Platforms: getPlatforms().join(","), movie: movieInfo }
      )
    );
  };

  const onRewind = () => {
    track(
      TRACK_EVENT.PLAYER_EVENT.NAME,
      TRACK_EVENT.PLAYER_EVENT.getClickProps(
        TRACK_EVENT.PLAYER_EVENT.CLICK.SEEK_10_MINUS,
        {  ...trackData.current, url, Platforms: getPlatforms().join(","), movie: movieInfo }
      )
    );
    var seekbar = document.getElementById("seekbar");
    var video = document.getElementById("player-ios");
    video.currentTime = video.currentTime - 10;
    seekbar.value = seekbar.value - 10;
  };
  const onForward = () => {
    track(
      TRACK_EVENT.PLAYER_EVENT.NAME,
      TRACK_EVENT.PLAYER_EVENT.getClickProps(
        TRACK_EVENT.PLAYER_EVENT.CLICK.SEEK_10_PLUS,
        {  ...trackData.current, url, Platforms: getPlatforms().join(","), movie: movieInfo }
      )
    );
    var seekbar = document.getElementById("seekbar");
    var video = document.getElementById("player-ios");
    video.currentTime = video.currentTime + 10;
    seekbar.value = seekbar.value + 10;
  };
  const onNext = () => {
    track(
      TRACK_EVENT.PLAYER_EVENT.NAME,
      TRACK_EVENT.PLAYER_EVENT.getClickProps(
        TRACK_EVENT.PLAYER_EVENT.CLICK.NEXT,
        { url }
      )
    );
  };
  const onPrevious = () => {
    track(
      TRACK_EVENT.PLAYER_EVENT.NAME,
      TRACK_EVENT.PLAYER_EVENT.getClickProps(
        TRACK_EVENT.PLAYER_EVENT.CLICK.PREVIOUS,
        { url }
      )
    );
  };
  const onRepeat = () => {
    track(
      TRACK_EVENT.PLAYER_EVENT.NAME,
      TRACK_EVENT.PLAYER_EVENT.getClickProps(
        TRACK_EVENT.PLAYER_EVENT.CLICK.REPEAT,
        { url, Platforms: getPlatforms().join(","), movie: movieInfo }
      )
    );
    var seekbar = document.getElementById("seekbar");
    var video = document.getElementById("player-ios");
    video.currentTime = 0;
    seekbar.value = 0;
  };
  const onFullScreen = (e) => {
    track(
      TRACK_EVENT.PLAYER_EVENT.NAME,
      TRACK_EVENT.PLAYER_EVENT.getClickProps(
        TRACK_EVENT.PLAYER_EVENT.CLICK.FULLSCREEN,
        {  ...trackData.current, url, Platforms: getPlatforms().join(","), movie: movieInfo }
      )
    );
    var myVideo = document.getElementById("player-ios");
    if (!fullScreen) {
      myVideo.webkitEnterFullscreen();
      ScreenOrientation.lock(ScreenOrientation.ORIENTATIONS.LANDSCAPE);
      StatusBar.hide();
    } else {
      myVideo.webkitExitFullscreen();
      ScreenOrientation.lock(ScreenOrientation.ORIENTATIONS.PORTRAIT);
      StatusBar.show();
    }
  };
  const onMute = () => {
    var video = document.getElementById("player-ios");
    var volumeRange = document.getElementById("volumebar");
    track(
      TRACK_EVENT.PLAYER_EVENT.NAME,
      TRACK_EVENT.PLAYER_EVENT.getClickProps(
        TRACK_EVENT.PLAYER_EVENT.CLICK.MUTE,
        {  ...trackData.current, url, Platforms: getPlatforms().join(","), movie: movieInfo }
      )
    );
    if (video.muted) {
      video.muted = false;
      volumeRange.value = video.volume;
    } else {
      video.muted = true;
      volumeRange.value = 0;
    }
  };

  // fires when seekbar is changed
  const ChangeTheTime = () => {
    var seekbar = document.getElementById("seekbar");
    var video = document.getElementById("player-ios");
    video.currentTime = seekbar.value;
  };

  const UpdateTheTime = () => {
    var seekbar = document.getElementById("seekbar");
    var video = document.getElementById("player-ios");
    var sec = video.currentTime;
    var h = Math.floor(sec / 3600);
    sec = sec % 3600;
    var min = Math.floor(sec / 60);
    sec = Math.floor(sec % 60);
    if (sec.toString().length < 2) sec = "0" + sec;
    if (min.toString().length < 2) min = "0" + min;

    var secDu = video.duration;
    var hDu = Math.floor(secDu / 3600);
    secDu = secDu % 3600;
    var minDu = Math.floor(secDu / 60);
    secDu = Math.floor(secDu % 60);
    if (secDu.toString().length < 2) secDu = "0" + secDu;
    if (minDu.toString().length < 2) minDu = "0" + minDu;

    document.getElementById("videoTime").innerHTML = h + ":" + min + ":" + sec;
    document.getElementById("videoDuration").innerHTML =
      hDu + ":" + minDu + ":" + secDu;

    seekbar.min = video.startTime || 0;
    seekbar.max = video.duration;
    seekbar.value = video.currentTime;
  };

  const SetSeekBar = () => {
    var seekbar = document.getElementById("seekbar");
    var video = document.getElementById("player-ios");
    seekbar.min = 0;
    seekbar.max = video.duration;
  };

  const handlePlayPauseButton = () => {
    var myVideo = document.getElementById("player-ios");
      var btnIcon = document.getElementById("iconPlayPause");
      if (myVideo.paused) {
        if (btnIcon !== null) {
          btnIcon.classList.remove("icon-pause");
          btnIcon.classList.add("icon-play");
        }
      } else {
        if (btnIcon !== null) {
          btnIcon.classList.remove("icon-play");
          btnIcon.classList.add("icon-pause");
        }
      }
  }

  const addMyListeners = () => {
    var myVideo = document.getElementById("player-ios");
    // myVideo.addEventListener("timeupdate", UpdateTheTime, false);
    // myVideo.addEventListener("durationchange", SetSeekBar, false);
    // myVideo.addEventListener('waiting', (event) => {
    //   setShowLoader(false);
    // });
    // myVideo.addEventListener('playing', (event) => {
    //   setShowLoader(false);
    // });
    // myVideo.onplaying = (event) => {
    //   setShowLoader(false);
    // };
    myVideo.addEventListener('play', (event) => {
      handlePlayPauseButton();
      
    });
    myVideo.addEventListener('pause', (event) => {
      handlePlayPauseButton();
    });
  };

  const ChangeVolume = () => {
    var video = document.getElementById("player-ios");
    var volumeRange = document.getElementById("volumebar");
    var myVol = volumeRange.value;
    video.volume = myVol;
    if (myVol == 0) {
      video.muted = true;
    } else {
      video.muted = false;
    }
  };

  const mouseMoveEvent = () => {
    try {
      if (isTimeout) {
        var timer;
        var fadeInBuffer = false;
        if (!fadeInBuffer && timer) {
          clearTimeout(timer);
          timer = 0;
          document.body.style.cursor = "";
        } else {
          document.body.style.cursor = "default";
          document.getElementById("customControlios").style.opacity = 1;
          document.getElementById("videoControllerios").style.opacity = 1;
          fadeInBuffer = false;
        }

        timer = setTimeout(function() {
          try {
            document.body.style.cursor = "none";
            document.getElementById("customControlios").style.opacity = 0;
            document.getElementById("videoControllerios").style.opacity = 0;
            fadeInBuffer = true;
            setIstimeout(true);
          } catch (e) {}
        }, 4000);
        setIstimeout(false);
      }
    } catch (e) {}
  };

  const settings = {
    lazyLoad: true,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <PageWrapperComponent hideHeader hideFooter>
          <div className="dash-video-player-ios" onMouseMove={mouseMoveEvent}>
            {/* <!-- HTML structure needed for the ControlBar --> */}

            <div className="videoContainerIos" id="videoContainerIos">
              <div className="videocontainerinner">
                <div id="customControlios">
                  { false &&
                  <>
                  <div
                    id="rewindBtn"
                    className="btn-rewind"
                    title="Rewind"
                    onClick={() => {
                      onRewind();
                    }}
                  >
                    <span id="iconrewind" className="icon-rewind">
                      <Rewind10 />
                      <span className="icon-forward10"></span>
                    </span>
                  </div>
                  
                  <div
                    id="forwardBtn"
                    className="btn-forward"
                    title="Forward"
                    onClick={() => {
                      onForward();
                    }}
                  >
                    <span id="iconforward" className="icon-forward">
                      <Forward10 />
                    </span>
                  </div>
                
                </>
                  }
                  <div
                    id="playPauseBtn"
                    className="btn-play-pause"
                    title="Play/Pause"
                    onClick={() => {
                      playPause();
                    }}
                  >
                    <span id="iconPlayPause" className="icon-play"></span>
                  </div>
                  </div>
                <video
                  onLoadedMetadata={() => addMyListeners()}
                  className="videoElement"
                  id="player-ios"
                  controls={false}
                  preload="auto"
                  playsInline={true}
                  autoPlay={false}
                  ref={playerRef}
                  poster={movieInfo?.item?.thumbnails?.[0]}
                >
                  <source
                    src={url}
                    // type="application/x-mpegURL"
                  />
                </video>
              { false &&
                <div
                  id="videoControllerios"
                  className="video-controller unselectable"
                >
                  <div className="seekgrid">
                    <div className="seekContainer">
                      <input
                        className="seekbar seekbar-complete"
                        type="range"
                        step="any"
                        id="seekbar"
                        value="0"
                        onChange={() => ChangeTheTime()}
                      />
                      {/* <div
                      className="seekbar seekbar-complete"
                      id="seekbar"
                      onChange={() => ChangeTheTime()}
                    >
                      <div
                        id="seekbar-buffer"
                        className="seekbar seekbar-buffer"
                      ></div>
                      <div
                        id="seekbar-play"
                        className="seekbar seekbar-play"
                      ></div>
                    </div> */}
                    </div>
                    <span id="videoTime" className="time-display">
                      00:00:00
                    </span>
                    <span id="videoDuration" className="duration-display">
                      00:00:00
                    </span>
                  </div>
                  <div
                    id="fullscreenBtn"
                    className="btn-fullscreen control-icon-layout"
                    title="Fullscreen"
                    onClick={() => {
                      onFullScreen();
                    }}
                  >
                    <Fullscreen />
                  </div>
                  <div className="next-prev-wrap">
                    {/* <div
                    id="prev-btn"
                    className="btn-prev control-icon-layout"
                    title="Previous"
                    onClick={() => {
                      onPrevious();
                    }}
                  >
                    <Prev />
                  </div> */}
                    <div
                      id="repeat-btn"
                      className="btn-repeat control-icon-layout"
                      title="Repeat"
                      onClick={() => {
                        onRepeat();
                      }}
                    >
                      <Repeat />
                    </div>
                    {/* <div
                    id="next-btn"
                    className="btn-next control-icon-layout"
                    title="Next"
                    onClick={() => {
                      onNext();
                    }}
                  >
                    <Next />
                  </div> */}
                  </div>
                  {/* <div className="volume-wrap">
                  <div
                    id="muteBtn"
                    className="btn-mute control-icon-layout"
                    title="Mute"
                    onClick={() => {
                      onMute();
                    }}
                  >
                    <span id="iconMute" className="icon-mute-off"></span>
                  </div>
                  <input
                    type="range"
                    min="0"
                    max="1"
                    step="0.1"
                    id="volumebar"
                    className="volumebar"
                    onChange={() => ChangeVolume()}
                  />
                  <input type="range" id="volumebar" className="volumebar" value="1" min="0" max="1" step=".01" />
                </div> */}
                  {/* <div id="trackSwitchBtn" className="control-icon-layout" title="A/V Tracks">
										<span className="icon-tracks"></span>
									</div>
									<div id="captionBtn" className="btn-caption control-icon-layout" title="Closed Caption">
										<span className="icon-caption"></span>
									</div> */}
                </div>
              }
              </div>
            </div>
          </div>

          {movieInfo && isMobile && (
            <div>
              <div></div>
              <div className="movieInfoContainer">
                <div className="font-bold">
                  <div className="titleBlock">
                    <div>
                      <h2 className="title">{movieInfo?.collection?.title}</h2>
                      <ul className="movie-details-wrap">
                        <li className="movie-details-list">
                          {movieInfo?.item?.duration}
                        </li>
                        <li className="movie-details-list">
                          {movieInfo?.collection?.rating}
                        </li>
                        <li className="movie-details-list">English</li>
                        <li className="movie-details-list">
                          {movieInfo?.collection?.genre}
                        </li>
                        <li className="movie-details-list">
                          {movieInfo?.item?.rating_tags}
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="descriptionBlock">
                    <p>{movieInfo?.collection?.description}</p>
                    <div className="cast-card-wrap">
                      <h5 className="cast-title">Cast:</h5>
                      <div className="cast-card-slider-wrap">
                        <Slider {...settings}>
                          {movieInfo?.artists?.map((actor, key) => (
                            <CastCardMobileComponent key={key} actor={actor} />
                          ))}
                        </Slider>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </PageWrapperComponent>
    </>
  );
};

const mapStateToProps = ({ payment, app }) => ({
  isMobile: app?.isMobile,
  url: payment?.playerUrl?.url,
  sessionId: payment?.playerUrl?.sessionId,
  movieInfo: payment?.movieInfo,
});

const mapDispatchToProps = {
  setShowHeaderAction: setShowHeader,
  setShowSideMenuAction: setShowSideMenu,
  setShowTabsAction: setShowTabs,
  setDefaultAction: setDefault,
};

export default connect(mapStateToProps, mapDispatchToProps)(VideoPlayerIosNative);
