import React, { useState } from "react";
import "./SkeletonLoader.scss";

const SkeletonLoader = ({ srcName }) => {
  const [isImgLoaded, setIsImgLoaded] = useState();
  const [noImage, setNoImage] = useState(false);

  return (
    <>
      {!isImgLoaded && !noImage && <div className="skeletonback">
        <div className="box-flip" />
      </div>
      }
      { noImage && 
          <img
          className={`cardimg movie-card-long-img view`}
          src={'/img/NoImage.png'}
          alt="no-image"
        />
      }
      { !noImage && <img
        className={`cardimg movie-card-long-img${isImgLoaded ? ' view' : ''}`}
        onLoad={() => setIsImgLoaded(true)}
        onError={() => setNoImage(true)}
        src={srcName}
        alt="poster"
      />
}
    </>
  );
};

export default SkeletonLoader;