/* eslint-disable*/
import React, { useEffect, useRef } from "react";
import {
  IonPage,
  IonContent,
  useIonViewDidEnter,
  useIonViewWillEnter,
  useIonViewWillLeave,
  isPlatform,
  getPlatforms
} from "@ionic/react";
import {
  setShowHeader,
  setShowSideMenu,
  setShowTabs,
  setDefault,
} from "store/slices/appSlice";
import { connect } from "react-redux";
import { ScreenOrientation } from "@ionic-native/screen-orientation";
import "./VideoPlayerIosSafari.scss";
import { useAnalytics } from "use-analytics";

import { PLAYER_EVENT, TRACK_EVENT } from "analytics/analyticsHelper";
import { useHistory } from "react-router";
import Slider from "react-slick";
import { PageWrapperComponent } from "modules/common";
import { CastCardMobileComponent } from "modules/common";

const VideoPlayerIosSafari = ({
  url,
  movieInfo,
  setShowHeaderAction,
  setShowSideMenuAction,
  setShowTabsAction,
  setDefaultAction,
  isMobile,
  sessionId
}) => {
  const history = useHistory();
  const { track, page, identify } = useAnalytics();
  const playerRef = useRef(null);
  const trackData = useRef(null);

  // var volumeRange = document.getElementById('volumebar');
  // var seekbar = document.getElementById('seekbar');
  useEffect(() => {
    page(TRACK_EVENT.APP_USAGE.SCREEN.PLAYER_IOS);
    setShowHeaderAction(false);
    setShowSideMenuAction(false);
    setShowTabsAction(false);
    if (!url) {
      history.push("/");
    }
    trackData.current = {
      sessionId: sessionId,
      MovieId: movieInfo?.item?.id,
      SeasonId: movieInfo?.item?.season_id,
      CollectionId: movieInfo?.collection?.id,
      Title: movieInfo?.collection?.title,
      Type: movieInfo?.collection?.multipart ? 'Series' : 'Movie',
      Player: 'VideoPlayerIosSafari.JS'
    };
    return () => {
      try {
        track(
          TRACK_EVENT.PLAYER_EVENT.NAME,
          TRACK_EVENT.PLAYER_EVENT.getProps(
            TRACK_EVENT.PLAYER_EVENT.ACTION.EXIT,
            {...trackData.current}
          )
        );
        playerRef.current.pause();
        playerRef.current.removeAttribute("src"); // empty source
        playerRef.current.load();
      } catch (e) {}
      setDefaultAction();
      if (
        (isPlatform("ios") || isPlatform("android")) &&
        !isPlatform("desktop")
      ) {
        ScreenOrientation.lock(ScreenOrientation.ORIENTATIONS.PORTRAIT);
      }
    };
  }, []);

  useEffect(() => {
    let videoElement = document.getElementById("player-ios");
    var data = {
      url: url,
      Platforms: getPlatforms().join(","),
    }
      videoElement.addEventListener('ended', function(event) {
        
        track(
          TRACK_EVENT.PLAYER_EVENT.NAME,
          TRACK_EVENT.PLAYER_EVENT.getProps(
            TRACK_EVENT.PLAYER_EVENT.ACTION.ENDED,
            {currentTime: videoElement?.currentTime, ...data, ...trackData.current }
          )
        );
      });
      videoElement.addEventListener('loadeddata', function(event) {
        
        track(
          TRACK_EVENT.PLAYER_EVENT.NAME,
          TRACK_EVENT.PLAYER_EVENT.getProps(
            TRACK_EVENT.PLAYER_EVENT.ACTION.INITILIZED,
            {...data, currentTime: videoElement?.currentTime, ...trackData.current }
          )
        );
      });
      videoElement.addEventListener('seeked', function(event) {
        
        track(
          TRACK_EVENT.PLAYER_EVENT.NAME,
          TRACK_EVENT.PLAYER_EVENT.getProps(
            TRACK_EVENT.PLAYER_EVENT.ACTION.SEEK,
            {...data, currentTime: videoElement?.currentTime, ...trackData.current }
          )
        );
      });
  },[]);

  const settings = {
    lazyLoad: true,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <PageWrapperComponent hideHeader hideFooter pageClass="video-player">
        <div className="dash-video-player-safari">
          {/* <!-- HTML structure needed for the ControlBar --> */}

          <div className="videoContainerSafari" id="videoContainerSafari">
            <div className="videocontainerinner">
              <video
                // onLoadedMetadata={() => addMyListeners()}
                className="videoElement"
                id="player-ios"
                controls
                preload="auto"
                playsInline={true}
                autoPlay={false}
                ref={playerRef}
                poster={movieInfo?.item?.thumbnails?.[0]}
              >
                <source
                  src={url}
                  // type="application/x-mpegURL"
                />
              </video>
            </div>
          </div>
        </div>

        {movieInfo && isMobile && (
          <div>
            <div></div>
            <div className="movieInfoContainer">
              <div className="font-bold">
                <div className="titleBlock">
                  <div>
                    <h2 className="title">{movieInfo?.collection?.title}</h2>
                    <ul className="movie-details-wrap">
                      <li className="movie-details-list">
                        {movieInfo?.item?.duration}
                      </li>
                      <li className="movie-details-list">
                        {movieInfo?.collection?.rating}
                      </li>
                      <li className="movie-details-list">English</li>
                      <li className="movie-details-list">
                        {movieInfo?.collection?.genre}
                      </li>
                      <li className="movie-details-list">
                        {movieInfo?.item?.rating_tags}
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="descriptionBlock">
                  <p>{movieInfo?.collection?.description}</p>
                  <div className="cast-card-wrap">
                    <h5 className="cast-title">Cast:</h5>
                    <div className="cast-card-slider-wrap">
                      <Slider {...settings}>
                        {movieInfo?.artists?.map((actor, key) => (
                          <CastCardMobileComponent key={key} actor={actor} />
                        ))}
                      </Slider>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </PageWrapperComponent>
    </>
  );
};

const mapStateToProps = ({ payment, app }) => ({
  isMobile: app?.isMobile,
  url: payment?.playerUrl?.url,
  sessionId: payment?.playerUrl?.sessionId,
  movieInfo: payment?.movieInfo,
});

const mapDispatchToProps = {
  setShowHeaderAction: setShowHeader,
  setShowSideMenuAction: setShowSideMenu,
  setShowTabsAction: setShowTabs,
  setDefaultAction: setDefault,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VideoPlayerIosSafari);
