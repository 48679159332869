import React from "react";
import { isPlatform, useIonAlert } from "@ionic/react";
import { getVideoUrl } from "actions/services";
import { TRACK_EVENT } from "analytics/analyticsHelper";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { ROUTES } from "helpers/routeHelper";
import { browserName } from "react-device-detect";
import { v4 as uuidv4 } from "uuid";
import { CONTENT_WATCH_WARNING } from "clientConfig";
import { setPlayerUrl } from "store/slices/paymentSlice";
import { useAnalytics } from "use-analytics";

const PlayButton = ({ movieId, collectionId, collectionItemCombo, setPlayerUrlAction, playerState, children }) => {
  const [present] = useIonAlert();
  const { track } = useAnalytics();
  const history = useHistory();
  const handleContentWarning = () => {
    const warning = CONTENT_WATCH_WARNING.find(
      (w) => w.id === collectionItemCombo?.collection?.rating
    );
    if (warning) {
      present({
        cssClass: "my-css",
        header: warning.header,
        message: warning.message,
        buttons: [
          "No",
          {
            text: "Yes",
            handler: (d) => {
              onWatchClick(movieId, collectionId);
            },
          },
        ],
        onDidDismiss: (e) => {},
      });
    } else {
      onWatchClick();
    }
  };

  const onWatchClick = () => {
    getVideoUrl(
      movieId,
      isPlatform("ios"),
      isPlatform("mobileweb"),
      browserName
    )
      .then((res) => {
        const data = res?.data?.data;
        if (res?.data?.success && data?.url && data.status === "verified") {
          setPlayerUrlAction(
            { url: data?.url, movieId: movieId, sessionId: uuidv4() },
            collectionItemCombo,
            playerState
          );
          history.push(ROUTES.PLAYER);
        } else {
          history.push(
            ROUTES.WATCH.replace(":movieId", movieId).replace(
              ":collectionId",
              collectionId
            )
          );
        }
      })
      .catch((err) => {
        if (err?.response?.status === 402) {
          history.replace(
            ROUTES.WATCH.replace(":movieId", movieId).replace(
              ":collectionId",
              collectionId
            )
          );
        }
      })
      .finally();
    track(
      TRACK_EVENT.APP_USAGE.NAME,
      TRACK_EVENT.APP_USAGE.getClickProps(
        TRACK_EVENT.APP_USAGE.CLICK_OPTIONS.WATCH_NOW,
        TRACK_EVENT.APP_USAGE.SCREEN.MOVIE_INFO,
        {
          MovieId: movieId,
          CollectionId: collectionId,
          Title: collectionItemCombo?.collection?.title,
          Type: collectionItemCombo?.collection?.multipart ? "Series" : "Movie",
        }
      )
    );
  };

  return (
    <div onClick={() => handleContentWarning()}>{children}</div>
  );
};

const mapStateToProps = ({ payment, persist }) => {
  return {};
};
const mapDispatchToProps = {
  setPlayerUrlAction: setPlayerUrl,
};

export default connect(mapStateToProps, mapDispatchToProps)(PlayButton);
