import { configureStore, getDefaultMiddleware, combineReducers } from "@reduxjs/toolkit";
import logger from "redux-logger";
import userReducer from "./slices/userSlice";
import appReducer from "./slices/appSlice";
import paymentReducer from "./slices/paymentSlice";
import PersistReducer from  "./slices/persistSlice/persistSlice";
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import persistStore from "redux-persist/es/persistStore";

const middleware = [...getDefaultMiddleware({
  serializableCheck: false,
}),
];

const persistPersistConfig = {
  key: 'persist',
  storage,
  // whitelist: ['featuredMovies', 'homeCarousel']
}

const rootReducer = combineReducers({
  user: userReducer,
  app: appReducer,
  payment: paymentReducer,
  persist: persistReducer(persistPersistConfig, PersistReducer),
});

const store = configureStore({
  reducer: rootReducer,
  middleware
});

export const persistor = persistStore(store);
export default store;