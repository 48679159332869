import { createSlice } from "@reduxjs/toolkit";

export const appSlice = createSlice({
  name: "app",
  initialState: {
    lastRoute: "/",
    showHeader: true,
    showTabs: true,
    showSideMenu: true,
    plateforms: [],
    currentRoute: "/",
    isMobile: null,
    fcmRegistrationToken: "",
    isNative: false,
  },
  reducers: {
    saveFcmRegistrationToken: (state, action) => {
      state.fcmRegistrationToken = action.payload;
    },
    saveLastRoute: (state, action) => {
      state.lastRoute = action.payload;
    },
    saveCurrentRoute: (state, action) => {
      state.currentRoute = action.payload;
    },
    saveShowHeader: (state, action) => {
      state.showHeader = action.payload;
    },
    saveShowTabs: (state, action) => {
      state.showTabs = action.payload;
    },
    saveShowSideMenu: (state, action) => {
      state.showSideMenu = action.payload;
    },
    saveIsNative: (state, action) => {
      state.isNative = action.payload;
    },
    saveDefault: (state, action) => {
      if (action.payload?.isNative) {
        state.isNative = action.payload?.isNative;
      }
      let plateforms = state.plateforms;
      if (action.payload?.initial) {
        plateforms = action.payload?.plateforms;
        state.plateforms = plateforms;
      }
      state.isMobile = plateforms?.includes("mobile");
      if (plateforms?.includes("desktop")) {
        state.showSideMenu = false;
        state.showTabs = false;
        state.showHeader = true;
      } else if (plateforms?.includes("mobile")) {
        state.showSideMenu = true;
        state.showTabs = true;
        state.showHeader = true;
      } else {
        state.showSideMenu = true;
        state.showTabs = true;
        state.showHeader = true;
      }
    },
  },
});

const {
  saveLastRoute,
  saveShowHeader,
  saveShowSideMenu,
  saveShowTabs,
  saveDefault,
  saveCurrentRoute,
  saveFcmRegistrationToken,
  saveIsNative,
} = appSlice.actions;

export const setLastRoute = (path) => (dispatch) => {
  dispatch(saveLastRoute(path));
};
export const setIsNative = (isNative) => (dispatch) => {
  dispatch(saveIsNative(isNative));
};
export const setCurrentRoute = (path) => (dispatch) => {
  dispatch(saveCurrentRoute(path));
};
export const setShowHeader = (val) => (dispatch) => {
  dispatch(saveShowHeader(val));
};
export const setShowSideMenu = (val) => (dispatch) => {
  dispatch(saveShowSideMenu(val));
};
export const setShowTabs = (val) => (dispatch) => {
  dispatch(saveShowTabs(val));
};
export const setDefault = (val) => (dispatch) => {
  dispatch(saveDefault(val));
};
export const setFcmRegistrationToken = (val) => (dispatch) => {
  dispatch(saveFcmRegistrationToken(val));
};

export default appSlice.reducer;
