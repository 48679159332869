import React, { useEffect, useState } from "react";
import "./StaticPagesMobile.scss";
import { useRouteMatch } from "react-router";
import {
    IonButton,
    IonPage,
} from "@ionic/react";
import { BUSINESS_CONFIG,TERMS_OF_SERVICE } from "../../../../clientConfig";
import { setPageTitle } from "helpers/seoHelper";
import { PageWrapperComponent } from "modules/common";
import { HeaderComponent } from "modules/common";
 
const TermsOfService = () => {
    useEffect(() => {
        setPageTitle("Terms Of Service");
    }, []);
 
 
    const getTitle = () => {
        return "Terms Of Service"
    }
    return (
        <>
            <IonPage className="ionPage">
                <HeaderComponent pageTitle={getTitle()}  hideSearch hideLogo={true} hideMenuButton />
                <PageWrapperComponent>
                    <div className="body-text">{TERMS_OF_SERVICE}</div>
                </PageWrapperComponent>
            </IonPage>
        </>
    )
}
export default TermsOfService;
 
