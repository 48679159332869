export const homeSkeleton = {
  featured: Array(1)
    .fill(1)
    .map(x => ({
      name: "Skeleton",
      values: Array(2)
        .fill(1)
        .map(y => ({
          id: y
        }))
    })),
  list: Array(4)
    .fill(5)
    .map(x => ({
      name: "Skeleton",
      values: Array(7)
        .fill(1)
        .map(y => ({
          id: y
        }))
    }))
};
export const movieSkeleton = {
  featured: Array(1)
    .fill(1)
    .map(x => ({
      name: "Skeleton",
      values: Array(3)
        .fill(1)
        .map(y => ({
          id: y
        }))
    })),
  list: Array(4)
    .fill(2)
    .map(x => ({
      name: "Skeleton",
      values: Array(7)
        .fill(1)
        .map(y => ({
          id: y
        }))
    }))
};

export const seriesSkeleton = {
  featured: Array(1)
    .fill(1)
    .map(x => ({
      name: "Skeleton",
      values: Array(3)
        .fill(1)
        .map(y => ({
          id: y
        }))
    })),
  list: Array(4)
    .fill(2)
    .map(x => ({
      name: "Skeleton",
      values: Array(7)
        .fill(1)
        .map(y => ({
          id: y
        }))
    }))
};
