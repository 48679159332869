import React, { useState, useEffect } from "react";
import {
  searchOutline,
  settingsOutline,
  cardOutline,
  timeOutline,
  arrowBack,
} from "ionicons/icons";
import {
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonMenuButton,
  IonItem,
  IonSearchbar,
  IonPopover,
  IonList,
  IonListHeader,
  IonIcon,
  IonBackButton,
} from "@ionic/react";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import "./Header.scss";
import { ROUTES } from "helpers/routeHelper";
import { FormattedMessage } from "react-intl";
import localStore from "utils/localStore";
import { saveUserDetails } from "store/slices/userSlice";
import {
  APP_LINK,
  FILTER_TYPE,
  MOVIE_SUB_TYPES,
  SHOP_URL,
  SHOW_HOME_FILTER,
  SHOW_TABS,
  SMALL_LOGO,
  LOGO,
} from "../../../../clientConfig";
import Avatar from "react-avatar";
import { Capacitor } from "@capacitor/core";
import { isPlatform } from "@ionic/core";
import { FILTER_TYPE_CODE } from "constants/constants";
import { useAnalytics } from "use-analytics";
import { TRACK_EVENT } from "analytics/analyticsHelper";
import analytics from "analytics/analytics";

const Header = ({
  isMobile,
  isAuthUser,
  email,
  fullName,
  saveUserDetailsAction,
  hideSearch,
  pageTitle,
  hideMenuButton,
  hideLogo,
  hideBack,
}) => {
  const { track, page } = useAnalytics();
  const history = useHistory();
  const [showSearchBar, setShowSearchBar] = useState(false);
  const [showDownloadHeader, setShowDownloadHeader] = useState(() => {
    const isShowed = sessionStorage.getItem("downloadBannerShowed");
    return isShowed != "1";
  });
  const [showPopover, setShowPopover] = useState({
    open: false,
    event: undefined,
  });
  const [searchText, setSearchText] = useState("");

  const onLogout = () => {
    localStore.deauthenticateUser();
    saveUserDetailsAction(null);
    window.location.reload();
  };

  const enterPressed = (event) => {
    let code = event.keyCode || event.which;
    if (searchText && (code === 13 || event.key === "Enter") ) {
      setShowSearchBar(false);
      track(
        TRACK_EVENT.APP_USAGE.NAME,
        TRACK_EVENT.APP_USAGE.getClickProps(
          TRACK_EVENT.APP_USAGE.CLICK_OPTIONS.SEARCH,
          "header",
          { SearchText: searchText }
        )
      );
      analytics.plugins.appCustom.movieSearch({
        SearchText: searchText,
        Screen: "header",
      });
      history.push(ROUTES.SEARCH.replace(":searchKey", searchText));
    }
  };

  const openAppUrl = () => {
    track(
      TRACK_EVENT.APP_USAGE.NAME,
      TRACK_EVENT.APP_USAGE.getClickProps(
        TRACK_EVENT.APP_USAGE.CLICK_OPTIONS.DOWNLOAD_APP,
        "header",
        {}
      )
    );
    sessionStorage.setItem("downloadBannerShowed", "1");
    if (isPlatform("ios")) {
      window.location.href = APP_LINK.IOS;
    } else if (isPlatform("android")) {
      window.location.href = APP_LINK.ANDROID;
    }
  };

  const onCloseBanner = () => {
    sessionStorage.setItem("downloadBannerShowed", "1");
    setShowDownloadHeader(false);
    track(
      TRACK_EVENT.APP_USAGE.NAME,
      TRACK_EVENT.APP_USAGE.getClickProps(
        TRACK_EVENT.APP_USAGE.CLICK_OPTIONS.CLOSE_DOWNLOAD_APP_BANNER,
        "header",
        {}
      )
    );
  };

  return (
    <>
      {/* {isPlatform("mobileweb") &&
        (isPlatform("ios") || isPlatform("android")) &&
        showDownloadHeader && (
          <IonHeader className="download-app-bar d-flex">
            <div className="app-bar-left">
              <div className="close-icon" onClick={onCloseBanner}></div>
              <div className="app-bar-logo">
                <img src={SMALL_LOGO} alt="" />
              </div>
              <div className="app-bar-text">
                <div className="main-text">Download Our App</div>
                <div className="semi-text">For a Better experience</div>
              </div>
            </div>
            <div className="app-bar-right" onClick={openAppUrl}>
              <span className="forward-icon"></span>
            </div>
          </IonHeader>
        )} */}
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            {/* {!hideMenuButton && <IonMenuButton color="primary"></IonMenuButton>} */}
            {!hideLogo && (
              <Link
                className="logolink"
                to={ROUTES.HOME}
                routerDirection="forward"
              >
                {/* <IonTitle>{APP_HEADER_TITLE}</IonTitle> */}
                <img
                  className="logoimg"
                  src= {LOGO}
                  alt="logo"
                />
              </Link>
            )}
            {!hideBack && (
              <>
                <Link className="back-button" to={ROUTES.HOME}>
                  <IonIcon icon={arrowBack} />
                </Link>
                <div className="page-title">{pageTitle} </div>
              </>
            )}
            {!isMobile && (
              <>
                {FILTER_TYPE == FILTER_TYPE_CODE.SUBTYPE &&
                  SHOW_HOME_FILTER &&
                  MOVIE_SUB_TYPES.map((subType) => (
                    <Link
                      routerDirection="forward"
                      to={
                        ROUTES.MOVIES_LIST.replace(":filter", subType.value) +
                        `?filter=${subType.value}`
                      }
                    >
                      {subType.label}
                    </Link>
                  ))}
                {SHOW_TABS.SERIES_LIST && (
                  <Link to={ROUTES.SERIES_LIST} routerDirection="forward">
                    <FormattedMessage id="tab.Webseries" />
                  </Link>
                )}
                {SHOW_TABS.MOVIES_LIST && (
                  <Link to={ROUTES.MOVIES} routerDirection="forward">
                    <FormattedMessage id="tab.Movies" />
                  </Link>
                )}
                {SHOW_TABS.SHOP && (
                  <Link onClick={() => window.open(SHOP_URL, "_blank")}>
                    <FormattedMessage id="header.Shop" />
                  </Link>
                )}

                {/* <Link to={ROUTES.SETTINGS} routerDirection="forward">
                  <FormattedMessage id="header.Settings" />
                </Link> */}
              </>
            )}
          </IonButtons>
          <IonButtons color="primary" slot="end">
            {!isMobile && (
              <>
                <IonPopover
                  isOpen={showPopover.open}
                  event={showPopover.event}
                  onDidDismiss={(e) =>
                    setShowPopover({ open: false, event: undefined })
                  }
                >
                  <IonList lines="none">
                    <div className="user-details-wrap">
                      <Avatar
                        color={Avatar.getRandomColor("sitebase", ["red"])}
                        size="36px"
                        round={true}
                        name={isAuthUser ? fullName : "Guest"}
                      />
                      <div className="user-details">
                        <IonListHeader>
                          {isAuthUser ? fullName : "Guest"}
                        </IonListHeader>
                        <div className="email">{email}</div>
                      </div>
                    </div>
                    {isAuthUser ? (
                      <>
                        <div
                          className="item-wrap"
                          onClick={() => {
                            setShowPopover({ open: false, event: undefined });
                          }}
                        >
                          <IonIcon icon={settingsOutline} />
                          <Link
                            to={ROUTES.USER_PROFILE}
                            routerDirection="forward"
                          >
                            <IonItem no-lines button>
                              {" "}
                              <FormattedMessage id="header.MyAccount" />{" "}
                            </IonItem>
                          </Link>
                        </div>
                        <div
                          className="item-wrap"
                          onClick={() => {
                            history.push(ROUTES.WATCH_HISTORY);
                            setShowPopover({ open: false, event: undefined });
                          }}
                        >
                          <IonIcon icon={timeOutline} />
                          <IonItem button>
                            <span>
                              <FormattedMessage id="header.WatchHistory" />
                            </span>
                          </IonItem>
                        </div>
                        {/* <div
                          className="item-wrap"
                          onClick={() => {
                            history.replace(ROUTES.PAYMENT_HISTORY);
                            setShowPopover({ open: false, event: undefined });
                          }}
                        >
                          <IonIcon icon={cardOutline} />
                          <IonItem button>
                            <span>
                              <FormattedMessage id="header.Payments" />
                            </span>
                          </IonItem>
                        </div> */}
                        <IonItem
                          button
                          onClick={() => {
                            onLogout();
                            setShowPopover({ open: false, event: undefined });
                          }}
                        >
                          <FormattedMessage id="header.Logout" />
                        </IonItem>
                      </>
                    ) : (
                      <>
                        <IonItem
                          button
                          onClick={() => {
                            history.replace(ROUTES.AUTH);
                            setShowPopover({ open: false, event: undefined });
                          }}
                        >
                          <span>Sign In</span>
                        </IonItem>
                      </>
                    )}
                  </IonList>
                </IonPopover>
                <div
                  className="profile-details"
                  // onClick={(e) =>
                  //   setShowPopover({ open: true, event: e.nativeEvent })
                  // }
                  onClick={() => history.replace(ROUTES.USER_PROFILE)}
                >
                  <Avatar
                    color="#EB1E4E"
                    size="36px"
                    round={true}
                    name={isAuthUser ? fullName : "Guest"}
                  />
                </div>
              </>
            )}
            
            {isMobile && !hideSearch && (
              <>
              <IonIcon
                icon={searchOutline}
                onClick={() => setShowSearchBar(!showSearchBar)}
                ></IonIcon>
                {/* <Avatar
                  color="#EB1E4E"
                  size="32px"
                  round={true}
                  onClick={() => history.replace(ROUTES.USER_PROFILE)}
                  name={isAuthUser ? fullName : "Guest"}
                /> */}
                {/* <IonMenuButton color="primary">
                  <Avatar
                    size="32px"
                    round={true}
                    name={isAuthUser ? fullName : "Guest"}
                  />
                </IonMenuButton> */}
              </>
            )}
            {isMobile && !hideMenuButton && (
              <>
                <IonMenuButton color="primary">
                  <Avatar
                    size="32px"
                    round={true}
                    name={isAuthUser ? fullName : "Guest"}
                    color="#F3B633"
                  />
                </IonMenuButton>
              </>
            )}
            {!hideSearch && (
              <div className="searchbar-wrap">
                <input
                  className="searchbar"
                  placeholder="Search"
                  value={searchText}
                  onBlur={() => setShowSearchBar(false)}
                  onChange={(e) => {
                    setSearchText(e?.target?.value);
                  }}
                  onKeyPress={(e) => enterPressed(e)}
                />
                <IonIcon icon={searchOutline}></IonIcon>
              </div>
            )}
          </IonButtons>
        </IonToolbar>
        {isMobile && showSearchBar && !hideSearch && (
          <IonToolbar>
            <IonSearchbar
              animated
              showCancelButton="always"
              inputmode="search"
              enterkeyhint="search"
              onIonCancel={() => setShowSearchBar(false)}
              onIonBlur={() => setShowSearchBar(false)}
              onIonChange={(e) => setSearchText(e?.detail?.value)}
              onKeyPress={(e) => enterPressed(e)}
              placeholder="Search Movie, Title, cast etc.."
            ></IonSearchbar>
          </IonToolbar>
        )}
      </IonHeader>

      {/* <PlansPaymentPopup
        setShow={(val) => {
          setPlansPaymentShow(val);
          localStorage.removeItem("showSubscribePopup");
        }}
        show={showPlansPayment}
      /> */}
    </>
  );
};

const mapStateToProps = ({ user, app }) => ({
  email: user?.email,
  userId: user?.userId,
  fullName: user?.fullName,
  isAuthUser: user?.isAuthUser,
  isMobile: app?.isMobile,
  showSideMenu: app?.showSideMenu,
  showTabs: app?.showTabs,
  showHeader: app?.showHeader,
});

Header.defaultProps = {
    hideBack: true,
    hideSearch: false,
    pageTitle: false,
    hideMenuButton: false,
    hideLogo: false,
};

const mapDispatchToProps = {
  saveUserDetailsAction: saveUserDetails,
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
