import React from "react";
import "./Footer.scss";
import {
  IonFooter,
  IonIcon
} from "@ionic/react";
import { logoInstagram, logoTwitter, logoFacebook } from "ionicons/icons";

const Footer = () => {
  return (
    <>
      <IonFooter className="footer-wrap">
        <div className="copyright-text">
          &#169; {new Date().getFullYear()} Ott India. All Rights Reserved
        </div>
        <div className="social-links">
          <a
            className="sociallogo"
            target="_blank"
            href="https://www.facebook.com/Ott/"
          >
            <IonIcon className="likeicon" icon={logoFacebook}></IonIcon>
          </a>
          <a
            className="sociallogo"
            target="_blank"
            href="https://twitter.com/Ott"
          >
            <IonIcon className="likeicon" icon={logoTwitter}></IonIcon>
          </a>
          <a
            className="sociallogo"
            target="_blank"
            href="https://instagram.com/Ott"
          >
            <IonIcon className="likeicon" icon={logoInstagram}></IonIcon>
          </a>
        </div>
      </IonFooter>
    </>
  );
};
export default Footer;
