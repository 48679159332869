import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

const propTypes = {
  paymentItem: PropTypes.shape({
    amount: PropTypes.number.isRequired,
    date: PropTypes.string,
    points: PropTypes.number.isRequired,
    item: PropTypes.object.isRequired,
  }),
};

const PaymentHistoryCard = ({ paymentItem }) => {
  return (
    <div className="paymentsListItem">
      <ion-card>
        <div className="card-inside-wrap">
          <div className="img-wrapper">
            <img
              className="historyimg"
              src="https://static.1tv.ru/uploads/photo/image/6/huge/1886_huge_4d2b0ddd65.jpg"
            />
          </div>
          <div className="card-content">
            <ion-card-header>
              <ion-card-subtitle>{paymentItem?.item?.title}</ion-card-subtitle>
            </ion-card-header>
            <ion-card-content>
              <div className="moviedetail">
                {paymentItem?.item?.duration} &nbsp; {paymentItem?.item?.rating}{" "}
                &nbsp;
                {paymentItem?.item?.genre}
              </div>
              <div className="lastseen">
                <FormattedMessage id="payments.boughtFor" />:{" "}
                {paymentItem?.amount}
              </div>
              <div className="seencount">
                <FormattedMessage id="payments.boughtOn" />: {paymentItem?.date}
              </div>
              <div className="pointsearned">
                <FormattedMessage id="payments.pointsEarned" />:{" "}
                {paymentItem?.points}
              </div>
              <div className="paymentListItemBtnGroup">
                <button>
                  <FormattedMessage id="payments.sendMail" />
                </button>
              </div>
            </ion-card-content>
          </div>
        </div>
      </ion-card>
    </div>
  );
};

PaymentHistoryCard.propTypes = propTypes;
export default PaymentHistoryCard;
