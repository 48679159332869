import React, { useMemo } from "react";
import PropTypes from "prop-types";
import {
  IonPage,
  IonContent,
  useIonViewDidEnter,
  useIonViewWillEnter,
} from "@ionic/react";
import "./ExploreSection.scss";
import { useAnalytics } from "use-analytics";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { getFeaturedMovie } from "store/slices/persistSlice/persistSlice";
import { ROUTES } from "helpers/routeHelper";
import { TRACK_EVENT } from "analytics/analyticsHelper";
import PageWrapper from "modules/common/PageWrapper";
import { PageWrapperComponent } from "modules/common";

const ExploreSection = ({ featuredMovieList, getFeaturedMovieAction }) => {
  const { track, page, identify } = useAnalytics();

  useIonViewWillEnter(() => {
    page(TRACK_EVENT.APP_USAGE.SCREEN.EXPLORE);
  });

  useIonViewDidEnter(() => {
    getFeaturedMovieAction();
  });

  return (
    <PageWrapperComponent>
      <div className="explore-section-wrap">
        {featuredMovieList?.map((movie, i) => (
          <ExploreCard movie={movie} key={movie?.id || i} />
        ))}
      </div>
    </PageWrapperComponent>
  );
};

const mapStateToProps = ({ persist }) => ({
  featuredMovieList: persist?.featuredMovies?.data?.[0]?.values,
});
const mapDispatchToProps = {
  getFeaturedMovieAction: getFeaturedMovie,
};

export default connect(mapStateToProps, mapDispatchToProps)(ExploreSection);

export const ExploreCard = ({ movie }) => {
  const history = useHistory();

  const url = useMemo(() => {
    return movie?.multipart
      ? ROUTES.SERIES_INFO.replace(":movieId", movie?.id)
      : ROUTES.MOVIE_INFO.replace(":movieId", movie?.id);
  });

  return (
    <div className="explore-card-wrap" onClick={() => history.replace(url)}>
      <div>
        <img src={movie?.banners?.[0]} />
        <div className="banner-desc">
          <h2 className="title">{movie?.title}</h2>
          <ul className="movie-details-wrap">
            <li className="movie-details-list">{movie?.rating}</li>
            <li className="movie-details-list">{movie?.language}</li>
            <li className="movie-details-list">{movie?.genre?.join(", ")}</li>
          </ul>
          <div className="movie-desc">{movie?.description?.slice(0, 240)}</div>
        </div>
      </div>
    </div>
  );
};
